<template>
  <div style="padding:10px;">
  
      <v-form validate-on="submit lazy" @submit.prevent="submit">
        
          <v-row v-if="component_name == 'Gruas'" class="card_contenedor mt-5 mb-5" style="border:1px solid black; border-radius:5px;">
              <div class="center_div">
                  <span class="title_card">INFORMACIÓN DE LA GRÚA</span>
              </div>
              <br />
              <v-row no-gutters style="margin-top:20px;">
                  <v-col cols="12" md="3" style="padding:15px">
                      <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                          label="Descripción de la grúa" v-model="item_lev.grua_desc"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" style="padding:15px">
                      <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                          label="Marca" v-model="item_lev.grua_marca"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" style="padding:15px">
                      <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                          label="Modelo" v-model="item_lev.grua_modelo"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" style="padding:15px">
                      <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                          label="Número de serie" v-model="item_lev.grua_serie"  @input="VerificarNumeroSerie(item_lev.grua_serie)"></v-text-field>
                  </v-col>
              </v-row>
          </v-row>

          <v-row  v-if="component_name == 'Gruas'" class="card_contenedor mt-5 mb-5" style="border:1px solid black; border-radius:5px;">
              <div class="center_div">
                  <span class="title_card">DISEÑO ESTRUCTURAL</span>
              </div>
              <br />

              <v-row no-gutters style="margin-top:20px;">
                  <v-col cols="12" md="4">
                      <v-sheet class="pa-2">
                          <div style="padding-left:40px">
                              <strong> INTEMPERIE/INTERIOR</strong>
                              <div class="center_element">
                                  <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.dsng_estr_inte"
                                      label="INTEMPERIE" value="1"></v-checkbox>
                                  <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.dsng_estr_inte"
                                      label="INTERIOR" value="2"></v-checkbox>
                              </div>
                          </div>
                      </v-sheet>
                  </v-col>
                  <!-- <v-col cols="12" md="4">
                      <v-sheet class="pa-2">

                      </v-sheet>
                  </v-col> -->
                  <v-col cols="12" md="4" v-if="item_lev.id_tipo_unidad == 5 || item_lev.id_tipo_unidad == 6">
                      <v-sheet class="pa-2">
                          <v-checkbox :readonly="item_lev.estatus_cot != null" label="GIRO MANUAL"
                              v-model="item_lev.dsng_estr_giro" value="1"></v-checkbox>
                          <v-checkbox :readonly="item_lev.estatus_cot != null" label="GIRO ELECTRICO "
                              v-model="item_lev.dsng_estr_giro" value="2"></v-checkbox>
                      </v-sheet>
                  </v-col>
              </v-row>
          </v-row>

          <v-row  v-if="component_name == 'Gruas'" class="card_contenedor mt-5 mb-5" style="border:1px solid black; border-radius:5px;">
              <div class="center_div">
                  <span class="title_card">DIMENSIONAMIENTO</span>
              </div>
              <br />

              <v-row no-gutters style="margin-top:20px;">
                  <v-col cols="12" md="3">
                      <v-sheet class="pa-2">
                          <span class="color_letter">A</span> <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null"
                              density="compact" variant="outlined" label="Altura paño de viga a piso"
                              v-model="item_lev.altura_viga_piso"></v-text-field>
                      </v-sheet>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-sheet class="pa-2">
                          <span class="color_letter">B</span> <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null"
                              density="compact" variant="outlined" label="Altura paño de gancho carga a piso"
                              v-model="item_lev.altura_gancho_carga_piso"></v-text-field>
                      </v-sheet>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-sheet class="pa-2">
                          <span class="color_letter">C</span> <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null"
                              density="compact" variant="outlined" label="Capacidad grua (KG/TON)"
                              v-model="item_lev.capacidad_grua"></v-text-field>
                      </v-sheet>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-sheet class="pa-2">
                          <span class="color_letter">D</span> <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null"
                              density="compact" variant="outlined" label="Marca/Tipo de sistema eléctrico"
                              v-model="item_lev.marca_tipo_sistema_electrico"></v-text-field>
                      </v-sheet>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-sheet class="pa-2">
                          <span class="color_letter">E</span> <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null"
                              density="compact" variant="outlined" label="Pesos de pieza carga máxima (Kg/Ton)"
                              v-model="item_lev.peso_pieza_carga_maxima"></v-text-field>
                      </v-sheet>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-sheet class="pa-2">
                          <span class="color_letter">F</span> <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null"
                              density="compact" variant="outlined" label="Giro de brazo"
                              v-model="item_lev.recorrido_total_col_col"></v-text-field>
                      </v-sheet>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-sheet class="pa-2">
                          <span class="color_letter">G</span> <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null"
                              density="compact" variant="outlined" label="Claro de columna a columna"
                              v-model="item_lev.claro_col_col"></v-text-field>
                      </v-sheet>
                  </v-col>
                  <v-col cols="12" md="3" v-if="item_lev.id_tipo_unidad == 5 || item_lev.id_tipo_unidad == 6">
                      <v-sheet class="pa-2">
                          <span class="color_letter">I</span> <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null"
                              density="compact" variant="outlined" label="Grados de giro"
                              v-model="item_lev.grados_giro"></v-text-field>
                      </v-sheet>
                  </v-col>
              </v-row>
          </v-row>
          <v-row  v-if="component_name == 'Gruas'" no-gutters>
              <v-col cols="12" md="6" v-if="data_unidad_nombre=='GRUA MONORRIEL' || data_unidad_nombre=='GRUA BIRRIEL'">
                  <v-sheet class="pa-2">
                      <v-img :src="require('@/assets/ImgFormatos/formato1.png')" width="500px" height="500px"  />
                  </v-sheet>
              </v-col>
              <v-col cols="12" md="6" v-if="data_unidad_nombre=='GRUA BANDERA' || data_unidad_nombre=='GRUA RADIAL'">
                  <v-sheet class="pa-2">
                      <v-img :src="require('@/assets/ImgFormatos/formato2.png')" width="500px" height="500px" />
                  </v-sheet>
              </v-col>
              <v-col cols="12" md="6" v-if="data_unidad_nombre=='GRUA PORTICO'">
                  <v-sheet class="pa-2">
                      <v-img :src="require('@/assets/ImgFormatos/Formato3.jpeg')" width="500px" height="500px" />
                  </v-sheet>
              </v-col>
              <v-col cols="12" md="6" v-if=" data_unidad_nombre=='GRUA SEMI PORTICO'">
                  <v-sheet class="pa-2">
                      <v-img :src="require('@/assets/ImgFormatos/Formato4.jpeg')" width="500px" height="500px" />
                  </v-sheet>
              </v-col>
          </v-row>

          <div  v-if="component_name == 'Gruas'" class="card_contenedor">
              <v-row no-gutters>
                  <v-col cols="12" md="4">
                      <div class="center_element" style="margin: 10px;">
                          <h2>Tipo de polipasto</h2>
                      </div>
                      <v-row no-gutters>
                          <v-col cols="12" md="6">
                              <v-sheet class="pa-2">

                                  <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.tipo_polipastos"
                                      label="Garrucha / Manual" value="Garrucha / Manual"></v-checkbox>
                                  <v-checkbox :readonly="item_lev.estatus_cot != null" class="mg_tn"
                                      v-model="item_lev.tipo_polipastos" label="Eléctrico de Cadena"
                                      value="Eléctrico de Cadena"></v-checkbox>
                                  <v-checkbox :readonly="item_lev.estatus_cot != null" class="mg_tn"
                                      v-model="item_lev.tipo_polipastos" label="Eléctrico de Cable"
                                      value="Eléctrico de Cable"></v-checkbox>
                                  <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.tipo_polipastos"
                                      label="Neumático" value="Neumático" class="mg_tn"></v-checkbox>

                              </v-sheet>
                          </v-col>
                          <v-col cols="12" md="6">
                              <strong>Voltaje de operación</strong>
                              <v-sheet>
                                  <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.voltaje_operacion"
                                      label="480 v" value="480v"></v-checkbox>
                                  <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.voltaje_operacion"
                                      label="220 v" value="220v" class="mg_tn"></v-checkbox>
                                  <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.voltaje_operacion"
                                      label="110 v" value="110v" class="mg_tn"></v-checkbox>
                                  <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.voltaje_operacion"
                                      label="90 PSI" value="90psi" class="mg_tn"></v-checkbox>
                              </v-sheet>
                          </v-col>
                      </v-row>
                  </v-col>

                  <v-col cols="12" md="2">
                      <v-sheet class="pa-2">
                          <br />
                          <br />
                          Velocidades de puente
                          <div class="center_element">
                              <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.velocidad_puente"
                                  label="1" value="1"></v-checkbox>
                              <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.velocidad_puente"
                                  label="2" value="2"></v-checkbox>
                              <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.velocidad_puente"
                                  label="Variador" value="variador"></v-checkbox>
                          </div>
                          <div class="mg_tn">
                              Velocidades de polipasto
                              <div class="center_element">
                                  <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.velocidad_polipasto"
                                      label="1" value="1"></v-checkbox>
                                  <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.velocidad_polipasto"
                                      label="2" value="2"></v-checkbox>
                                  <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.velocidad_polipasto"
                                      label="Variador" value="variador"></v-checkbox>
                              </div>
                          </div>
                      </v-sheet>
                  </v-col>

                  <v-col cols="12" md="3">
                      <v-sheet class="pa-4">
                          <div class="center_element" style="margin: 10px;">
                              <h2>Alimentación Neumática</h2>
                          </div>

                          <div class="center_element">
                              <v-img :src="require('@/assets/ImgFormatos/formato1_maq1.png')" width="200px" height="100px" />
                             <!--  <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.alimentacion_neumatica"
                                  label="" value="1"></v-checkbox> -->
                                  <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.alimentacion_tipo_maq"
                                  label="" value="1"></v-checkbox>
                          </div>
                          <br />
                          <div class="center_element">
                              <strong>Nota:</strong> Presión de trabajo 0.6 Mpa
                          </div>
                      </v-sheet>
                  </v-col>

                  <v-col cols="12" md="3">
                      <v-sheet class="pa-2">

                          <div class="center_element" style="margin: 10px;">
                              <h2>Alimentación Eléctrica</h2>
                          </div>

                          <div class="center_element">
                              <div>
                                  <v-img :src="require('@/assets/ImgFormatos/formato1_maq_2.png')" width="200px"
                                      height="100px" />
                              </div>
                              <div>
<!-- TIPO ALIMENTACIÓN -->
                                <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.alimentacion_tipo_maq"
                                  label="" value="2"></v-checkbox>
                                  <!-- <v-checkbox :readonly="item_lev.estatus_cot != null"
                                      v-model="item_lev.alimentacion_electrica" label="480 v" value="480v"></v-checkbox>
                                  <v-checkbox :readonly="item_lev.estatus_cot != null"
                                      v-model="item_lev.alimentacion_electrica" label="220 v" value="220v"></v-checkbox>
                                  <v-checkbox :readonly="item_lev.estatus_cot != null"
                                      v-model="item_lev.alimentacion_electrica" label="110 v" value="110v"></v-checkbox> -->

                              </div>

                          </div>


                      </v-sheet>
                  </v-col>
              </v-row>
          </div>

          <Manipuladores 
            v-if="component_name == 'Manipuladores'" 
            :restore_lev="item_lev" 
            :is_new="is_new"
            :id_tipo_unidad="id_tipo_unidad"
            :cargarLevantamientos="cargarLevantamientos"
            :data_unidad_nombre="data_unidad_nombre" 
            :component_name="component_name"
          />

        
          <FormatoEstructural 
            v-if="component_name == 'Estructural'" 
            :restore_lev="item_lev" 
            :is_new="is_new"
            :id_tipo_unidad="id_tipo_unidad"
            :cargarLevantamientos="cargarLevantamientos"
            :data_unidad_nombre="data_unidad_nombre" 
            :component_name="component_name"/>
          
          <v-row  v-if="component_name == 'Gruas'" class="card_contenedor mt-5 mb-5" style="border:1px solid black; border-radius:5px;">
              <v-col cols="12" sm="12" lg="12">
                  <center>
                      <h3>
                          Complementos
                      </h3>
                  </center>
                  <v-row>
                      <v-col>
                          <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.requiere_extension"
                              label="Extensión" value="1"></v-checkbox>
                      </v-col>
                      <v-col>
                          <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.requiere_balancin"
                              label="Balancín" value="1"></v-checkbox>
                      </v-col>
                      <v-col>
                          <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.requiere_polipasto"
                              label="Polipastos" value="1"></v-checkbox>
                      </v-col>
                      <v-col>
                          <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.requiere_accesorio_carga"
                              label="Accesorios de carga" value="1"></v-checkbox>
                      </v-col>
                      
                      <v-col>
                          <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.requiere_control"
                              label="Botonera" value="botonera"></v-checkbox>
                      </v-col>
                      <v-col>
                          <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.requiere_control"
                              label="Control Remoto" value="remoto"></v-checkbox>
                      </v-col>
                  </v-row>
              </v-col>
          </v-row>

          <PolipastosCarga 
            v-if="(typeof item_lev.requiere_polipasto !== 'undefined' && item_lev.requiere_polipasto == 1) || component_name == 'Polipastos'" 
            :restore_lev="item_lev" 
            :is_new="is_new"
            :id_tipo_unidad="id_tipo_unidad"
            :cargarLevantamientos="cargarLevantamientos"
            :data_unidad_nombre="data_unidad_nombre" 
            :component_name="component_name"
          />

          <v-row  class="mt-5 mb-5" style="border:1px solid black; border-radius:5px;"
              v-if="(typeof item_lev.requiere_extension !== 'undefined' && item_lev.requiere_extension == 1)">
              <div class="center_div">
                  <span class="title_card">EXTENSIÓN</span>
              </div>
              <br />

              <v-row no-gutters style="margin-top:20px;">
                  <v-col cols="12" md="3">
                      <v-sheet class="pa-2">
                          <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              label="Número de columnas" v-model="item_lev.ext_no_colum"></v-text-field>
                          <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              label="Número de viga puente" v-model="item_lev.ext_no_viga_puente"></v-text-field>
                          <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              label="Tipo de sistema eléctrico" v-model="item_lev.ext_tipo_sistema_electrico"></v-text-field>
                          <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              label="Capacidad" v-model="item_lev.ext_capacidad"></v-text-field>
                          <v-text-field  :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              label="Voltaje" v-model="item_lev.ext_voltaje"></v-text-field>
                      </v-sheet>
                  </v-col>

                  <v-col cols="12" md="5">
                      <v-sheet class="pa-2">
                          <v-text-field  :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              label="Distancia entre colmnas existentes"
                              v-model="item_lev.ext_distancia_entre_colum_exist"></v-text-field>
                          <v-row no-gutters>
                              <v-col cols="12" md="4">
                                  <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                                      label="Claro" v-model="item_lev.ext_claro"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4" class="pl-2">
                                  <v-text-field  :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                                      label="Recorrido" v-model="item_lev.ext_recorrido"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4" class="pl-2">
                                  <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                                      label="Altura" v-model="item_lev.ext_altura"></v-text-field>
                              </v-col>
                          </v-row>
                          <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              label="Tipo de unión : (Tornillería/Soldadura)"
                              v-model="item_lev.ext_tipo_union_ts"></v-text-field>
                          <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              label="Tipo de anclaje: (Quimico/Cimentación)"
                              v-model="item_lev.ext_tipo_quimico_cim"></v-text-field>

                          <v-select :readonly="item_lev.estatus_cot != null" v-model="item_lev.ext_tipo_riel"
                              :items="['Cuadrado', 'Hongo']" label="Seleccionar tipo de riel" density="compact"
                              variant="outlined">

                          </v-select>
                          <!-- medidas segun tipo de riel -->
                          <v-select :readonly="item_lev.estatus_cot != null" v-model="item_lev.ext_tipo_viga"
                              :items="['IPS', 'IPR', 'HSS']" label="Seleccionar tipo de viga" density="compact"
                              variant="outlined">

                          </v-select>
                          <!-- medidas seggun tipo de viga -->
                      </v-sheet>
                  </v-col>

                  <v-col cols="12" md="4">
                      <v-sheet class="pa-2">
                          <div class="center_element">
                              <v-img :src="require('@/assets/ImgFormatos/formato_1_maq_3.png')" width="300px"
                                  height="300px" />
                          </div>
                      </v-sheet>
                  </v-col>
              </v-row>
          </v-row>

          <v-row class="mt-5 mb-5" style="border:1px solid black; border-radius:5px;"
              v-if="(typeof item_lev.requiere_control !== 'undefined') && item_lev.requiere_control != null && item_lev.requiere_control != false">
              <div class="center_div">
                  <span class="title_card">BOTONERA/CONTROL REMOTO</span>
              </div>
              <br />

              <v-row no-gutters style="margin-top:20px;">
                  <v-col cols="12" md="5">
                      <v-sheet class="pa-5">
                          <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              label="Marca" v-model="item_lev.bot_control_r_marca"></v-text-field>
                          <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              label="Modelo" v-model="item_lev.bot_control_r_modelo"></v-text-field>
                          <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              label="Número de movimientos" v-model="item_lev.bot_control_r_no_movim"></v-text-field>
                          <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              label="Velocidades" v-model="item_lev.bot_control_r_velocidades"></v-text-field>
                          <v-row no-gutters>
                              <v-col cols="12" md="6">
                                  <v-text-field  :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                                      label="Incluye cable Mando"
                                      v-model="item_lev.bot_control_r_incluye_cable_mando"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                  <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                                      label="¿Cuantos metros?" class="ml-2"
                                      v-model="item_lev.bot_control_r_cuantos_metros"></v-text-field>
                              </v-col>
                          </v-row>

                      </v-sheet>
                  </v-col>
                  <v-col cols="12" md="4">
                      <v-sheet class="pa-2">
                          <br />
                          <v-text-field :rules="rules"  :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              label="Selector para palipasto (A/B)"
                              v-model="item_lev.bot_control_r_selector_pp_ab"></v-text-field>
                          <v-text-field  :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              label="Incluye paro de emergencia?"
                              v-model="item_lev.bot_control_r_incluye_paro_emerg"></v-text-field>
                          <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              label="Instalación o solo suministro"
                              v-model="item_lev.bot_control_r_intalacion_o_suministro"></v-text-field>
                      </v-sheet>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-sheet class="pa-2">
                          <div class="center_element">
                              <v-img :src="require('@/assets/ImgFormatos/formato_1_maq_4.png')" width="300px"
                                  height="300px" />
                          </div>
                      </v-sheet>
                  </v-col>
              </v-row>
          </v-row>

          <!-- <v-row class="center_element" v-if="(typeof item_lev.requiere_control !== 'undefined') && item_lev.requiere_control != false">
              <small>Indicar si solo se suministra en planta o requiere instalacion por parte de CARVAJAL CRANES & HOSIT SAPI
                  DE CV</small>
          </v-row> -->

          <BalancinCarga v-if="(typeof item_lev.requiere_balancin !== 'undefined' && item_lev.requiere_balancin == 1) || data_unidad_nombre == 'BALANCINES DE CARGA'" :restore_lev="item_lev"/>


          <v-row class="mt-5 mb-5" style="border:1px solid black; border-radius:5px;"
              v-if="(typeof item_lev.requiere_balancin !== 'undefined' && item_lev.requiere_balancin == 1)">
              <div class="center_div">
                  <span class="title_card">MAQUINADOS | FABRICACIONES ESPECIALES</span>
              </div>
              <br />


              <v-row no-gutters>
                  <v-col cols="12" md="4">
                      <v-sheet class="pa-5">
                          <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              v-model="item_lev.maq_fabr_esp_tipo_material" label="Tipo de material"></v-text-field>
                          <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              v-model="item_lev.maq_fabr_esp_peso_total" label="Peso total"></v-text-field>
                          <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              v-model="item_lev.maq_fabr_esp_numero_punto_anclaje"
                              label="Número puntos de anclaje"></v-text-field>
                          <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              v-model="item_lev.maq_fabr_esp_tipo_figuras_geometricas"
                              label="Tipo figuras geometricas"></v-text-field>
                          <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                              v-model="item_lev.maq_fabr_esp_tipo_tratamiento" label="Tipo de tratamiento"></v-text-field>
                      </v-sheet>
                  </v-col>

                  <v-col cols="12" md="8">
                      <v-sheet class="pa-5">
                          <v-row no-gutters>
                              <v-col cols="12" md="3">
                                  <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                                      v-model="item_lev.maq_fabr_esp_circunferencia" label="Circunferencia"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="3">
                                  <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                      style="margin-left:10px;" variant="outlined" v-model="item_lev.maq_fabr_esp_altura_uno"
                                      label="Altura"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="3">
                                  <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                      style="margin-left:10px;" variant="outlined" v-model="item_lev.maq_fabr_esp_ancho"
                                      label="Ancho"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="3">
                                  <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                      style="margin-left:10px;" variant="outlined" v-model="item_lev.maq_fabr_esp_altura_dos"
                                      label="Altura"></v-text-field>
                              </v-col>
                          </v-row>
                          <v-textarea :readonly="item_lev.estatus_cot != null" label="Observaciones generales"
                              variant="outlined" v-model="item_lev.maq_fabr_esp_observaciones"></v-textarea>
                      </v-sheet>
                  </v-col>
              </v-row>
          </v-row>

          <AccesoriosCarga v-if="(typeof item_lev.requiere_accesorio_carga !== 'undefined' && item_lev.requiere_accesorio_carga == 1) || data_unidad_nombre == 'ACCESORIOS DE CARGA'" :restore_lev="item_lev"/>
          <FabricacionesEspeciales v-if="data_unidad_nombre == 'FABRICACIONES ESPECIALES'" 
          
          :restore_lev="item_lev" 
            :is_new="is_new"
            :id_tipo_unidad="id_tipo_unidad"
            :cargarLevantamientos="cargarLevantamientos"
            :data_unidad_nombre="data_unidad_nombre" 
            :component_name="component_name"
          />


          <div class="card_contenedor">
              <div class="center_div">
                  <span class="title_card_2">FOTOS EN REPORTE FINAL DEL CLIENTE</span>
              </div>
              <v-sheet class="pa-4">
                  <v-row no-gutters>
                      <v-col :cols="deviceCordova ? '10' : '12'">
                        <!--  @change="handleFileChange" -->
                          <v-file-input v-if="item_lev.estatus_cot == null"  :readonly="item_lev.estatus_cot != null" label="Subir Imagenes" accept="image/*" v-model="selectedImages" variant="outlined"
                              ref="fileInput" type="file" @change="handleFileUpload" multiple></v-file-input>
                      </v-col>

                        <v-col cols="2" v-show="deviceCordova">
                            <!-- this.selectedFiles -->
                             <!-- selectedImages -->

                             <!-- this.selectedFiles2 -->
                              <!-- selectedImages2 -->
                            <v-btn block @click="TakePhotoCamera(1)"><v-icon>mdi-camera</v-icon></v-btn>
                        </v-col>

                        <!-- <v-col cols="12" v-show="deviceCordova">
                            <center>
                            <img :src="imagenTomada" v-if="imagenTomada" alt="Foto tomada" style="width:200px;" @click="md_img_take_foto=true">
                            </center>
                        </v-col> -->
                  </v-row>

                  <v-row no-gutters>
                      <v-col cols="12" md="12">
                          <v-row>
                              <v-col v-for="(image, index) in img_views" :key="index" cols="12" md="4">
                                  <v-img :src="image" aspect-ratio="1"></v-img>
                              </v-col>
                          </v-row>
                      </v-col>

                      <v-col cols="12" md="12">
                          
                      
                          <v-row v-if="item_lev.files">
                              <v-col v-for="(image, index) in item_lev.files.split(';')" :key="index" cols="12" md="4">
                                  <v-img :src="'levantamientos/'+item_lev.id_levantamiento+'/interno/'+image" aspect-ratio="1" :class="img_borrar.includes(image)?'imagen-opaca':''"></v-img>
                                  <v-btn color="#da2128" @click="removerfoto(image)" v-if="item_lev.estatus_cot == null" v-show="img_borrar.includes(image)==false"><v-icon>mdi-close</v-icon></v-btn>
                                  <v-btn color="success" @click="recuperarfoto(image)" v-show="img_borrar.includes(image)==true"><v-icon>mdi-check</v-icon></v-btn>

                                  
                              </v-col>
                          </v-row>
                      </v-col>
                  </v-row>
              </v-sheet>
          </div>

          <div class="card_contenedor">
              <div class="center_div">
                  <span class="title_card_2">OTRAS FOTOS</span>
              </div>

              <v-sheet class="pa-4">
                  <v-row no-gutters>
                      <v-col :cols="deviceCordova ? '10' : '12'">
                          <v-file-input v-if="item_lev.estatus_cot == null"  :readonly="item_lev.estatus_cot != null" label="Subir Imagenes" accept="image/*" v-model="selectedImages2" variant="outlined"
                              ref="fileInput" type="file" @change="handleFileUpload2" multiple></v-file-input>
                      </v-col>

                      <v-col cols="2" v-show="deviceCordova">
                        <v-btn block @click="TakePhotoCamera(2)"><v-icon>mdi-camera</v-icon></v-btn>
                    </v-col>

                  </v-row>

                  <v-row no-gutters>
                      <v-col cols="12" md="12">
                          <v-row>
                              <v-col v-for="(image, index) in img_views2" :key="index" cols="12" md="4">
                                  <v-img :src="image" aspect-ratio="1"></v-img>
                              </v-col>
                          </v-row>
                      </v-col>

                      <v-col cols="12" md="12">
                          
                      
                          <v-row v-if="item_lev.files2">
                              <v-col v-for="(image, index) in item_lev.files2.split(';')" :key="index" cols="12" md="4">
                                  <v-img :src="'levantamientos/'+item_lev.id_levantamiento+'/externo/'+image" aspect-ratio="1" :class="img_borrar2.includes(image)?'imagen-opaca':''"></v-img>
                                  <v-btn color="#da2128" @click="removerfoto2(image)" v-if="item_lev.estatus_cot == null" v-show="img_borrar2.includes(image)==false"><v-icon>mdi-close</v-icon></v-btn>
                                  <v-btn color="success" @click="recuperarfoto2(image)" v-show="img_borrar2.includes(image)==true"><v-icon>mdi-check</v-icon></v-btn>

                              </v-col>
                          </v-row>
                      </v-col>
                  </v-row>
              </v-sheet>
          </div>


          <div class="card_contenedor">
              <div class="center_div">
                  <span class="title_card_2">PRODUCTOS/SERVICIOS REQUERIDOS</span>
              </div>

              <v-sheet class="pa-4">
                  <v-row no-gutters>
                      <v-col cols="12" md="12">
                        <v-card elevation="0" class="col-lg-12 mt-5">
                        <v-card-actions>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar concepto"
                                single-line
                                hide-details
                                outlined
                            ></v-text-field>
                            <v-spacer></v-spacer>
                            <v-btn
                              v-if="item_lev.estatus_cot == null"
                              style="float: right"
                              color="green darken-4"
                              small
                              fab
                              class="white--text"
                              @click="modalConcepto = true"
                            ><v-icon>mdi-plus</v-icon> Concepto
                            </v-btn>

                        </v-card-actions>

                        <v-card-body>
                          <v-data-table 
                            v-model:items-per-page="itemsPerPage"
                            :headers="cotizacion_headers"
                            :items="cotizacion_items"
                            :search="search"
                            fixed-header
                            :loading ="cotizacion_loading"
                            loading-text="Cargando... Por favor espere."
                            no-data-text="Aún no hay conceptos agregados"
                            dense
                          >
                            <template v-slot:item="{ item, index }">
                              <tr>
                                <td>
                                  <v-text-field v-if="item_lev.estatus_cot == null && item.selectable.id_prodserv == null" @input="
                                  updateItem(item.selectable, 'concepto')" :append-inner-icon="item.selectable.concepto_spin ? 'mdi-spin mdi-loading' : ''" style="margin-top:15px"  v-model="item.selectable.concepto" label="Concepto" density="compact" variant="outlined"></v-text-field>
                                  <span v-else>{{ item.selectable.concepto }}</span> 
                                </td>

                                <td>
                                  <v-textarea v-if="item_lev.estatus_cot == null" @input="
                                  updateItem(item.selectable, 'descripcion')" :append-inner-icon="item.selectable.descripcion_spin ? 'mdi-spin mdi-loading' : ''" style="margin-top:15px"  v-model="item.selectable.descripcion" label="Descripción" density="compact" variant="outlined"></v-textarea>
                                  <span v-else>{{ item.selectable.descripcion }}</span> 
                                </td>
                                

                                <td>
                                  <v-text-field v-if="item_lev.estatus_cot == null && item.selectable.prodserv == 'producto'" type="number" @input="
                                  updateItem(item.selectable, 'cantidad')" :append-inner-icon="item.selectable.cantidad_spin ? 'mdi-spin mdi-loading' : ''" style="margin-top:15px"  v-model="item.selectable.cantidad" label="Cantidad" density="compact" variant="outlined"></v-text-field>
                                  <span v-else>{{ item.selectable.cantidad }}</span>
                                </td>

                                <td>
                                    {{ item.selectable.unidad }}
                                  <!-- <v-select v-if="item_lev.estatus_cot == null && item.selectable.prodserv == 'producto'" @input="
                                  updateItem(item.selectable, 'unidad')" :append-inner-icon="item.selectable.unidad_spin ? 'mdi-spin mdi-loading' : ''"  style="margin-top:15px" v-model="item.selectable.unidad" label="Unidad" density="compact" variant="outlined" :items="['Pieza', 'Servicio']"></v-select>
                                  <span v-else>{{ item.selectable.unidad }}</span> -->
                                </td>
                                
                                <td>
                                  <v-btn v-if="item_lev.estatus_cot == null" color="red" class="ma-1"  small dark  @click="item.selectable.deleting=true; deleteItem(item.selectable)" :disabled="item.selectable.deleting" :loading="item.selectable.deleting">
                                    <v-icon> mdi-delete </v-icon>
                                  </v-btn> 
                                </td>
                              </tr>

                            </template>
                          
                        

                          </v-data-table>
                          

                        </v-card-body>
                      </v-card>
                      </v-col>
                  </v-row>
              </v-sheet>
          </div>


          <div class="card_contenedor">
              <div class="center_div">
                  <span class="title_card_2">CORREO DEPTO. COMPRAS</span>
              </div>

              <v-sheet class="pa-4">
                  <v-row no-gutters>
                      <v-col cols="12" md="12">

                          <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                              v-model="item_lev.correo_compras" label="Ingresa el correo"></v-text-field>
                        <!-- {{item_lev.correo_compras}} -->
                        <!-- return-object -->
                        <!-- <v-select :items="usuarios" v-model="item_lev.correo_compras" item-title="nombre" item-value="correo" :readonly="item_lev.estatus_cot != null" label="Seleccionar destinatarios" variant="outlined" multiple>
                            <template v-slot:item="{ props, item }">
                                <v-list-item v-bind="props" :subtitle="item.raw.correo"></v-list-item>
                            </template>
                        </v-select> -->


                      </v-col>
                  </v-row>
              </v-sheet>
          </div>

          <div class="card_contenedor">
              <div class="center_div">
                  <span class="title_card_2">COMENTARIOS</span>
              </div>

              <v-sheet class="pa-4">
                  <v-row no-gutters>
                      <v-col cols="12" md="12">
                          <v-textarea :readonly="item_lev.estatus_cot != null" rows="3" variant="outlined"
                              v-model="item_lev.notas_comentarios" label="Notas/Comentarios"></v-textarea>
                      </v-col>
                  </v-row>
              </v-sheet>
          </div>

          <v-row class="mt-5">
              <v-col cols="12" v-if="item_lev.estatus_cot == null">
                  <v-btn block color="warning" type="submit">
                      {{ is_new ? "Crear " : "Actualizar " }}levantamiento
                  </v-btn>
              </v-col>
          </v-row>
      </v-form>
  </div>

    <v-dialog persistent v-model="modalConcepto" width="700px" >	
        <v-card class="pa-5">
            <Modal :is_new="is_new" @agregarItem="agregarItem" :id_levantamiento="item_lev.id_levantamiento" :cargarItems="cargarItems" @modal="modalConcepto = $event"/>
        </v-card>
    </v-dialog>


    <!-- <v-dialog v-model="md_img_take_foto" max-width="700px">
        <v-card class="pa-2">
          <v-card-actions class="pa-0 py-0">
            <v-spacer></v-spacer>
            <v-btn color="error" fab small @click="md_img_take_foto = false"
              ><v-icon>mdi-close</v-icon></v-btn>
          </v-card-actions>

          <v-card-text style="ma-2">
            <center>
              <img :src="imagenTomada" alt="Foto tomada" style="width:100% !important;">
            </center>
          </v-card-text>
        </v-card>
      </v-dialog> -->
  
</template>

<script>
import { mapState } from "vuex";

import Modal from './ConceptoModal.vue';

import AccesoriosCarga from "./AccesoriosCarga2.vue";
import BalancinCarga from "./BalancinCarga.vue";
import PolipastosCarga from "./PolipastosCarga.vue";
import FabricacionesEspeciales from "./FabricacionesEspeciales.vue";
import FormatoEstructural from "./FormatoEstructural.vue";
import Manipuladores from "./Manipuladores.vue";


export default {
  computed: {
      ...mapState("Session", ['session_key', 'user', 'permisos', 'loggedin']),
  },
  components: {
      AccesoriosCarga,
      BalancinCarga,
      PolipastosCarga,
      FabricacionesEspeciales,
      FormatoEstructural,
      Manipuladores,
      Modal
  },
  props: {
      is_new: Boolean,
      restore_lev: Object,
      visita: Object,
      id_tipo_unidad: String,
      cargarLevantamientos: Function,
      data_unidad_nombre: Object,
      component_name: String,
      creator: Boolean,
      showAlert: Function,
      hasPermission: Function
  },
  data() {
      return {
            
            md_img_take_foto:false,
            imagenTomada:null,
            imagenesTomadas:[],
            deviceCordova:false,




            item_lev: {},
            modalConcepto:false,
            concepto:null,

            itemsPerPage:100,
            cotizacion_headers:[
                    { title: 'Producto o servicio'	 , align: 'left'  , key: 'concepto' 	},
                    { title: 'Descripción'	 , align: 'left'  , key: 'descripcion' 	},
                    { title: 'Cantidad'  					 , align: 'left'  , key: 'cantidad' },
                    { title: 'Unidad'  					 , align: 'left'  , key: 'unidad' },
                    // { title: 'Precio unit.'	 , align: 'left'  , key: 'precio_unitario' },
                    // { title: 'Precio Total'	 , align: 'left'  , key: 'total' },
                    { title: ''	 , align: 'left'  , key: 'action' }
                ],
            cotizacion_items:[],
            search:'',
            cotizacion_loading:false,

          selectedImages: [],
          selectedImages2: [],
          img_views: [],
          img_views2:[],
          selectedFiles: [],
          selectedFiles2: [],
          rules: [
              (value) => {
                  if (value) return true;

                  return "Este campo es obligatorio";
              },
          ],
          imagenUrl: null,
          data_unidad_nombre:this.is_new==true?this.data_unidad_nombre:this.restore_lev.tipo_unidad_nombre,
          img_borrar:[],
          img_borrar2:[],
          time_numer_serie:null,

          usuarios:[]
      }
  },
  watch: {
      selectedImages() {
          this.obtenerImagen();
      },
      selectedImages2() {
          this.obtenerImagen2();
      },
      data_unidad_nombre(){
          this.data_unidad_nombre=this.is_new==true?this.data_unidad_nombre:this.restore_lev.tipo_unidad_nombre
      }

  },
  async mounted(){
    const vm = this;
    document.addEventListener("deviceready", () => {
        vm.deviceCordova = true;
    });
  },
  async created() {
    await this.$axios.post("usuarios.search", {estatus:1})
    .then(r=>{
        this.usuarios = r.data;  
    })
    .catch(e=>{console.log(e)}) 
        
      if (!this.is_new) {
          this.item_lev = this.restore_lev;
          this.item_lev.tipo_polipastos = this.restore_lev.tipo_polipastos == null ? [] : (this.restore_lev.tipo_polipastos.includes(';') ? this.restore_lev.tipo_polipastos.split(";") : this.restore_lev.tipo_polipastos);
        //   this.item_lev.correo_compras = this.restore_lev.correo_compras == null ? [] : (this.restore_lev.correo_compras.includes(',') ? this.restore_lev.correo_compras.split(",") : [this.restore_lev.correo_compras]);

          await this.cargarItems();
      } else {
          this.item_lev.tipo_polipastos = []
          //agregamos por defecto a cotizacion_items el pconcepto de preventivo??
          //y cuando todavia no es creado ?? 

           /* this.cotizacion_items.push({
                id_levantamiento:null,
                prodserv:'servicio',
                id_prodserv:2,
                concepto:'Preventivos',
                cantidad:1,
                unidad:'Servicio'
            });*/
            
      }

  },



    methods: {

        TakePhotoCamera(toNumberFile) {
            //como agrego esto a mi v-file-input sin perder las imagenes ya cargadas, es decir, para que a su vez se ejecute todo lo que desencadena @change="handleFileUpload"
            document.addEventListener("deviceready", () => {
                const options = {
                quality: 80,
                destinationType: window.cordova.camera.DestinationType.DATA_URL,
                encodingType: window.cordova.camera.EncodingType.JPEG,
                mediaType: window.cordova.camera.MediaType.PICTURE,
                sourceType: window.cordova.camera.PictureSourceType.CAMERA
                };

                window.cordova.camera.getPicture(
                imageData => {
                    /* window.alert("imageData : " + imageData); */
                    // let imagenTomada = 'data:image/jpeg;base64,' + imageData;
                     // Convert base64 image to a File object
                    let byteString = atob(imageData);
                    let ab = new ArrayBuffer(byteString.length);
                    let ia = new Uint8Array(ab);
                    for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                    }
                    let blob = new Blob([ia], { type: "image/jpeg" });
                    let file = new File([blob], "photo.jpg", { type: "image/jpeg" });
                    
                    if(toNumberFile == 1){
                        // Add the File object to selectedImages
                        this.selectedImages.push(file);
                        
                        // Trigger handleFileUpload
                        this.handleFileUpload({ target: { files: this.selectedImages } });

                        this.obtenerImagen();
                    } else if(toNumberFile == 2){
                         // Add the File object to selectedImages
                         this.selectedImages2.push(file);
                        
                        // Trigger handleFileUpload
                        this.handleFileUpload2({ target: { files: this.selectedImages2 } });

                        this.obtenerImagen2();
                    }
                   

                },
                error => {
                    console.error('Error al tomar la foto:', error);
                /*  window.alert("error : " + error); */
                },
                options
                );

            });


        },
       async VerificarNumeroSerie(n_s){


        if(this.time_numer_serie != null){
        clearTimeout(this.time_numer_serie);
      }
       
        this.time_numer_serie = setTimeout(async() => {
        let datapost ={
            id_cliente:this.visita.id_cliente,
            id_tipo_unidad:this.id_tipo_unidad,
            numero_serie:n_s
        }

           this.$axios.post("comprobar.numero.serie.maq",datapost)
           .then(r=>{
            if(r.data==true){
                this.item_lev.grua_serie ="";
                window.showAlert({
                        activo: true,
                        texto: "El número de serie ya ha sido registrado con anterioridad, intenta agregar otro",
                        color: "error",
                        vertical: true
                    });
            }
           
        })
        .catch(e=>{console.log(e)}) 


    }, 1000);
           
        },
        
        agregarItem(item){
            this.cotizacion_items.push(item);
        },

        async deleteItem(item){
            if(window.confirm("¿Éstas seguro que desea eliminar este concepto '"+item.concepto+"'?")){
                if(this.is_new){
                    this.cotizacion_items.splice(this.cotizacion_items.indexOf(item), 1);
                    item.deleting = false;
                } else {
                    await this.$axios.post('levantamientos.deleteItem', item).then(()=>{
                    this.cargarItems();
                    }).catch(e=>{
                    window.showAlert({
                        activo: true,
                        texto: "Lo sentimos! Algo salio mal...",
                        color: "error",
                        vertical: true
                    });
                    }).finally( () => {
                    item.deleting = false;
                    });
                }
                
            } else {
                item.deleting = false;
            }
        },
        
        async updateItem(item, column){
            if(!this.is_new){
                if(item.timer != null){
                    clearTimeout(item.timer);
                }

                item.timer = setTimeout(async() => {
                if(column == 'cantidad'){
                    item.cantidad_spin=true;
                } else if(column == 'concepto'){
                    item.concepto_spin=true;
                } else if(column == 'descripcion'){
                    item.descripcion_spin=true;
                }
                
                await this.$axios.post('levantamientos.updateItem', item).then(()=>{
                
                }).catch(e=>{
                    window.showAlert({
                    activo: true,
                    texto: "Lo sentimos! Algo salio mal...",
                    color: "error",
                    vertical: true
                    });
                }).finally( () => {

                    if(column == 'cantidad'){
                        item.cantidad_spin=false;
                    } else if(column == 'concepto'){
                        item.concepto_spin=false;
                    }  else if(column == 'descripcion'){
                        item.descripcion_spin=false;
                    }
                });
                }, 1000);
            }

            },

        async cargarItems(){
            this.cotizacion_loading = true;
            await this.$axios.post("levantamientos.items", {
                id_levantamiento: this.item_lev.id_levantamiento
            }).then(r => { 
                this.cotizacion_items = r.data;
            }).catch(e => { console.log(e) }).finally( () => {
                this.cotizacion_loading = false;
            } );

        },

      removerfoto(img) {
          let find = this.img_borrar.filter(e => e === img);
          if (find.length === 0) {
              this.img_borrar.push(img);
          }
      },

      recuperarfoto(img){
          this.img_borrar.splice(img, 1);
      },

      removerfoto2(img) {
          let find2 = this.img_borrar2.filter(e => e === img);
          if (find2.length === 0) {
              this.img_borrar2.push(img);
          }
      },

      recuperarfoto2(img){
          this.img_borrar2.splice(img, 1);
      },

      obtenerImagen2() {
          this.img_views2 = [];
          this.selectedImages2.forEach(img => {
              this.img_views2.push(URL.createObjectURL(img))
          });
      },

      obtenerImagen() {
          this.img_views = [];
          this.selectedImages.forEach(img => {
              this.img_views.push(URL.createObjectURL(img))
          });
      },
      handleFileUpload(event) {
          this.selectedFiles = event.target.files;
      },

      handleFileUpload2(event) {
          this.selectedFiles2 = event.target.files;
      },

      



      uploadImage(id_lev) {
          let formData = new FormData();
          formData.append('id_levantamiento', id_lev);
          if(this.item_lev.files!=null){
              formData.append('files_env', this.item_lev.files);
          }

          if(this.item_lev.files2!=null){
              formData.append('files_env2', this.item_lev.files2);
          }
         

          for (let i = 0; i < this.selectedFiles.length; i++) {
              formData.append('images[]', this.selectedFiles[i]);
          }

          for (let i = 0; i < this.selectedFiles2.length; i++) {
              formData.append('images2[]', this.selectedFiles2[i]);
          }
          this.sendImage(formData);
      },



      async sendImage(formData) {
          await this.$axios.post("subir.archivos.levantamientos", formData).then(r => { console.log(r);   this.cargarLevantamientos(); }).catch(e => { console.log(e) })
      },



      async submit(event) {
          const results = await event;
          if (results.valid == true) {
              this.crearLevantamiento();
          }else{
              window.showAlert({
                      activo: true,
                      texto: "Falta llenar algun campo",
                      color: "red accent-4",
                      vertical: true,
                  });
          }
      },

      async crearLevantamiento() {
            if(this.cotizacion_items.length < 1) {
                window.showAlert({
                      activo: true,
                      texto: "Por favor, ingresa al menos un producto/servicio",
                      color: "red accent-4",
                      vertical: true,
                  });
                  return;
            }
            //this.item_lev.correo_compras.length < 1
            if(!this.item_lev.correo_compras || this.item_lev.correo_compras == '' || this.item_lev.correo_compras == null) {
                window.showAlert({
                      activo: true,
                      texto: "Por favor, ingresa al menos un destinatario",
                      color: "red accent-4",
                      vertical: true,
                  });
                  return;
            }

          if(this.img_borrar.length>0){
           let temp =   this.item_lev.files.split(';')

              let resultado = temp.filter(elemento => !this.img_borrar.includes(elemento));
              this.item_lev.files=resultado.join(';');
          }

          if(this.img_borrar2.length>0){
           let temp2 =   this.item_lev.files2.split(';')

              let resultado2 = temp2.filter(elemento => !this.img_borrar2.includes(elemento));
              this.item_lev.files2=resultado2.join(';');
          }
         


          this.item_lev = {
              ...this.item_lev,
              id_usr: this.user.id_usr,
              id_tipo_unidad: this.id_tipo_unidad,
              id_visita: this.visita.id_visita,
            //   correo_compras: this.item_lev.correo_compras ? this.item_lev.correo_compras.join(',') : ""
          };

          let payload = {
              table: 'levantamientos',
              foreignkey: 'id_levantamiento',
              protectedkeys: [
                  'id_levantamiento',
                  'component_name',
                  'tipo_unidad_descripcion',
                  'tipo_unidad_nombre',
                  'id_visita',
                  'id_tipo_unidad',
                  'estatus_cot'
              ],
              items:this.cotizacion_items,
              data: this.item_lev
          }

          await this.$axios
              .post("levantamientos.crear.actualizar", payload)
              .then(async (r) => {
                  console.log(r.data)
                  window.showAlert({
                      activo: true,
                      texto: "Levantamiento " + this.is_new == true ? "agregado" : "actualizado" + " con éxito!",
                      color: "green accent-4",
                      vertical: true,
                  });
                  if(this.selectedFiles.length>0 || this.selectedFiles2.length>0){
                      this.uploadImage(r.data)
                  }else{
                      this.cargarLevantamientos();
                  }
                
              })
              .catch((error) => {
                  console.log(error);
              })
              .finally();
      }
  }
}
</script>


<style scoped>
.card_contenedor {

  margin-top: 20px;
  border: solid 2px #000;
  width: 100%;
  border-radius: 5px;
}

.title_card {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 5px;
  padding-top: 5px;
  letter-spacing: 2px;
  background-color: #FFD600;
}


.center_div {

  width: 100%;
  top: -25px;
  display: flex;
  justify-content: center;
}

.center_element {
  display: flex;
  justify-content: center
}


.color_letter {
  color: #000000;
  padding: 4px;
  background-color: #FFD600;
}

.color_spacer {
  color: #000000;
  padding: 4px;

}

.space_container {
  margin-top: -20px;
}

.mg_tn {
  margin-top: -30px;
}

.nota_contenedor {
  padding: 10px;
  border: solid 2px #000000;
  font-size: 18px;
  color: #5F5F5F;
  font-weight: bold;
}

.title_card_2 {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 5px;
  padding-top: 5px;
  letter-spacing: 2px;
  background-color: #0D3961;
  color: #fff
}

.imagen-opaca {
opacity: 0.2; 
}
</style>