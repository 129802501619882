<template>
  <div v-if="!Loading">

    <v-col cols="12" align="right">

      <!-- <v-alert border="bottom" colored-border color="white"> -->
         <h1 style="float: left;">
            <v-btn icon color="warning" to="/unidades">
              <v-icon> mdi-arrow-left </v-icon>
            </v-btn>

            Unidad 
            <span style="color: #1e88e5">
              {{ unidad.clave_unidad }}
            </span>
        </h1>
        <v-btn :block="screenWidth <= 500 ? true : false" elevation="0"
          :variant="tap_btn == 'detalles' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'detalles'">
          Detalles unidad
        </v-btn>

        <v-btn :block="screenWidth <= 500 ? true : false" elevation="0" 
            :variant="tap_btn == 'levantamientos' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'levantamientos'">
            Levantamiento
        </v-btn>

        <v-btn :block="screenWidth <= 500 ? true : false" elevation="0" 
            :variant="tap_btn == 'servicios' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'servicios'">
            Servicios Realizados
        </v-btn>

        <!-- <v-btn :block="screenWidth <= 500 ? true : false" elevation="0" 
            :variant="tap_btn == 'levantamientos' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'levantamientos'">
            Preventivos/Plan de manteimiento
        </v-btn> -->
        
      <!-- </v-alert> -->
    </v-col>

    <div style="background-color:#F5F5F5; padding:3px; margin-left:15px;margin-right:15px">
    </div>

    <div style="padding: 20px; margin: 10px; border-radius: 10px;">
      
      <!-- <detalles v-if="tap_btn == 'detalles'" 
        :showAlert="showAlert"
        :hasPermission="hasPermission"
      />
      
      -->
      <!-- props que es solo vista, editable? -->
      <FormatoGlobal v-if="tap_btn == 'levantamientos'" 
        :is_new="false" 
        :restore_lev="levantamiento" 
        :visita="visita" 
        :id_tipo_unidad="levantamiento.id_tipo_unidad" 
        :component_name="levantamiento.component_name" 
        :data_unidad_nombre="levantamiento.tipo_unidad_nombre" 
        
        :creator="false"
        :showAlert="showAlert"
        :hasPermission="hasPermission" /> 



      <v-row class="pa-0 ma-3" justify="center" v-if="tap_btn == 'servicios'" >
        <v-col cols="12">
          <h3> ORDENES DE SERVICIO </h3>
          <v-card class="mt-3" outlined>
            <v-card-actions>
              <v-text-field v-model="search" append-icon="search" label="Buscar orden de servicio" single-line
                hide-details></v-text-field>
              <v-spacer></v-spacer>
              
              <v-btn small class="gray" icon dark @click="consulta()"><v-icon>mdi-refresh</v-icon> </v-btn>
            </v-card-actions>

            <v-data-table v-model:items-per-page="itemsPerPage" :headers="headers" :items="items" :search="search"
              fixed-header hide-default-footer :loading="Loading" loading-text="Cargando... Por favor espere."
              no-data-text="Aún no hay móduos registrados" dense>
              <template v-slot:item.action="{ item }">
                  <v-btn small text color="warning" dark
                  @click="infoOT(item.selectable.id_ot)"><v-icon> mdi-send</v-icon> </v-btn>
              </template>

              <template v-slot:item.estatus="{ item }">
                <v-chip :color="GetEstusColor(item.selectable.estatus)">

                  {{ GetEstatus(item.selectable.estatus) }}
                </v-chip>
              </template>
            </v-data-table>

          </v-card>

        </v-col>
      </v-row>

      <!-- <levantamiento  v-if="tap_btn == 'levantamientos'" :visita="visita" :creator="creator" :showAlert="showAlert" :hasPermission="hasPermission"/> -->
      <!-- <CheckList  v-if="tap_btn == 'checklist'" :visita="visita"/> -->
      <!-- <DetallesUnidad  v-if="tap_btn == 'unidad'" :visita="visita"/> -->
    </div>
  </div>

  <ScreenSplash v-else />
</template>

<script>

import { mapState } from "vuex";
import ScreenSplash from '@/components/ScreenSplash.vue';
import FormatoGlobal from "@/views/Levantamientos/Formatos/FormatoGlobal.vue";

import moment from 'moment';
export default {

  components: {
    ScreenSplash,
    FormatoGlobal
  },

  computed: {
    ...mapState("Session", ['session_key', 'user','permisos', 'loggedin']),
    ...mapState(['router']),
    screenWidth() {
      return window.innerWidth;
    }
  },
  props: {
    id_unidad: String
  },
  data() {
    return {
        tap_btn:'detalles',
        unidad:{},
        levantamiento:{},
        Loading:false,

        search: '',
        itemsPerPage: 100,
        items: [],
        headers: [
          { title: '#', align: 'left', key: 'id_ot' },
          { title: 'Fecha', align: 'left', key: 'fecha_visita_programada' },
          { title: 'Servicio', align: 'left', key: 'nombre_servicio' },
          { title: 'Comentarios', align: 'left', key: 'comentarios' },
          { title: 'Estatus', align: 'left', key: 'estatus' },
          { title: '', align: 'right', key: 'action', sortable: false },

        ],

    };
  },

  async created() {
    this.Loading=true;
    this.$axios.post('consulta.detunidad', {id_unidad:this.id_unidad}).then((res)=>{
        this.unidad = res.data.unidad;
        this.levantamiento = res.data.levantamiento;
        this.items = res.data.servicios;

    }).catch((err)=>{
        console.log(err);
    }).finally( ()=> {
        this.Loading=false;
    });

  },
  mounted(){
    // this.Loading = false
  },
  methods: {
    infoOT(item) {
      this.$router.push({ name: 'VistaOrdenSelected', params: { id_ot: item } });

    },
    GetEstusColor(e) {
      let c = "";
      switch (e) {
        case 0:
          c = "blue accent-4";
          break;
        case 1:
          c = "orange accent-4";
          break;
          c = "";
        case 2:
          c = "green accent-4";
          break;
        default:
          c = "blue accent-4";
          break;
      }
      return c;
    },
    GetEstatus(e) {
      let c = "";
      switch (e) {
        case 0:
          c = "Pendiente";
          break;
        case 1:
          c = "Programada";
          break;
          c = "";
        case 2:
          c = "Finalizada";
          break;
        default:
          c = "Pendiente";
          break;
      }
      return c;
    }

  }
}
</script>