<template>
  <div>

    <v-navigation-drawer
      v-model="drawer"
      left
      temporary
      app
      class="bg-blue-grey-darken-4"
      theme="dark"
    >
      <br />
      
      <!-- <v-divider></v-divider>
      <v-list-item :title="session_data.nombre"></v-list-item>
      <v-divider></v-divider> -->

      <v-list dense nav class="py-0 white--text" >
        <v-list-item 
          value="Levantamientos"  to="/account" two-line>
          <v-row>
            <v-col cols="2">
              <v-list-item-avatar >
                <img src="img/person.png" width="30" heigth="30" >
              </v-list-item-avatar>
            </v-col>
            <v-col>
              <v-list-item-content>
                <v-list-item-title v-list-item-title >{{ user.nombre }}</v-list-item-title>
                <v-list-item-subtitle>{{user.role_name}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-col>
          </v-row>
          

          
        </v-list-item>
        <v-divider></v-divider>
      </v-list>

      <v-list >
        <v-list-subheader title="Menú principal"></v-list-subheader>
        <v-list-item
          v-show="hasPermission('ver_clientes')"
          prepend-icon="mdi-calendar-alert"
          title="Clientes"
          value="Clientes"
          to="/clientes"
        ></v-list-item>

        <v-list-item
          v-show="hasPermission('ver_agenda')"
          prepend-icon="mdi-calendar-alert"
          title="Agenda visitas"
          value="Agenda visitas"
          to="/visitas"
        ></v-list-item>

        <!-- <v-list-item
          v-show="hasPermission('ver_levantamientos')"
          prepend-icon="mdi-calendar-alert"
          title="Levantamientos"
          value="Levantamientos"
          to="/levantamientos"
        ></v-list-item> -->

        <v-list-item
          v-show="hasPermission('ver_cotizaciones')"
          prepend-icon="mdi-account-circle"
          title="Cotizaciones"
          value="Cotizaciones"
          to="/cotizaciones"
        ></v-list-item>

        <v-list-item
          v-show="hasPermission('ver_ordenes')"
          prepend-icon="mdi-account-circle"
          title="Ordenes de Servicio"
          value="Ordenes de Servicio"
          to="/ordenes-servicio"
        ></v-list-item>

        <v-list-item
        v-show="hasPermission('ver_unidades')"
          prepend-icon="mdi-account-circle"
          title="Unidades"
          value="Unidades"
          to="/unidades"
        ></v-list-item>


        <!-- <v-list-item
          prepend-icon="mdi-account-circle"
          title="Preventivos"
          value="Preventivos"
          to="/cliente/preventivos"
        ></v-list-item>
         -->

        <!-- <v-list-item
          prepend-icon="mdi-account-circle"
          title="Mi cuenta"
          value="Mi cuenta"
          to="/cliente/user-setting"
        ></v-list-item> -->
      </v-list>
      
      <v-divider></v-divider>


<!-- 
      

      <v-list>
        <v-list-subheader title="Menú vendedor"></v-list-subheader>

        <v-list-item
          prepend-icon="mdi-view-dashboard"
          title="Clientes"
          value="Clientes"
          to="/clientes"
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-view-dashboard"
          title="Citas"
          value="Citas"
          to="/agenda/citas"
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-view-dashboard"
          title="Cotizaciones"
          value="Cotizaciones"
          to="/cotizaciones"
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-qrcode-scan"
          title="Ordenes de Servicio"
          value="Ordenes de Servicio"
          to="/ordenes-de-servicio"
        ></v-list-item>
      </v-list>

      <v-list >
        <v-list-subheader title="Menú técnicos"></v-list-subheader>
        <v-list-item
          prepend-icon="mdi-view-dashboard"
          title="citas"
          value="citas"
          to="/agenda/citas"
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-qrcode-scan"
          title="Ordenes de Servicio"
          value="Ordenes de Servicio"
          to="/tecnico/ordenes-servicio"
        ></v-list-item>
      </v-list>
      
      <v-divider></v-divider>

      <v-list >
        <v-list-subheader title="Catálogos"></v-list-subheader>
        <v-list-item
          prepend-icon="mdi-view-dashboard"
          title="Tipo unidades"
          value="Tipo unidades"
          to="/catalogo/tipounidades"
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-qrcode-scan"
          title="Unidades"
          value="Unidades"
          to="/catalogo/unidades"
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-qrcode-scan"
          title="Formatos"
          value="Formatos"
          to="/catalogo/formatos"
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-qrcode-scan"
          title="Servicios"
          value="Servicios"
          to="/catalogo/servicios"
        ></v-list-item>

      </v-list>
      
      <v-divider></v-divider>

      <v-list >
        <v-list-subheader title="Menú super admin"></v-list-subheader>
        <v-list-item
          prepend-icon="mdi-view-dashboard"
          title="Módulos"
          value="Módulos"
          to="/admin/modulos"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-view-dashboard"
          title="Permisos"
          value="Permisos"
          to="/admin/permisos"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-view-dashboard"
          title="Roles"
          value="Roles"
          to="/admin/roles"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-view-dashboard"
          title="Usuarios"
          value="Usuarios"
          to="/admin/usuarios"
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-view-dashboard"
          title="Config. sistema"
          value="Config. sistema"
          to="/admin/sistema"
        ></v-list-item>

      </v-list>
      <v-divider></v-divider> -->


      <v-divider></v-divider>

<!-- <v-list >
  <v-list-subheader title="Catálogos"></v-list-subheader>
  <v-list-item
    prepend-icon="mdi-view-dashboard"
    title="Tipo unidades"
    value="Tipo unidades"
    to="/catalogo/tipounidades"
  ></v-list-item>

  <v-list-item
    prepend-icon="mdi-qrcode-scan"
    title="Servicios"
    value="Servicios"
    to="/catalogo/servicios"
  ></v-list-item>

</v-list>

<v-divider></v-divider> 


 reparaciones estéticas, como la pintura, podrían considerarse parte del mantenimiento regular y, por lo tanto, ser responsabilidad del arrendador.



-->

<v-list >
  <v-list-subheader title="Administración"></v-list-subheader>
  <!-- <v-list-item
    prepend-icon="mdi-view-dashboard"
    title="Módulos"
    value="Módulos"
    to="/admin/modulos"
  ></v-list-item>
  <v-list-item
    prepend-icon="mdi-view-dashboard"
    title="Permisos"
    value="Permisos"
    to="/admin/permisos"
  ></v-list-item>
  <v-list-item
    prepend-icon="mdi-view-dashboard"
    title="Roles"
    value="Roles"
    to="/admin/roles"
  ></v-list-item> -->

  <v-list-item
    v-if="hasPermission('catalog')"
    prepend-icon="mdi-view-dashboard"
    title="Catálogos"
    value="Catálogos"
    to="/admin/catalogos"
  ></v-list-item> 

  <v-list-item
    v-if="hasPermission('users')"
    prepend-icon="mdi-view-dashboard"
    title="Usuarios"
    value="Usuarios"
    to="/admin/usuarios"
  ></v-list-item>

  <v-list-item
    v-if="hasPermission('config_sys')"
    prepend-icon="mdi-view-dashboard"
    title="Config. sistema"
    value="Config. sistema"
    to="/admin/sistema"
  ></v-list-item>

</v-list>
<v-divider></v-divider>

      <v-list>
        <v-list-item @click="logout()" style="background-color: #FFB506">
          <v-icon style="color: #fff">mdi-power</v-icon>

          <strong style="color: #fff"> Salir</strong>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :color="appconf.navbar_color" elevation="0" app :style=" 'border-bottom: 15px solid '+appconf.navbar_border">
      <v-app-bar-nav-icon
      @click.stop="drawer = !drawer"
        style="color: #fff"
        variant="text"
      >
      </v-app-bar-nav-icon> 
      <img
        style="margin-left: 10px"
        @click.stop="drawer = !drawer"
        :src="appconf.navbar_icon"
        width="50"
        height="50"
      />
 
      <v-spacer></v-spacer>

      <v-btn v-if="escaner" icon @click="funcion_escanear">
        <v-icon>mdi-qrcode-scan</v-icon>
      </v-btn>


      <v-btn icon >
        
        <v-badge
        :content="1"
        :value="1"
        color="warning"
        overlap
      >
        <!-- <v-btn icon @click="abreNotificaciones()" > -->
         <!--  <v-icon  @click="abreNotificaciones()">mdi-bell-ring</v-icon> -->
         <v-icon >mdi-bell-ring</v-icon>
        <!-- </v-btn> -->
      </v-badge>
      </v-btn>

      <!-- <v-btn text >
        <v-icon>mdi-logout</v-icon> Salir
      </v-btn> -->

    </v-app-bar>
    
  </div>
</template>


<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "NavSideBar",
  // props: {
  //   session: Object,
  //   salir: Function
  // },
  props:{
    appconf: Object,
    hasPermission: Function
  },
  data() {
    return {
      drawer: false,
      escaner:false,
      // roles:[
      //   {key_role:'admin', roleName:'Super admin', permissions:null},
      //   {key_role:'vendedor', roleName:'Vendedor',permissions:null},
      //   {key_role:'cliente', roleName:'Cliente',permissions:null},
      //   {key_role:'tecnico', roleName:'Técnico',permissions:null}
      // ]
    };
  },
  computed: {
    ...mapState("Session", ['session_key', 'user', 'permisos', 'loggedin']),
  },
  mounted() {
    //localStorage.getItem("session.data")
    // console.log(this.session);
    // console.log("session: "+ JSON.parse(localStorage.getItem("session.data") ));
  },
  created(){
    let vm = this;
    document.addEventListener("deviceready", () => {
      vm.escaner = true
    });
    // this.session_data.role = this.roles.filter(r => r.key_role == this.session_data.key_role);
    // this.session_data.role = this.session_data.role[0]
  },
  methods: {
    ...mapMutations("Session", ['session_destroy']),

    funcion_escanear() {
      
      let vm = this;

      document.addEventListener("deviceready", () => {

        if (window.QRScanner) {
          QRScanner.prepare((err, status) => {
            if (err) {
              alert("error")
            }
            if (status.authorized) {
              alert("autorizado")
              // La cámara está lista, inicia la captura.
              QRScanner.scan((err, text) => {
                if (err) {
                  console.error(err);
                  alert("error 2")
                } else {
                  // QRScanner.cancelScan();
                  // setTimeout(() => {
                  //   QRScanner.hide();
                  // }, 300); // Agrega un pequeño retraso de 300ms antes de ocultar la cámara
                  QRScanner.destroy(() => {
                    // Restaurar la opacidad del webview
                    document.body.style.backgroundColor = "#FFFFFF"; 
                    document.body.style.opacity = "1"; // Restablecer la opacidad
                  });
                  console.log("QR Code: ", text);
                  vm.$router.push({
                    path: '/unidades/' + text
                  });
                }
              });

              // Muestra la vista de la cámara
              QRScanner.show();
            }
          });
      } else {
        console.error("QRScanner plugin not available");
      }

      });
    },
    
    logout(){
      document.addEventListener("deviceready", () => {
        window.cordova.exec(
          function (successMessage) {
            console.log(
              "Java code executed successfully:",
              successMessage
            );
          },
          function (errorMessage) {
            console.error("Error from Java:", errorMessage);
          },
          "NotificationsEndPlugin",
          "handleButtonClick",
          []
        );
      });
      this.$router.push({name:'HomeComponent'});
      this.session_destroy()
    }
  },
};
</script>