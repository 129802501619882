<template>
    <div id="pdf-content-aviso">
        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col v-html="appconf.ot_iso" cols="12" md="6" style="background-color:#ffffff; color: #000000;">
                
            </v-col>
            <v-col cols="12" md="6" style="background-color:#c00000; color: #ffffff;">
                <center>
                    <img :src="appconf.logo" :width="appconf.logo_width" :height="appconf.logo_height" />
                </center>
            </v-col>
        </v-row>
        <br />
        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="6" style="background-color:#002060; color: #FFFFFF;  padding:10px;">
                <center>
                    ADVERTENCIAS IMPORTANTES
                    <v-radio-group v-model="aviso_alerta.advertencia_importante">
                        <v-radio label="No se puede operar" value="No se puede operar" density="compact"></v-radio>
                        <v-radio label="Operar sólo lo indispensable" value="Operar sólo lo indispensable" density="compact"></v-radio>
                        <v-radio label="Operar bajo advertencia" value="Operar bajo advertencia" density="compact"></v-radio>
                        <v-radio label="Otros..." value="Otros" density="compact"></v-radio>
                    </v-radio-group>
                </center>
            </v-col>
            <v-col cols="12" md="6" style="background-color:#ffc000; color: #ffffff; padding:10px;">
                <center>
                    PROBLEMAS CRÍTICOS
                    <v-radio-group v-model="aviso_alerta.problemas_criticos" >

                        <v-radio label="Estado de cable/cadena crítico" value="Estado de cable/cadena crítico" density="compact"></v-radio>
                        <v-radio label="Puntos de apoyo criticos" value="Puntos de apoyo criticos" density="compact"></v-radio>
                        <v-radio label="Cargas axiales / No propias a la operacion" value="Cargas axiales / No propias a la operacion" density="compact"></v-radio>
                        <v-radio label="Sobrecalentamiento de motor" value="Sobrecalentamiento de motor" density="compact"></v-radio>
                        <v-radio label="Contactores flameados" value="Contactores flameados" density="compact"></v-radio>
                    </v-radio-group>
                </center>
            </v-col>
        </v-row>
        <br />
        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="12" style="background-color:#002060; color: #000000;  padding:10px;">
                <v-textarea v-model="aviso_alerta.desc_fallas" variant="solo" label="DESCRIPCIÓN DE FALLAS / ALCANCE:"></v-textarea>
            </v-col>
        </v-row>
        <br />
        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="12" style="background-color:#002060; color: #000000;  padding:10px;">
                <v-textarea v-model="aviso_alerta.diagnostico" variant="solo" label="DIAGNÓSTICO:"></v-textarea>
            </v-col>
        </v-row>
        <br />
        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; background-color:#c00000; color: #000000;  padding:10px;">
            <v-col cols="12" md="12">
                <v-card-actions class="hide-from-canvas">
                    <v-spacer></v-spacer>
                    <v-btn @click="!recording ? recognition.start() : recognition.stop(); recording = !recording" icon color="blue">
                        <v-icon>
                            {{ !recording ? 'mdi-microphone' : 'mdi-microphone-off' }}
                        </v-icon>
                    </v-btn>
                </v-card-actions>
                <v-textarea v-model="aviso_alerta.observaciones" variant="solo" label="OBSERVACIONES"></v-textarea>
            </v-col>

            <v-col class="hide-from-canvas" :cols="deviceCordova ? '10' : '12'" >
                <!--  type="file" @change="handleFileUpload" -->
                <v-file-input label="Subir Imagenes" variant="solo" accept="image/*" @change="handleFileUpload" show-size v-model="selectedImages" multiple></v-file-input>
            </v-col>

            <v-col v-if="deviceCordova" cols="2" class="hide-from-canvas" >
                <v-btn block @click="TakePhotoCamera"><v-icon>mdi-camera</v-icon></v-btn>
            </v-col>

            <v-col cols="12" md="12">
                <v-card variant="tonal" class="pa-5">
                    <v-row>
                        <v-col v-for="(image, index) in img_views" :key="index" cols="12" md="4">
                            <v-img :src="image" aspect-ratio="1"></v-img>
                        </v-col>
                        <!-- v-if="aviso_alerta.img_observaciones" -->
                        <v-col v-for="(image, index) in aviso_alerta.img_observaciones" :key="index" cols="12" md="4">
                            <v-img :src="$baseURL + image" aspect-ratio="1" :class="img_borrar.includes(image)?'imagen-opaca':''"></v-img>
                            <v-btn color="#da2128" @click="removerfoto(image)" v-if="img_borrar.includes(image)==false" ><v-icon>mdi-close</v-icon></v-btn>
                            <v-btn color="success" @click="recuperarfoto(image)" v-if="img_borrar.includes(image)==true" ><v-icon>mdi-check</v-icon></v-btn>
                        </v-col>

                    </v-row>
                    <v-card-actions class="hide-from-canvas">
                        <v-spacer></v-spacer>
                        <v-btn @click="uploadImage" :loading="uploading" :disabled="uploading" color="success" >Guardar imagenes</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

        </v-row>


        <br />
        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="3" style="background-color:#ffc000; padding:10px;">
                <center>
                    <span style="color:#fff;">
                        Firma de autorización del cliente
                    </span><br/>
                    <vue-drawing-canvas v-if="aviso_alerta.firma_cliente == '' || aviso_alerta.firma_cliente == null" :ref="id_canva" :canvasId="id_canva" :width="300" :height="200"></vue-drawing-canvas>
                    <template v-else>
                        <img :src="aviso_alerta.firma_cliente"/><br>
                        {{ firma_detalles.creationDate }}
                    </template>
                    
                    <br/>
                    <v-btn class="hide-from-canvas" @click="this.$refs.firma_cliente ? this.$refs.firma_cliente.reset() : borrarFirmaCliente();" :disabled="btn[0]" :loading="btn[0]" icon color="blue">
                        <v-icon>
                            mdi-reload
                        </v-icon>
                    </v-btn>
                </center>
            </v-col>
            <v-col cols="12" md="3" style="background-color:#002060; color: #ffffff; padding:10px;">
                <v-radio-group v-model="aviso_alerta.estatus">
                    <v-radio label="Cotizar" value="Cotizar" density="compact"></v-radio>
                    <v-radio label="Proceder" value="Proceder" density="compact"></v-radio>
                    <v-radio label="Facturar" value="Facturar" density="compact"></v-radio>
                </v-radio-group>
                <v-text-field v-model="aviso_alerta.correo_compras" v-if="aviso_alerta.estatus == 'Cotizar'" label="Correo" density="compact" variant="solo"></v-text-field>
                
            </v-col>
            <v-col cols="12" md="3" style="background-color:#ffc000; color: #ffffff; padding:10px;">
                <v-text-field v-model="aviso_alerta.fecha" label="FECHA ENTREGA" variant="solo" density="compact"></v-text-field>
            </v-col>
            <v-col cols="12" md="3" style="background-color:#002060; color: #ffffff; padding:10px;">
             
              
                    <center>
                        <span style="color:#fff;">RECIBIDO/VALIDACIÓN</span><br/>
                        <vue-drawing-canvas v-if="aviso_alerta.validacion == '' || aviso_alerta.validacion == null" :ref="id_canva_2" :canvasId="id_canva_2" :width="300" :height="200"></vue-drawing-canvas>
                        <template v-else>
                            <img :src="aviso_alerta.validacion"/>
                            {{ firma_detalles_aviso.creationDate }}
                        </template>
                        
                        <br/>
                        <small>Firma del tecnico</small>
                        <br/>
                        <v-btn class="hide-from-canvas" @click="this.$refs.firma_tecnico ? this.$refs.firma_tecnico.reset() : borrarValidacion()" :disabled="btn[1]" :loading="btn[1]" icon color="blue" >
                        <v-icon>
                            mdi-reload
                        </v-icon>
                    </v-btn>
                </center>
                
            </v-col>
        </v-row>

        <br />

        <v-row class="hide-from-canvas">
            <v-col>
                <!-- @click="viewdata_save=true; viewdata()" :disabled="viewdata_save" :loading="viewdata_save"  -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="info" @click="generatePDF">Descargar PDF</v-btn>
                <v-btn color="success" @click="save=true; _save(true)" :disabled="save" :loading="save">Guardar Cambios</v-btn>
                </v-card-actions>
            </v-col>
        </v-row>


    </div>
</template>
<script>
import VueDrawingCanvas from 'vue-drawing-canvas';
import { mapState, mapMutations } from "vuex";
import Swal from 'sweetalert2';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
export default {
    components: {
        VueDrawingCanvas
    },
    props: {
        detalles_os:Object,
        aviso_props:Object,
        showAlert:Function,
        hasPermission:Function,
        appconf:Object
    },
    computed: {
        ...mapState("Session", ['session_key', 'user', 'permisos', 'loggedin']),
    },
    data() {
        return {
            id_canva: "firma_cliente",
            id_canva_2:"firma_tecnico",
            save:false,
            btn:[],
            recognition: null,
            recording:false,



            deviceCordova:false,
            selectedImages: [],
            img_views: [],
            img_borrar:[],
            uploading:false,

            // files: [],
            // images: [],  
            firma_detalles:{
                creationDate:''
            },

            firma_detalles_aviso:{
                creationDate:''
            },

            aviso_alerta:  {
                id_aviso:null,
                id_ot:this.detalles_os.id_ot,
                advertencia_importante:'',
                problemas_criticos:'',
                desc_fallas:'',
                diagnostico:'',
                observaciones:'',
                firma_cliente:null,
                estatus:'',
                fecha:'',
                validacion:null,
                img_observaciones:null
            }
        }
    },
    watch: {
        selectedImages() {
            this.obtenerImagen();
        },
    },
    async created(){
        this.aviso_alerta = this.aviso_props;
        this.aviso_alerta.id_ot = this.detalles_os.id_ot;

        if(typeof this.aviso_alerta.img_observaciones !== 'undefined' && this.aviso_alerta.img_observaciones !== null && this.aviso_alerta.img_observaciones !== '' && this.aviso_alerta.img_observaciones !== 'undefined'){
            this.aviso_alerta.img_observaciones = this.aviso_alerta.img_observaciones.includes(';') ? this.aviso_alerta.img_observaciones.split(';') : (typeof this.aviso_alerta.img_observaciones == 'object' ? this.aviso_alerta.img_observaciones : [this.aviso_alerta.img_observaciones])
        } else {
            this.aviso_alerta.img_observaciones = []
        }

        if(typeof this.aviso_alerta.firma_cliente !== 'undefined' && this.aviso_alerta.firma_cliente !== null && this.aviso_alerta.firma_cliente !== '' && this.aviso_alerta.firma_cliente !== 'undefined'){
            this.firma_detalles =  await this.getFileDetails(this.aviso_alerta.firma_cliente);   
        }

        if(typeof this.aviso_alerta.validacion !== 'undefined' && this.aviso_alerta.validacion !== null && this.aviso_alerta.validacion !== '' && this.aviso_alerta.validacion !== 'undefined'){
            this.firma_detalles_aviso =  await this.getFileDetails(this.aviso_alerta.validacion);   
        }
    
    },

    async mounted() {

        const vm = this;
        document.addEventListener("deviceready", () => {
            vm.deviceCordova = true;
        });

        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        // if ('webkitSpeechRecognition' in window) {
        this.recognition = new SpeechRecognition();//new webkitSpeechRecognition();
        this.recognition.lang = "es-ES";
        this.recognition.continuous = true;
        
         this.recognition.onresult =  (event) => {
            let results = "";
            for (let i = 0; i < event.results.length; i++) {
                const transcript = event.results[i][0].transcript;
                results = results + transcript + "\n";
                // Haz algo con el texto reconocido, como mostrarlo en la interfaz de usuario.
            }
            this.aviso_alerta.observaciones = results;
        }
        

        // this.recognition.start();
        // } else {
        // console.error('El reconocimiento de voz no está soportado por este navegador.');
        // }



        },

    methods:{
        async generatePDF() {
            const content = document.getElementById("pdf-content-aviso");
            const canvas = await html2canvas(content, { useCORS: true });

            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'px',
                format: [canvas.width, canvas.height]
            });

            pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
            pdf.save("download.pdf");
        },
        async getFileDetails(url) {
            try {
                const path = url.split('/').slice(3).join('/');
                const response = await this.$axios.get(`${this.$baseURL}/getFileDetails.php?path=${path}`);

                const { name, size, creationDate, lastModified } = response.data;

                return { url, name, size, creationDate, lastModified };
            } catch (error) {
                console.error(`Error fetching details for ${url}:`, error);
                return { url, name: url.split('/').pop(), size: 0, creationDate: null, lastModified: null };
            }
        },

        removerfoto(img) {
            let find = this.img_borrar.filter(e => e === img);
            if (find.length === 0) {
                this.img_borrar.push(img);
            }
        },

        recuperarfoto(img){
            this.img_borrar.splice(img, 1);
        },

        TakePhotoCamera() {
            //como agrego esto a mi v-file-input sin perder las imagenes ya cargadas, es decir, para que a su vez se ejecute todo lo que desencadena @change="handleFileUpload"
            document.addEventListener("deviceready", () => {
                const options = {
                quality: 80,
                destinationType: window.cordova.camera.DestinationType.DATA_URL,
                encodingType: window.cordova.camera.EncodingType.JPEG,
                mediaType: window.cordova.camera.MediaType.PICTURE,
                sourceType: window.cordova.camera.PictureSourceType.CAMERA
                };

                window.cordova.camera.getPicture(
                    imageData => {
                        /* window.alert("imageData : " + imageData); */
                        // let imagenTomada = 'data:image/jpeg;base64,' + imageData;
                        // Convert base64 image to a File object
                        let byteString = atob(imageData);
                        let ab = new ArrayBuffer(byteString.length);
                        let ia = new Uint8Array(ab);
                        for (let i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                        }
                        let blob = new Blob([ia], { type: "image/jpeg" });
                        let file = new File([blob], "photo.jpg", { type: "image/jpeg" });
                        
                        this.selectedImages.push(file);
                        this.handleFileUpload({ target: { files: this.selectedImages } });
                        this.obtenerImagen();
                    

                    },
                    error => {
                        console.error('Error al tomar la foto:', error);
                    /*  window.alert("error : " + error); */
                    },
                    options
                );

            });


        },
        obtenerImagen() {
            this.img_views = [];
            this.selectedImages.forEach(file => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.img_views.push(e.target.result);
                };
                reader.readAsDataURL(file);
            });
        },

        handleFileUpload(event) {
            this.selectedFiles = event.target.files;
        },

        async uploadImage() {
            if(this.selectedFiles && this.selectedFiles.length > 0){
                let formData = new FormData();

                formData.append('path', 'avisos/obs/'+this.aviso_alerta.id_ot+'/');

                Array.from(this.selectedFiles).forEach(file => {
                    formData.append('files[]', file);
                    console.log(file);
                });


                // this.selectedFiles.forEach(file => {
                //     formData.append('files[]', file);
                // });

                this.uploading = true;
                await this.$axios.post(this.$uploadsURL, formData).then(async (r)=>{

                    let images = r.data;

                    if(images !== null && images !== ''){
                        if(images.includes(';')){
                            images.split(';').forEach(image => {
                                this.aviso_alerta.img_observaciones.push(image);
                            });
                        } else {
                            this.aviso_alerta.img_observaciones.push(images);
                        }
                        
                        await this.sendImage();
                    }
                    
                    
                }).catch( (error) => {

                    console.log(error);

                    window.showAlert({
                        activo: true,
                        texto: error.response.data,
                        color: "error",
                        vertical: true
                    });

                }).finally(async () => {
                    this.uploading = false;
                });
            } else {
                this.uploading = true;
                await this.sendImage().then(() => {
                    this.uploading = false;
                })
                
            }
            
        },



        async sendImage() {
            if(this.img_borrar.length < 1 && this.selectedImages.length < 1){
                window.showAlert({
                    activo: true,
                    texto: "No hay imagenes para guardar",
                    color: "error",
                    vertical: true
                });
                return;
            }

            let formData = new FormData();
            
            console.log(this.aviso_alerta);

            formData.append('id_ot', this.aviso_alerta.id_ot);

            if(this.img_borrar.length>0){
                let temp =   this.aviso_alerta.img_observaciones;
                let resultado = temp.filter(elemento => !this.img_borrar.includes(elemento));
                formData.append('img_observaciones', resultado.join(';'));
                // this.item_lev.files=resultado.join(';');
            } else {
                formData.append('img_observaciones', this.aviso_alerta.img_observaciones.join(';')); 
            }

            // this.uploading = true;
            
            this.aviso_alerta.img_observaciones = [];

            await this.$axios.post("update.aviso_alerta.imgs", formData).then(r => { 
                let images = r.data[0].img_observaciones;
                
                if(images !== null && images !== ''){
                    if(images.includes(';')){
                        images.split(';').forEach(image => {
                            this.aviso_alerta.img_observaciones.push(image);
                        });
                    } else {
                        this.aviso_alerta.img_observaciones.push(images);
                    }
                }

                //this.aviso_alerta.img_observaciones
                // this.$emit('updateImgsObs', images);

            }).catch(e => { 
                console.log(e)
            }).finally(()=> {
                this.selectedImages = [];
                // this.i
                // this.uploading = false;
            })
        },

        // onFileChange() {
        //     this.images = [];

        //     this.files.forEach(file => {
        //         const reader = new FileReader();
        //         reader.onload = (e) => {
        //         this.images.push(e.target.result);
        //         };
        //         reader.readAsDataURL(file);
        //     });
        // },
        // async uploadImages() {
        //     const formData = new FormData();
        //     this.files.forEach(file => {
        //         formData.append('images[]', file);
        //     });

        //     try {
        //         const response = await fetch('/upload', {
        //         method: 'POST',
        //         body: formData
        //         });
        //         if (response.ok) {
        //         this.$emit('success', 'Imágenes subidas con éxito');
        //         } else {
        //         this.$emit('error', 'Error al subir imágenes');
        //         }
        //     } catch (error) {
        //         this.$emit('error', 'Error en la solicitud');
        //     }
        // },
        async borrarFirmaCliente(){

            const resultado = await Swal.fire({
                title: '¿Estás seguro?',
                text: 'Se realizará una acción irreversible',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, estoy seguro'
            });

            if (resultado.isConfirmed) {

                this.btn[0] = true;
                this.aviso_alerta.firma_cliente = null;
                this.firma_detalles.creationDate = '';
                await this._save().then( () => {
                    this.btn[0] = false;
                } );
            }
        },

        async borrarValidacion(){

            const resultado = await Swal.fire({
                title: '¿Estás seguro?',
                text: 'Se realizará una acción irreversible',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, estoy seguro'
            });

            if (resultado.isConfirmed) {

                this.btn[1] = true;
                this.aviso_alerta.validacion = null;
                this.firma_detalles_aviso.creationDate = '';
                await this._save().then( () => {
                    this.btn[1] = false;
                } );
            }


            
        },

        dataURLtoBlob(dataURL) {
            let array, binary, i, len;
            binary = atob(dataURL.split(',')[1]);
            array = [];
            i = 0;
            len = binary.length;
            while (i < len) {
                array.push(binary.charCodeAt(i));
                i++;
            }
            return new Blob([new Uint8Array(array)], {
                type: 'image/png'
            });
        },

        // dataURLtoBlob(dataURL) {
        //     let array, binary, i, len;
        //     binary = atob(dataURL.split(',')[1]);
        //     array = [];
        //     i = 0;
        //     len = binary.length;
        //     while (i < len) {
        //         array.push(binary.charCodeAt(i));
        //         i++;
        //     }
        //     return new Blob([new Uint8Array(array)], {
        //         type: 'image/png'
        //     });
        // },

        // CanvasIsEmpty(canvaId) {
            
        //     const canvas = canvaId == 'firma_cliente' ? this.$refs.firma_cliente.canvas : this.$refs.firma_tecnico.canvas
        //     const context = canvas.getContext("2d");

        //     // Obtiene los datos de píxeles
        //     const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        //     const data = imageData.data;

        //     // Itera sobre los píxeles para verificar si están en blanco
        //     let isEmpty = true;
        //     for (let i = 0; i < data.length; i += 4) {
        //         if (data[i] !== 255 || data[i + 1] !== 255 || data[i + 2] !== 255) {
        //         // El píxel no está en blanco
        //         isEmpty = false;
        //         break;
        //         }
        //     }

        //     return isEmpty;
        // },

        async _save(gen =  false){ 

            if(this.aviso_alerta.firma_cliente == '' || this.aviso_alerta.firma_cliente == null){
                //que no este vacio..
                try {
                    const canvas = document.getElementById('firma_cliente');
                    const file = this.dataURLtoBlob( canvas.toDataURL() );

                    const fd = new FormData;
                    fd.append('path', 'avisos/firma_cliente/'+this.detalles_os.id_ot+'/');
                    fd.append('files', file);

                    await this.$axios.post(this.$uploadsURL, fd, {
                        headers: {
                        'Content-Type': 'multipart/form-data',
                        },
                    }).then(async(r) => {
                        
                        this.aviso_alerta.firma_cliente = new URL(r.data, this.$baseURL).toString();
                        this.firma_detalles =  await this.getFileDetails(this.aviso_alerta.firma_cliente);

                    }).finally( () => {
                        // this.loading = false;
                    } );

                } catch (error) {
                    console.error('Error al enviar la imagen al servidor:', error);
                // this.loading=false
                }

            }

            if(this.aviso_alerta.validacion == '' || this.aviso_alerta.validacion == null){
                //que no este vacio..

                try {
                    const canvas = document.getElementById('firma_tecnico');
                    const file = this.dataURLtoBlob( canvas.toDataURL() );

                    const fd = new FormData;
                    fd.append('path', 'avisos/firma_tecnico/'+this.detalles_os.id_ot+'/');
                    fd.append('files', file);

                    await this.$axios.post(this.$uploadsURL, fd, {
                        headers: {
                        'Content-Type': 'multipart/form-data',
                        },
                    }).then(async(r) => {
                        
                        this.aviso_alerta.validacion = new URL(r.data, this.$baseURL).toString();
                        this.firma_detalles_aviso =  await this.getFileDetails(this.aviso_alerta.validacion);   
                    }).finally( () => {
                        // this.loading = false;
                    } );

                } catch (error) {
                    console.error('Error al enviar la imagen al servidor:', error);
                    // this.loading=false
                }

            }

            if(gen){
                const hiddenElements = document.querySelectorAll('.hide-from-canvas');
                hiddenElements.forEach(el => el.style.display = 'none');


                const content = document.getElementById("pdf-content-aviso");
                const canvas = await html2canvas(content, { useCORS: true });

                const file = this.dataURLtoBlob (canvas.toDataURL());

                hiddenElements.forEach(el => el.style.display = '');

                const fd = new FormData;
                fd.append('path', 'reportes/avisoalerta/'+this.detalles_os.id_ot+'/');
                fd.append('files', file);

                await this.$axios.post(this.$uploadsURL, fd, {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                }).then(async(r) => {
                    const reporte_aviso_img = new URL(r.data, this.$baseURL).toString();
                    this.detalles_os.reporte_aviso_img = reporte_aviso_img;
                    this.$emit('update', reporte_aviso_img);

                    this.$emit('updateAdvertencia', this.aviso_alerta.advertencia_importante);
                    

                    await this.sendUpdate();
                });
            } else {
                this.sendUpdate();
            }
        },
        async sendUpdate(){
            await this.$axios.post('guardar.aviso.orden.servicio', {
                id_ot:this.detalles_os.id_ot,
                reporte_aviso_img:this.detalles_os.reporte_aviso_img,
                advertencia_importante:this.aviso_alerta.advertencia_importante,
                problemas_criticos:this.aviso_alerta.problemas_criticos,
                desc_fallas:this.aviso_alerta.desc_fallas,
                diagnostico:this.aviso_alerta.diagnostico,
                observaciones:this.aviso_alerta.observaciones,
                firma_cliente:this.aviso_alerta.firma_cliente,
                estatus:this.aviso_alerta.estatus,
                fecha:this.aviso_alerta.fecha,
                validacion:this.aviso_alerta.validacion,
                correo_compras:this.aviso_alerta.correo_compras
            } )
            .then(r => { 
                window.showAlert({
                    activo: true,
                    texto: "Aviso de alerta actualizado con éxito!",
                    color: "success",
                    vertical: true
                });
            }).catch(e=>{
                window.showAlert({
                    activo: true,
                    texto: "Lo sentimos! Algo salio mal...",
                    color: "error",
                    vertical: true
                });
            }).finally( () => {
                this.save=false
            });
        }
    }

}
</script>