<template>
    <v-row>
        <!-- {{ creator ? 'es creador' : 'lector' }} -->
        <v-col cols="12">
            <v-row>
                <v-col cols="3" >
                    <div style="display: flex; justify-content: center;">
                    Estatus: {{estatus_compromiso.text}}
                    </div>
                    <div :style="{'backgroundColor': estatus_compromiso.color, 'padding':'3px'}"></div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>


<script>

    import DetePikerInput from "@/components/DatePikerInput.vue";
    import { mapState } from "vuex";
    import moment from 'moment';

    export default {
        components: {
            DetePikerInput
        },
        setup() { },
        computed: {
        ...mapState("Session", ['session_key', 'user', 'permisos', 'loggedin']),
        ...mapState(['router']),
        screenWidth() {
            return window.innerWidth;
        }
        },

        props: {
            visita: Object,
            creator: Boolean,
            showAlert: Function,
            hasPermission: Function
        },
        data() {
            return {
                estatus_compromiso:{}
            };
        },

        async created() {
            switch (this.visita.estatus) {
                
                case 0:
                    this.estatus_compromiso={
                        text:"Pendiente",
                        color:"#2196F3"
                    }
                break;

                case 1:
                    this.estatus_compromiso={
                        text:"Iniciado",
                        color:"#388E3C"
                    }
                break;

                case 2:
                    this.estatus_compromiso={
                        text:"Finalizado",
                        color:"#388E3C"
                    }
                break;

                case 3:
                    this.estatus_compromiso={
                        text:"Cancelado",
                        color:"red"
                    }
                break;

                case 4:
                    this.estatus_compromiso={
                        text:"Autorizado",
                        color:"green"
                    }
                break;
                default:
                    this.estatus_compromiso={
                            text:"EN ASIGNACION",
                            color:"#388E3C"
                        }
                break;
            }
        },

        mounted(){
            // this.Loading = false;
        },

        methods: {
            
        },
    };
</script>
<!-- si soy el responsable tendre el permiso de editor, sino solo leectura. o si soy con admin_filter, pues si -->