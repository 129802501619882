<template>
  <div id="app" :style="{ backgroundColor: backgroundColor }">
    <v-snackbar
      v-model="alerta.activo"
      multi-line
      location="top right"
      :color="alerta.color"
    >
      <strong> {{ alerta.texto }} </strong>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text @click="alerta.activo = false" v-bind="attrs">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

    <ScreenSplash v-if="DOMLoaded == false" />

    <v-layout v-else class="rounded rounded-md scrollable-container">
      <template v-if="loggedin == false">
        <v-main>
          <v-container fluid>
            <Login
              :Login="Login"
              :alerta="alerta"
              :appconf="appconf"
            />
            <v-footer absolute class="blue" dark >
              <v-col class="text-center" cols="12" >  2023 &copy; <strong>S21Sistem</strong>  <br> <small><i>Todos los derechos reservados</i></small></v-col>
            </v-footer> 
          </v-container>
        </v-main>
      </template>

      <template v-else>

        <navSideBar :hasPermission="hasPermission" :appconf="appconf" />
      


        <v-main :style="{ backgroundColor: backgroundColor }">
          <br/>
          <router-view :showAlert="showAlert" :hasPermission="hasPermission" :appconf="appconf" />
        </v-main>
        <!---
        <v-footer app color="#f5f5f5">
          <v-row justify="center" no-gutters>
            <v-col>
              <v-tabs v-model="tabs" color="#bf1c7f" grow centered stacked>
                <v-tab :value="0" to="/recibo">
                  <v-icon>mdi-truck-fast</v-icon>
                  <small>Entradas</small>
                </v-tab>

                <-- to="/scaner-camara" --
                <v-tab :value="2" to="/scaner-camara">
                  <--    <v-icon>{{tipo_app_funcion=="funcion_scaner"?"mdi-camera":"mdi-keyboard"}}</v-icon> --

                  <div style="display: flex; justify-content: center">
                    <v-icon>mdi-qrcode-scan</v-icon>
                  </div>
                  <small>Codigo</small>
                </v-tab>

                <v-tab :value="1" to="/" stacked>
                  <-- <v-badge
                    dot
                    color="error"
                  >
                  <v-icon>mdi-package-variant</v-icon>
                  
                  </v-badge> --

                  <v-icon>mdi-package-variant</v-icon>

                  <small>Reservas</small>
                </v-tab>

                <v-tab :value="3" @click="salir()">
                  <v-icon>mdi-menu</v-icon>
                  <small>Mas</small>
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-footer>-->
      </template>
    </v-layout>
  </div>
</template>

<script>
// mapGetters
import { mapActions, mapState, mapMutations } from "vuex";
import Login from "@/views/Login.vue";
import navSideBar from "@/components/NavSideBar.vue";
import ScreenSplash from '@/components/ScreenSplash.vue';

export default {
  name: "App",
  components: {
    Login,
    navSideBar,
    ScreenSplash
  },
  data() {
    return {
      DOMLoaded:false,
      syster_number: 10,
      backgroundColor: '#ffffff',
      appconf:{},
      tabs: null,
      alerta: { activo: false, texto: "", color: "error", vertical: true },
      message: "",
      timer: null,
    };
  },

  watch: {
  },

  computed: {
    ...mapState("Session", ['session_key', 'user','permisos', 'loggedin']),
    ...mapState(['router']),
  }, 
  
  async mounted() {
    // this.scren();
    // let vm = this;
    

    await this.$axios.post('app.config', {
      app_key:'carvajal-app'
    }).then( (r) => {
      this.appconf = r.data[0];
      document.title = this.appconf.name;
    } ).catch( (error) => {
      //showalert...
    } ).finally( () => {
      this.DOMLoaded = true;
    } );

    // //ya debio a ver hecho el de autentificar? 
    // setTimeout(() => {
    //     vm.DOMLoaded = true;
    //   }, 500);
  },

  async created() {
    window.hasPermission = this.hasPermission;
    window.showAlert = this.showAlert;

    


    this.session_check(); //despues de autentificar ponemos el this.DOMLoaded = true
    window.handleNativeData = this.handleNativeCode;
  },
  methods: {
    ...mapMutations(['RouterParams']),
    ...mapActions("Session", ["Login", "getSessionData"]),
    ...mapMutations("Session", ['session_destroy']),

    cleanstore(){
      this.RouterParams(null);
    },
    
    handleNativeCode(codigo) {
      // Aquí manejas el código recibido desde el lado nativ
      let jsonData = JSON.parse(codigo);
      this.RouterParams(jsonData.params); 
      //params: jsonData.params
      this.$router.push({ name: jsonData.ViewComponent});
    },

    showAlert(alertData){
      this.alerta= alertData;
    },

    hasPermission(key_permission){
      return this.user.super_admin == 1 || this.permisos.has(key_permission)
    },

    async session_check() {

      //si actualiza un dato de su usuario tambien se actualiza el token.... 
      
      // localStorage.setItem("session.data", response.data);


      if (typeof Storage !== "undefined") {
          if (localStorage.getItem("session.key") != null) {
            this.getSessionData();
          }
        } else {
          //el navegador no soporte cookies?
        }

    },

    
  },
};
</script>

<style>
::-webkit-scrollbar {
    width: 10px !important;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgb(255, 160, 0);
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

::-webkit-scrollbar-track {
    display: none;
}

/* html {
  background-color: #ffffff;
} 
body {
  background-color: #ffffff;
} */
 
.v-main {
  background-color: #ffffff; /* Cambia al color que desees */
}

/* Asegúrate de que el fondo de v-main sea siempre el deseado */

video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 9999;
  opacity: 1; 
}

</style>