<template>
    <div id="pdf-content-diagnostico">
        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="3" style="background-color:#002060; color: #ffffff;">
                <!-- <center>
                    <br />
                    <h2>LOGO</h2>
                </center> -->
                <center>
                <img :src="appconf.logo" :width="appconf.logo_width" :height="appconf.logo_height"/>
            </center>
            </v-col>
            <v-col cols="12" md="6" style="background-color:#c00000; color: #ffffff;">
                <center><br />
                    <h1> DIAGNOSTICO FINAL</h1>
                </center>
            </v-col>
            <v-col v-html="appconf.ot_iso" cols="12" md="3" style="background-color:#ffc000; color: #ffffff;">
                
            </v-col>
        </v-row>
        <br />

        <div style="background-color: #c00000; padding: 10px; width: 100%; border-radius: 15px;
    border:solid 8px #000;">
            <!-- <v-textarea label="NOTAS" variant="solo"></v-textarea> -->

            <v-row>
                <v-col cols="12">
                    

                    <v-btn @click="this.$refs.diagnostico_final ? this.$refs.diagnostico_final.reset() : removeImg();" icon color="blue" class="ml-3 mb-4 hide-from-canvas">
                        <v-icon>
                            mdi-reload
                        </v-icon>
                    </v-btn>
                    <center>
                        <vue-drawing-canvas v-if="detalles_os.diagnostico_imagen == '' || detalles_os.diagnostico_imagen == null" :ref="id_canva" :canvasId="id_canva" :width="screenWidth"></vue-drawing-canvas>
                        <img v-else :src="detalles_os.diagnostico_imagen"/>
                    </center>
                    <!-- {{items_menu_steps}} fe -->
                </v-col>
                <v-col cols="12">
                    <v-card-actions class="hide-from-canvas">
                        <v-spacer></v-spacer>
                        <v-btn @click="!recording ? recognition.start() : recognition.stop(); recording = !recording" icon color="blue">
                            <v-icon>
                                {{ !recording ? 'mdi-microphone' : 'mdi-microphone-off' }}
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-textarea v-model="transcript" variant="solo" label="Observaciones"></v-textarea>
                </v-col>

            <v-col :cols="deviceCordova ? '10' : '12'" class="hide-from-canvas">
                <!--  type="file" @change="handleFileUpload" -->
                <v-file-input label="Subir Imagenes" variant="solo" accept="image/*" @change="handleFileUpload" show-size v-model="selectedImages" multiple></v-file-input>
            </v-col>

            <v-col cols="2" v-if="deviceCordova" class="hide-from-canvas">
                <v-btn block @click="TakePhotoCamera"><v-icon>mdi-camera</v-icon></v-btn>
            </v-col>

            <v-col cols="12" md="12">
                <v-card variant="tonal" class="pa-5">
                    <v-row>
                        <v-col v-for="(image, index) in img_views" :key="index" cols="12" md="4">
                            <v-img :src="image" aspect-ratio="1"></v-img>
                        </v-col>
                        <!-- v-if="aviso_alerta.img_observaciones" -->
                        <v-col v-for="(image, index) in detalles_os.img_observaciones" :key="index" cols="12" md="4">
                            <v-img :src="$baseURL + image" aspect-ratio="1" :class="img_borrar.includes(image)?'imagen-opaca':''"></v-img>
                            <v-btn class="hide-from-canvas" color="#da2128" @click="removerfoto(image)" v-if="img_borrar.includes(image)==false"><v-icon>mdi-close</v-icon></v-btn>
                            <v-btn class="hide-from-canvas" color="success" @click="recuperarfoto(image)" v-if="img_borrar.includes(image)==true"><v-icon>mdi-check</v-icon></v-btn>
                        </v-col>

                    </v-row>
                    <!-- <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="uploadImage" :loading="uploading" :disabled="uploading" color="success">Guardar imagenes</v-btn>
                    </v-card-actions> -->
                </v-card>
            </v-col>

                <!-- this.detalles_os -->

                <v-col cols="12" v-if="items_menu_steps && items_menu_steps.length > 0" class="hide-from-canvas">
                    <v-table style="max-height: 600px; overflow-y: auto;">
                        <template v-for="(item, index) in items_menu_steps" :key="index">
                            <template v-if="item.items.filter(_subitem => parseInt(_subitem.selected_r) >= 3 && parseInt(_subitem.selected_r) < 10).length > 0">
                                <thead>
                                    <tr>
                                        <th colspan="3" class="text-center text-h6">{{ item.title }}</th>
                                    </tr>
                                    <tr>
                                        <th class="text-center">COMPONENTE</th>
                                        <th class="text-center">ESTADO</th>
                                        <th class="text-center">OBSERVACIONES</th>
                                        <th class="text-right">EVIDENCIAS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr  v-for="(subitem, subindex) in item.items.filter(_subitem => parseInt(_subitem.selected_r) >= 3 && parseInt(_subitem.selected_r) < 10)" :key="subindex">
                                        <td class="text-center">{{ subitem.subtitle }} 
                                            <span v-if="subitem.subcampo_1"> <br>
                                                <strong>{{ subitem.subcampo_1 }}:</strong> {{ subitem.value_subcampo_1 }}
                                            </span>

                                            <span v-if="subitem.subcampo_2"> <br>
                                                <strong>{{ subitem.subcampo_2 }}:</strong> {{ subitem.value_subcampo_2 }}
                                            </span>

                                            <span v-if="subitem.subcampo_3"> <br>
                                                <strong>{{ subitem.subcampo_3 }}:</strong> {{ subitem.value_subcampo_3 }}
                                            </span>
                                        </td>
                                        <td class="text-center">{{ getDescItem( parseInt(subitem.selected_r) ) }}</td>
                                        <td class="text-center">{{ subitem.observaciones }}</td>
                                        <td class="text-right">
                                            <v-row v-if="typeof subitem.images_views !== 'undefined' && subitem.images_views.length > 0">
                                                <v-col v-for="(image, index_image) in subitem.images_views" :key="index_image" class="d-flex child-flex" cols="4">
                                                    <v-img :src="$baseURL + image" :lazy-src="image" aspect-ratio="1" cover class="bg-grey-lighten-2" style="cursor: pointer;" @click="openImageModal($baseURL + image)"></v-img>

                                                </v-col>
                                            </v-row>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </template>
                    </v-table>
                </v-col>

                <v-col cols="12" class="hide-from-canvas">
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <!-- <v-btn @click="uploadImage" :loading="uploading" :disabled="uploading" color="success">Guardar imagenes</v-btn> -->
                        <v-btn color="green" @click="save()" :disabled="loading || uploading" :loading="loading || uploading">
                            Guardar cambios
                        </v-btn>
                    </v-card-actions>
                </v-col>
            </v-row>


        </div>


    </div>

    <v-dialog v-model="isModalOpen" max-width="600px">
      <v-card>
        <v-img :src="selectedImage" aspect-ratio="1" cover></v-img> <!-- Imagen seleccionada -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="isModalOpen = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>
<script>
import html2canvas from "html2canvas";

import elmentos_checklist from "./CheckListComponent.json";
import VueDrawingCanvas from 'vue-drawing-canvas';
import Swal from 'sweetalert2';

export default {
    components: {
        VueDrawingCanvas
    },
    props: {
        appconf:Object,
        detalles_os:Object,
        checklist_data:Object,
        showAlert:Function,
        hasPermission:Function
    },
    data() {
        return {
            transcript: '',
            recognition: null,
            draw: null,
            backgroundImage: null,
            id_canva: "diagnostico_final",
            recording:false,
            loading:false,

            isModalOpen: false,       // Controla la apertura del modal
            selectedImage: null  ,

            deviceCordova:false,
            selectedImages: [],
            img_views: [],
            img_borrar:[],
            uploading:false,

            items_menu_steps: this.decodeCheckList(),

        }
    },
    computed: {
        screenWidth() {
            var ancho = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if (ancho) {
                let resultado =0;
            
                let med=0;
                if(ancho>=1000){
                    med = ancho/12;
                    let estado= med*2;
                    resultado =ancho-estado-100;
                }else if(ancho < 1000){
                    resultado =ancho-100;
                }else{
                    resultado =200
                }
                return resultado;
            } else {
                return 200;
            }
        }


    },
    watch: {
        selectedImages() {
            this.obtenerImagen();
        },
    },
    created() {
        this.transcript = this.detalles_os.diagnostico_texto

        if(typeof this.detalles_os.img_observaciones !== 'undefined' && this.detalles_os.img_observaciones !== null && this.detalles_os.img_observaciones !== '' && this.detalles_os.img_observaciones !== 'undefined'){
            this.detalles_os.img_observaciones = this.detalles_os.img_observaciones.includes(';') ? this.detalles_os.img_observaciones.split(';') : (typeof this.detalles_os.img_observaciones == 'object' ? this.detalles_os.img_observaciones : [this.detalles_os.img_observaciones])
        } else {
            this.detalles_os.img_observaciones = []
        }

    },
    mounted() {

        const vm = this;
        document.addEventListener("deviceready", () => {
            vm.deviceCordova = true;
        });

        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        // if ('webkitSpeechRecognition' in window) {
        this.recognition = new SpeechRecognition();//new webkitSpeechRecognition();
        this.recognition.lang = "es-ES";
        this.recognition.continuous = true;
        console.log(this.recognition);
        this.recognition.onresult = (event) => {
            let results = "";
            for (let i = 0; i < event.results.length; i++) {
                const transcript = event.results[i][0].transcript;
                results = results + transcript + "\n\r";
                // Haz algo con el texto reconocido, como mostrarlo en la interfaz de usuario.
            }

            this.transcript = results;

        }

        // this.recognition.start();
        // } else {
        // console.error('El reconocimiento de voz no está soportado por este navegador.');
        // }



    },
    methods: {
        getDescItem(estatus){
            switch(estatus){
                case 3: return 'Reparar';
                case 4: return 'Requiere cambio';
                case 5: return 'Bajo de aceite';
                case 6: return 'Requiere cambio de aceite';
                case 7: return 'Requiere limpieza';
                case 8: return 'Requiere pintura';
                case 9: return 'Corrosión';
                default: return '';
            }
        },
        decodeCheckList(){
            try {
                return JSON.parse(atob(this.checklist_data.data_json));
            } catch (error) {
                console.error("Error decoding base64:", error);
                return elmentos_checklist;
            }
        },

        removerfoto(img) {
            let find = this.img_borrar.filter(e => e === img);
            if (find.length === 0) {
                this.img_borrar.push(img);
            }
        },

        recuperarfoto(img){
            this.img_borrar.splice(img, 1);
        },
        openImageModal(image) {
            this.selectedImage = image;  // Asigna la imagen seleccionada
            this.isModalOpen = true;     // Abre el modal
        },
        TakePhotoCamera() {
            //como agrego esto a mi v-file-input sin perder las imagenes ya cargadas, es decir, para que a su vez se ejecute todo lo que desencadena @change="handleFileUpload"
            document.addEventListener("deviceready", () => {
                const options = {
                quality: 80,
                destinationType: window.cordova.camera.DestinationType.DATA_URL,
                encodingType: window.cordova.camera.EncodingType.JPEG,
                mediaType: window.cordova.camera.MediaType.PICTURE,
                sourceType: window.cordova.camera.PictureSourceType.CAMERA
                };

                window.cordova.camera.getPicture(
                    imageData => {
                        /* window.alert("imageData : " + imageData); */
                        // let imagenTomada = 'data:image/jpeg;base64,' + imageData;
                        // Convert base64 image to a File object
                        let byteString = atob(imageData);
                        let ab = new ArrayBuffer(byteString.length);
                        let ia = new Uint8Array(ab);
                        for (let i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                        }
                        let blob = new Blob([ia], { type: "image/jpeg" });
                        let file = new File([blob], "photo.jpg", { type: "image/jpeg" });
                        
                        this.selectedImages.push(file);
                        this.handleFileUpload({ target: { files: this.selectedImages } });
                        this.obtenerImagen();
                    

                    },
                    error => {
                        console.error('Error al tomar la foto:', error);
                    /*  window.alert("error : " + error); */
                    },
                    options
                );

            });


        },
        obtenerImagen() {
            this.img_views = [];
            this.selectedImages.forEach(file => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.img_views.push(e.target.result);
                };
                reader.readAsDataURL(file);
            });
        },

        handleFileUpload(event) {
            this.selectedFiles = event.target.files;
        },

        async uploadImage() {
            if(this.selectedFiles && this.selectedFiles.length > 0){
                let formData = new FormData();

                formData.append('path', 'diagnosticos/obs/'+this.detalles_os.id_ot+'/');

                Array.from(this.selectedFiles).forEach(file => {
                    formData.append('files[]', file);
                    console.log(file);
                });


                // this.selectedFiles.forEach(file => {
                //     formData.append('files[]', file);
                // });

                this.uploading = true;
                await this.$axios.post(this.$uploadsURL, formData).then(async (r)=>{

                    let images = r.data;

                    if(images !== null && images !== ''){
                        if(images.includes(';')){
                            images.split(';').forEach(image => {
                                this.detalles_os.img_observaciones.push(image);
                            });
                        } else {
                            this.detalles_os.img_observaciones.push(images);
                        }
                        
                        await this.sendImage();
                    }
                    
                    
                }).catch( (error) => {

                    console.log(error);

                    window.showAlert({
                        activo: true,
                        texto: error.response.data,
                        color: "error",
                        vertical: true
                    });

                }).finally(async () => {
                    this.uploading = false;
                });
            } else {
                this.uploading = true;
                await this.sendImage().then(() => {
                    this.uploading = false;
                })
                
            }
            
        },

        async sendImage() {
            if(this.img_borrar.length > 0 || this.selectedImages.length > 0){
            

                let formData = new FormData();
                formData.append('id_ot', this.detalles_os.id_ot);

                if(this.img_borrar.length>0){
                    let temp =   this.detalles_os.img_observaciones;
                    let resultado = temp.filter(elemento => !this.img_borrar.includes(elemento));
                    formData.append('img_observaciones', resultado.join(';'));
                    // this.item_lev.files=resultado.join(';');
                } else {
                    formData.append('img_observaciones', this.detalles_os.img_observaciones.join(';')); 
                }

                // this.uploading = true;
                
                this.detalles_os.img_observaciones = [];

                await this.$axios.post("update.diagnostico.imgs", formData).then(r => { 
                    let images = r.data[0].img_observaciones;
                    
                    if(images !== null && images !== ''){
                        if(images.includes(';')){
                            images.split(';').forEach(image => {
                                this.detalles_os.img_observaciones.push(image);
                            });
                        } else {
                            this.detalles_os.img_observaciones.push(images);
                        }
                    }

                    // this.$emit('updateImgsObs', images);
                }).catch(e => { 
                    console.log(e)
                }).finally(()=> {
                    this.selectedImages = [];
                    // this.i
                    // this.uploading = false;
                })
            }
        },

        async setImage(event) {
            let URL = window.URL;
            this.backgroundImage = URL.createObjectURL(event.target.files[0]);
            await this.$refs.diagnostico_final.redraw();
        },

        async removeImg(){
            const resultado = await Swal.fire({
                title: '¿Estás seguro?',
                text: 'Se realizará una acción irreversible',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, estoy seguro'
            });

            if (resultado.isConfirmed) {
                // Lógica a ejecutar si el usuario confirma
                // Swal.fire('Acción confirmada', '', 'success');
                this.detalles_os.diagnostico_imagen = ''; 
                await this.$axios.post('ot.diagnostic.save', {
                    id_ot: this.detalles_os.id_ot,
                    diagnostico_imagen: ""
                });
            }

           
        },
        async save(){
            await this.uploadImage().then(async () => {

                const hiddenElements = document.querySelectorAll('.hide-from-canvas');
                hiddenElements.forEach(el => el.style.display = 'none');


                const content = document.getElementById("pdf-content-diagnostico");
                const canvas = await html2canvas(content, { useCORS: true });

                const file = this.dataURLtoBlob (canvas.toDataURL());

                hiddenElements.forEach(el => el.style.display = '');

                const fd = new FormData;
                fd.append('path', 'reportes/diagnostico/'+this.detalles_os.id_ot+'/');
                fd.append('files', file);

                await this.$axios.post(this.$uploadsURL, fd, {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                }).then(async(r) => {
                    const reporte_diagnostico_img = new URL(r.data, this.$baseURL).toString();
                    this.detalles_os.reporte_diagnostico_img = reporte_diagnostico_img;
                    this.$emit('update', reporte_diagnostico_img);
                    

                    await this.guardarCambios();
                });
            });
            
        },

        async guardarCambios(){
            this.loading=true;

            if(this.$refs.diagnostico_final){
                try {
                    const canvas = document.getElementById('diagnostico_final');
                    const file = this.dataURLtoBlob( canvas.toDataURL() );

                    const fd = new FormData;
                    fd.append('path', 'diagnosticos/'+this.detalles_os.id_ot+'/');
                    fd.append('files', file);

                    await this.$axios.post(this.$uploadsURL, fd, {
                        headers: {
                        'Content-Type': 'multipart/form-data',
                        },
                    }).then(async(r) => {
                        
                        let imagen_url = new URL(r.data, this.$baseURL).toString();
                        
                        await this.$axios.post('ot.diagnostic.save', {
                            id_ot: this.detalles_os.id_ot,
                            diagnostico_texto: this.transcript,
                            reporte_diagnostico_img:this.detalles_os.reporte_diagnostico_img,
                            diagnostico_imagen: imagen_url
                        }).then( () => {
                            this.detalles_os.diagnostico_imagen = imagen_url;
                        } );

                    }).finally( () => {
                        this.loading = false;
                    } );

                    } catch (error) {
                    console.error('Error al enviar la imagen al servidor:', error);
                    this.loading=false
                    }
            } else {
                await this.$axios.post('ot.diagnostic.save', {
                    id_ot: this.detalles_os.id_ot,
                    reporte_diagnostico_img:this.detalles_os.reporte_diagnostico_img,
                    diagnostico_texto: this.transcript
                }).then( () => {
                    this.loading=false
                } );
            }
        },

        dataURLtoBlob(dataURL) {
            let array, binary, i, len;
            binary = atob(dataURL.split(',')[1]);
            array = [];
            i = 0;
            len = binary.length;
            while (i < len) {
                array.push(binary.charCodeAt(i));
                i++;
            }
            return new Blob([new Uint8Array(array)], {
                type: 'image/png'
            });
        }
        // dataURLtoBlob(dataURL) {
        //     const parts = dataURL.split(';base64,');
        //     const contentType = parts[0].split(':')[1];
        //     const raw = window.atob(parts[1]);
        //     const array = new Uint8Array(new ArrayBuffer(raw.length));

        //     for (let i = 0; i < raw.length; i++) {
        //     array[i] = raw.charCodeAt(i);
        //     }

        //     return new Blob([array], { type: contentType });
        // },
    }
}
</script>
