<template>
    <div>
        <v-row>
            <v-col cols="12">
                <!-- :items="items_menu_steps" prev-text="anterior" next-text="siguente" -->
                <v-stepper v-model="paso" :items="items_menu_steps">
                    <!-- <v-stepper-header class="overflow-x-auto">
                        <v-stepper-step 
                            v-for="(step,n) in items_menu_steps"
                            :key="n"
                            :complete="stepComplete(n+1)"
                            :step="n+1"
                            :rules="[value => !!step.valid]" 
                            :color="stepStatus(n+1)">
                        </v-stepper-step>
                    </v-stepper-header> -->
                    <!-- <v-stepper-content v-for="(step,n) in items_menu_steps" :step="n+1" :key="n"> -->
                    <template v-for="(step, n) in items_menu_steps" :v-slot:item="`item.${n}`" :key="n">
                        <v-card v-show="n + 1 == paso" :title="step.title" flat>
                            <v-form :ref="'stepForm'" v-model="step.valid" lazy-validation>
                                <v-sheet border>
                                    <v-table>
                                        <thead>
                                            <tr>
                                                <th  width="400px">Componente</th>
                                                <th><span>Buena</span></th>
                                                <th><span>Ajustar</span></th>
                                                <th><span>Reparar</span></th>
                                                <th><span>Req. cambio</span></th>
                                                <th><span>Bajo de aceite</span></th>
                                                <th><span>Req.<br/> cambio de aceite</span></th>
                                                <th><span>Req. Limpieza</span></th>
                                                <th><span>Req. Pintura</span></th>
                                                <th><span>Corrosión</span></th>
                                                <th><span>NA</span></th>
                                                <th width="400px">Observaciones</th>
                                                <th >Dictar</th>
                                                <th  width="400px">Imagenes</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr v-for="(comp, ix) in step.items" :key="ix">
                                                <td>
                                                    {{ comp.subtitle }}

                                                    <!-- subcampo_1 = DESCRIPCION COMPONENTE -->
                                                    <!-- subcampo_2 = DESCRIPCION SUB COMPONENTE -->
                                                    <!-- subcampo_3 = DESCRIPCION SUB COMPONENTE DOS -->
                                                
                                                    <v-text-field density="compact" variant="outlined" v-if="comp.subcampo_1" v-model="comp.value_subcampo_1" :label="comp.subcampo_1" :rules="applyRules(comp)"></v-text-field>
                                                    <v-text-field density="compact" variant="outlined" v-if="comp.subcampo_2 && comp.subcampo_2!='(SI) o (NO)'" v-model="comp.value_subcampo_2" :label="comp.subcampo_2" :rules="applyRules(comp)"></v-text-field>

                                                    <v-select  v-else-if="comp.subcampo_2=='(SI) o (NO)'" v-model="comp.value_subcampo_2" :label="comp.subcampo_2"
                                                    :items="['SI', 'NO']"
                                                    density="compact" variant="outlined" :rules="applyRules(comp)"></v-select>


                                                    <v-text-field density="compact" variant="outlined" v-if="comp.subcampo_3 && comp.subcampo_3!='DESGASTE (SI) o (NO)'" v-model="comp.value_subcampo_3" :label="comp.subcampo_3" :rules="applyRules(comp)"></v-text-field>
                                                
                                                    <v-select  v-else-if="comp.subcampo_3=='DESGASTE (SI) o (NO)'" v-model="comp.value_subcampo_3" :label="comp.subcampo_3"
                                                    :items="['SI', 'NO']"
                                                    density="compact" variant="outlined" :rules="applyRules(comp)"></v-select>
                                                    
                                                </td>
                                                <td>
                                                    <v-checkbox v-model="comp.selected_r" value="1" :rules="applyRules(comp)"></v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox v-model="comp.selected_r" value="2" :rules="applyRules(comp)"></v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox v-model="comp.selected_r" value="3" :rules="applyRules(comp)"></v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox v-model="comp.selected_r" value="4" :rules="applyRules(comp)"></v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox v-model="comp.selected_r" value="5" :rules="applyRules(comp)"></v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox v-model="comp.selected_r" value="6" :rules="applyRules(comp)"></v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox v-model="comp.selected_r" value="7" :rules="applyRules(comp)"></v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox v-model="comp.selected_r" value="8" :rules="applyRules(comp)"></v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox v-model="comp.selected_r" value="9" :rules="applyRules(comp)"></v-checkbox>
                                                </td>

                                                <td>
                                                    <v-checkbox v-model="comp.selected_r" value="10" :rules="applyRules(comp)"></v-checkbox>
                                                </td>
                                            
                                                <td>
                                                    <v-text-field v-model="comp.observaciones" label="Observaciones" :rules="applyRules(comp)"></v-text-field>
                                                </td>
                                                <td>
                                                    <!-- {{  comp.recording  }} -->
                                                    <v-btn @click="TranscribirAudio(comp);" icon color="blue">
                                                        <v-icon>
                                                            {{ (typeof comp.recording == 'undefined' || comp.recording == false) ? 'mdi-microphone' : 'mdi-dots-horizontal' }}
                                                        </v-icon>
                                                    </v-btn>
                                                </td>
                                                <td>
                                                <ImagenViwerComponent :comp="comp" :detalles_os="detalles_os" :viewdata="viewdata" :showAlert="showAlert" :hasPermission="hasPermission" :appconf="appconf"/>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-table>
                                </v-sheet>
                            </v-form>

                            <v-card-actions>
                                <v-btn text @click="paso = n" v-if="n > 0"><v-icon>mdi-arrow-left</v-icon> Volver</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn v-if="n+1 < lastStep" color="success" @click="validate(n)" :disabled="!step.valid">Siguiente <v-icon>mdi-arrow-right</v-icon></v-btn>
                                <v-btn v-else color="success" @click="done()">Done</v-btn>
                            </v-card-actions>
                           
                        </v-card>
                    </template>
                    <!-- </v-stepper-content> -->

                    <template v-slot:actions>
                    </template>
                </v-stepper>
            </v-col>
            <v-col cols="12">
                <v-btn @click="viewdata_save=true; viewdata()" :disabled="viewdata_save" :loading="viewdata_save" color="success" style="float:right" >Guardar Cambios</v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import elmentos_checklist from "./CheckListComponent.json";
import ImagenViwerComponent from "./ImagenViewerComponent.vue";

import { mapState } from "vuex";

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
export default {
    components:{
        ImagenViwerComponent
    },

    props: {
        checklist_data:Object,
        detalles_os:Object,
        showAlert: Function,
        hasPermission: Function,
        appconf: Object
    },

    computed: {
        ...mapState("Session", ['session_key', 'user', 'permisos', 'loggedin']),
    },

    data() {
        return {
            paso: 1,
            
            lastStep: null,

            valid: false,
            stepForm: [],
            
            rules: [v => !!v || "Required."],

            steps: ['Step 1', 'Step 2', 'Step 3'],
            items_menu_steps: this.decodeCheckList(),

            recognition:null,
            isListening:false,
            estatus_reporte_unidad:null,
            viewdata_save:false
        }
    },
    created(){
        this.recognition = new SpeechRecognition();
        this.recognition.lang = "es-ES";
        this.recognition.continuous = true;
        
        this.lastStep = this.items_menu_steps.length + 1;

        // this.items_menu_steps = checklist_data ? checklist_data : elmentos_checklist
    },

    methods:{

        applyRules(comp){
            if (comp.id >= 16 && comp.id <= 18 
            || comp.id == 24 || comp.id == 27 
            || comp.id >= 30 && comp.id <= 32 
            || comp.id == 33 || comp.id == 53 
            ||  comp.id >= 55 && comp.id <= 57 
            || comp.id == 63 
            || comp.id >= 70 && comp.id <= 71 
            ||  comp.id >= 74 && comp.id <= 76 
            || comp.id == 81 
            || comp.id == 108 
            ) {
                return [];
            } else {
                return this.rules;
            }
        },
        stepComplete(step) {
            return this.paso > step
        },
        stepStatus(step) {
            return this.paso > step ? 'green' : 'blue'
        },
        validate(n) {
            this.items_menu_steps[n].valid = false
            let v = this.$refs.stepForm[n].validate()
            if (v) {
                this.items_menu_steps[n].valid = true
                // continue to next
                this.paso = n+2
            }
        },
        done() {
            this.paso = this.lastStep + 1
        },

        decodeCheckList(){
            try {
                return JSON.parse(atob(this.checklist_data.data_json));
            } catch (error) {
                console.error("Error decoding base64:", error);
                return elmentos_checklist;
            }
        },
        TranscribirAudio(item){

            this.items_menu_steps.forEach(step => {
                step.items.forEach(item => {
                    item.recording = false
                });
            });

          
           
            if(this.isListening){
                this.recognition.stop();
                this.isListening = false;
            }

            /*
            deberia recorrer todos los items y cancelarlos.
            */

            item.recording = typeof item.recording == 'undefined' || item.recording == false


                if(item.recording){
                    
                    this.recognition.start()
                    this.isListening = true;
                    
                }
                
            this.recognition.onresult = (event) => {
                let results = "";
                for (let i = 0; i < event.results.length; i++) {
                    const transcript = event.results[i][0].transcript;
                    results = results + transcript + "\n\r";
                  
                }

                item.observaciones = results;
                return item

            }
          
         


        },
        getDescItem(estatus){
            switch(estatus){
                case 3: return 'Reparar';
                case 4: return 'Requiere cambio';
                case 5: return 'Bajo de aceite';
                case 6: return 'Requiere cambio de aceite';
                case 7: return 'Requiere limpieza';
                case 8: return 'Requiere pintura';
                case 9: return 'Corrosión';
                default: return '';
            }
        },
        async viewdata(){


            //this.items_menu_steps
            // console.log(this.items_menu_steps);
            let items = this.items_menu_steps;

            // items.forEach(step => {
            //     step.items.forEach(item => {
            //         // console.log(item); //borramos files y images_views ... codificamos en base64 y mandamos al api con id_ot.
            //         delete item.files;
            //         delete item.images_views;
            //     });
            // });

            let encoded =  btoa(JSON.stringify(items) );

            let ot_items = [];

            let servicios = [...this.items_menu_steps];

            servicios.forEach(servicio => {
                servicio.items.forEach(item => {
                    let estatus = parseInt(item.selected_r);
                    if(estatus >= 3 && estatus < 10){
                        ot_items.push({
                            id_ot:this.detalles_os.id_ot,
                            prodserv:'servicio',
                            id_prodserv:1,
                            concepto:`${servicio.title} ${item.subtitle}`,
                            descripcion:this.getDescItem(estatus),
                            cantidad:1,
                            unidad:'Servicio',
                            estatus:1
                        });
                    }
                })
            });

            let checklist = [...this.items_menu_steps];

            /* CHECK LIST POLIPASTO */ 
            let checklist_polipasto = checklist.find(c => c.title == 'POLIPASTO');

            let paro_total_polipasto = ['FRENO DE MOTOR', 'MOTOR', 'CADENA', 'ESTADO VARIADOR']; //aqui falto: balatas, botonera/r.c
            let paro_total_polipasto_values = checklist_polipasto.items.filter(i =>  paro_total_polipasto.includes(i.subtitle));

            let paro_parcial_polipasto = ['GUIAS Y RODAMIENTOS', 'NUEZ DE CARGA', 'GUARDAS', 'SWITCH LIMITE', 'PUNTOS DE APOYO', 'GANCHO DE CARGA', 'APERTURA DE GARGANTA', 'SEGURO DE GANCHO', 'CARCAZA DE GANCHO (APAREJO DE CARGA)', 'LUBRICACIÓN DE', 'ACEITE NIVEL', 'CHUMACERAS', 'GUIA DE CADENA']; // falto: limitador de carga...
            let paro_parcial_polipasto_values = checklist_polipasto.items.filter(i =>  paro_parcial_polipasto.includes(i.subtitle));

            /* CHECK LIST TROLLEY */ 
            let checklist_trolley = checklist.find(c => c.title == 'TROLE');

            let paro_total_trolley= ['ESTADO DE TRANSMISIÓN', 'ESTADO FRENO', 'VARIADOR'];
            let paro_total_trolley_values = checklist_trolley.items.filter(i =>  paro_total_trolley.includes(i.subtitle));
            
            let paro_parcial_trolley = ['BALATAS', 'GUIAS', 'BALEROS', 'CONDICIONES TUCERCAS', 'CEJAS', 'ESTADO DE RUEDAS']; // falto: NIVEL DE ACEITE, LIMIT SWITCH.. 
            let paro_parcial_trolley_values = checklist_trolley.items.filter(i =>  paro_parcial_trolley.includes(i.subtitle));


            /* check list puente */

            let checklist_puente = checklist.find(c => c.title == 'PUENTE');


            let paro_total_puente= ['CONDICIONES DE MOTOR REDUCT', 'CONDICIONES DE FRENO'];
            let paro_total_puente_values = checklist_puente.items.filter(i =>  paro_total_puente.includes(i.subtitle));
            
            let paro_parcial_puente = ['ESTADO DE RUEDAS DE CABEZALES', 'CHUMACERAS']; // falto:  NIVEL DE ACEITE
            let paro_parcial_puente_values = checklist_puente.items.filter(i =>  paro_parcial_puente.includes(i.subtitle));


            /* check list alimentacion */ 
            let checklist_alimentacion = checklist.find(c => c.title == 'SISTEMA DE ALIMENTACIÓN');
            
            let paro_total_alimentacion= ['FESTON CALIBRE', 'CONDICIONES DE CABLE FESTOON', 'DUCTO BARRA TIPO', 'BRAZOS TOMA CORRIENTES']; // falto: tablero motoreductores
            let paro_total_alimentacion_values = checklist_alimentacion.items.filter(i =>  paro_total_alimentacion.includes(i.subtitle));
            
            let paro_parcial_alimentacion = ['TAPAS FINALES', 'DESGASTE', 'UNICIONES']; // falto: tornilleria, carretillas, oxidacion, conectores
            let paro_parcial_alimentacion_values = checklist_alimentacion.items.filter(i =>  paro_parcial_alimentacion.includes(i.subtitle));


            /* paros totales y parciales */

            let paro_total_componentes = [...paro_total_polipasto_values,
                ...paro_total_trolley_values,
                ...paro_total_puente_values,
                ...paro_total_alimentacion_values
            ];

            let paro_parcial_componentes = [
                ...paro_parcial_polipasto_values,
                ...paro_parcial_trolley_values,
                ...paro_parcial_puente_values,
                ...paro_parcial_alimentacion_values
            ];

            // let estatus_reporte_unidad = null;
            this.estatus_reporte_unidad = null;
            await this.procesarParoTotal(paro_total_componentes);
            await this.procesarParoParcial(paro_parcial_componentes);

            await this.$axios.post('guardar.checklist.orden.servicio', {
                id_ot:this.detalles_os.id_ot,
                data_json:encoded,
                ot_items:ot_items,
                id_usr:this.user.id_usr,
                estatus_reporte_unidad:this.estatus_reporte_unidad
            })
            .then(r => { 
                window.showAlert({
                    activo: true,
                    texto: "Checklist Actualizado con éxito!",
                    color: "success",
                    vertical: true
                });
                this.$emit('update', this.estatus_reporte_unidad);
                this.$emit('checklist', {data_json:encoded});
            }).catch(e=>{
                window.showAlert({
                    activo: true,
                    texto: "Lo sentimos! Algo salio mal...",
                    color: "error",
                    vertical: true
                });
            }).finally( () => {
                this.viewdata_save=false
            });






            //... get imagenes?? 
        },
        async procesarParoTotal(paro_total_componentes){
            for (let i = 0; i < paro_total_componentes.length; i++) {
                let item = paro_total_componentes[i];
                if (typeof item.selected_r !== 'undefined' && parseInt(item.selected_r) >= 3 && parseInt(item.selected_r) < 5) {
                    this.estatus_reporte_unidad = "30"; // red... paro total;
                    break; // Detener el bucle
                }
            }
        },
        async procesarParoParcial(paro_parcial_componentes){
            for (let j = 0; j < paro_parcial_componentes.length; j++) {
                let item = paro_parcial_componentes[j];
                if (typeof item.selected_r !== 'undefined' && parseInt(item.selected_r) >= 3 && parseInt(item.selected_r) < 5 && this.estatus_reporte_unidad == null) {
                    this.estatus_reporte_unidad = "50"; // yellow... paro total;
                    break; // Detener el bucle
                }
            }
        },
        adjuntarImagen(item){
            console.log(item)
            
            if(item.imagenes_complement){
                item.imagenes_complement.push()
            }
       

        },

        OrdenarData(){

          let semi_bd =[
            {
                id:3,
                value_subcampo_1:"TEST",
                value_subcampo_2:"TEST 2",
                value_subcampo_3:"TEST 3",
                selected_r:4,
                observaciones:"EJEMPLO DE OBSERVACIONES"
            }
          ];

          
            //  this.step.items


         let elementos =   this.items_menu_steps;

       /*   elementos.forEach(e=>{
            e.
         }); */

         
        }
    }
}
</script>
