<template>
    <div ref="qrCodeRef"></div>
  </template>
  
  <script>
  import QRCodeStyling from 'qr-code-styling';
  
  export default {
    name: 'QRCodeVue3',
    props: {
      value: {
        type: String,
        required: true,
      },
      width: {
        type: Number,
        default: 200,
      },
      height: {
        type: Number,
        default: 200,
      },
      image: {
        type: String,
        default: '',
      },
      dotsColor: {
        type: String,
        default: '#000000',
      },
      backgroundColor: {
        type: String,
        default: '#ffffff',
      },
    },
    data() {
      return {
        qrCode: null,
      };
    },
    mounted() {
      this.qrCode = new QRCodeStyling({
        width: this.width,
        height: this.height,
        data: this.value,
        image: this.image,
        dotsOptions: {
          color: this.dotsColor,
          type: 'rounded',
        },
        backgroundOptions: {
          color: this.backgroundColor,
        },
        imageOptions: {
          crossOrigin: 'anonymous',
          margin: 5,
          imageSize: 0.4,
        },
      });
      this.qrCode.append(this.$refs.qrCodeRef);
    },
    watch: {
      value(newValue) {
        if (this.qrCode) {
          this.qrCode.update({
            data: newValue,
          });
        }
      },
    },
  };
  </script>
  
  <style scoped>
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>
  