import { createApp, ref } from 'vue'

import App from './App.vue'
import VueCordova from 'vue-cordova'
import './registerServiceWorker'

import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

import router from "./router";
import store from './store'
import axios from 'axios';

import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css';
// import vueInstance from './plugins/vueInstance'

import { loadFonts } from './plugins/webfontloader'

// import ScreenSplash from './views/Auth/ScreenSplash.vue';

loadFonts()

/* 'https://demo-api.phpsoluciones.net/public/api/'; */

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://carvajalgruasviajeras.com/public/api/' : 'https://carvajalgruasviajeras.com/public/api/';
const app = createApp(App);

// app.component('ScreenSplash', ScreenSplash);
app.config.globalProperties.$axios = axios
app.config.globalProperties.$refs = ref

const currentURL = window.location.href;
// const uploadURL = new URL('uploads.php', currentURL).toString();
//uploadsURL
app.config.globalProperties.$baseURL = process.env.NODE_ENV === 'production' ? currentURL : "https://carvajalgruasviajeras.com/";//process.env.NODE_ENV === 'production' ? currentURL : "https://carvajal-app-production.cloudevserv.com/";

app.config.globalProperties.$uploadsURL = new URL('uploads.php', app.config.globalProperties.$baseURL).toString();

// app.config.globalProperties.$url_backend = "https://carvajal-api.phpsoluciones.net/";

app.use(VueCordova);
app.use(router);
app.use(store);
app.use(vuetify);
app.use(VCalendar, {});

const appInstance = app.mount('#app');


router.beforeEach((to, from, next) => {
    if(to.meta.permission == null || store.state.Session.permisos.has(to.meta.permission) || store.state.Session.user.super_admin == 1){
        next();
    }else {
        next({ name: 'HomeComponent' });
    }
});

router.afterEach(() => {
    if (appInstance && typeof appInstance.cleanstore === 'function') {
        appInstance.RouterParams(null);
    }
});
