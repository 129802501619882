<template>
    <div class="card_contenedor">
        <div class="center_div">
            <span class="title_card_2">FRECUENCIA DE OPERACION</span>
        </div>
        <v-sheet class="pa-5">
            <v-row no-gutters>
                <v-col cols="12"> 
                    <v-text-field 
                        :rules="rules" 
                        :readonly="item_lev.estatus_cot != null" 
                        density="compact" variant="outlined"
                        label="Ciclos por hora" 
                        v-model="item_lev.ciclos_hora"
                    ></v-text-field>

                    <v-text-field 
                        :rules="rules" 
                        :readonly="item_lev.estatus_cot != null" 
                        density="compact" variant="outlined"
                        label="Duración del ciclo más largo" 
                        v-model="item_lev.duracion_ciclo" 
                        class="mt-2"
                    ></v-text-field>

                    <v-text-field 
                        :rules="rules" 
                        :readonly="item_lev.estatus_cot != null" 
                        density="compact" variant="outlined"
                        label="Frecuencia de uso" 
                        v-model="item_lev.frecuencia_uso" 
                        class="mt-2"
                    ></v-text-field>

                    <!-- <v-select 
                        v-model="item_lev.tornilleria_trabe_puente" 
                        label="Tornilleria de trabe y puente"
                        :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                        :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    ></v-select> -->

                </v-col>
            </v-row>
        </v-sheet>
    </div>

    <div class="card_contenedor">
        <div class="center_div">
            <span class="title_card_2">CARACTERISTICA DE LA PIEZA</span>
        </div>
        <v-sheet class="pa-5">
            <v-row no-gutters>
                <v-col cols="3">
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_humedad"
                        label="Humedad" value="1"
                    ></v-checkbox>
                </v-col>
                <v-col cols="3">
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_pintada"
                        label="Pintada" value="1"
                    ></v-checkbox>
                </v-col>
                <v-col cols="3">
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_cristal"
                        label="Cristal" value="1"
                    ></v-checkbox>
                </v-col>

                <v-col cols="3">
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_uretano"
                        label="Uretano" value="1"
                    ></v-checkbox>
                </v-col>

                <v-col cols="3">
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_polvo"
                        label="Con polvo" value="1"
                    ></v-checkbox>
                </v-col>

                <v-col cols="3">
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_caliente"
                        label="Caliente" value="1"
                    ></v-checkbox>
                </v-col>

                <v-col cols="3">
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_ceramica"
                        label="Carámica" value="1"
                    ></v-checkbox>
                </v-col>

                <v-col cols="3">
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_madera"
                        label="Madera" value="1"
                    ></v-checkbox>
                </v-col>

                <v-col cols="3">
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_seca"
                        label="Seca" value="1"
                    ></v-checkbox>
                </v-col>

                <v-col cols="3">
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_metalica"
                        label="Metálica" value="1"
                    ></v-checkbox>
                </v-col>

                <v-col cols="3">
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_plastica"
                        label="Plástica" value="1"
                    ></v-checkbox>
                </v-col>

                <v-col cols="3">
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_aceite"
                        label="Con aceite" value="1"
                    ></v-checkbox>
                </v-col>

                <v-col cols="12">
                    <v-text-field 
                        :rules="rules" 
                        :readonly="item_lev.estatus_cot != null" 
                        density="compact" variant="outlined"
                        label="Temperatura de la pieza °C" 
                        v-model="item_lev.mnp_pieza_temperatura"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-sheet>
    </div>

    <div class="card_contenedor">
        <div class="center_div">
            <span class="title_card_2">GEOMETRÍA DE LA PIEZA</span>
        </div>
        <v-sheet class="pa-5">
            <v-row no-gutters>
                <v-col cols="6"> 
                    <v-img src="img/mnp_geometria.png"></v-img>
                </v-col>
                <v-col cols="6">
                    <v-table density="compact">
                        <thead>
                            <tr>
                                <th class="text-left">MOD.</th>
                                <th class="text-left">Peso (kg)</th>
                                <th class="text-left">Largo (mm) A</th>
                                <th class="text-left">Ancho (mm) B</th>
                                <th class="text-left">Espesor (mm) C</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_peso_1"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_largo_1"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_ancho_1"
                                    ></v-text-field>
                                </td>

                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_espesor_1"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_peso_2"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_largo_2"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_ancho_2"
                                    ></v-text-field>
                                </td>

                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_espesor_2"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_peso_3"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_largo_3"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_ancho_3"
                                    ></v-text-field>
                                </td>

                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_espesor_3"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_peso_4"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_largo_4"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_ancho_4"
                                    ></v-text-field>
                                </td>

                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_espesor_4"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_peso_5"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_largo_5"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_ancho_5"
                                    ></v-text-field>
                                </td>

                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_espesor_5"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_peso_6"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_largo_6"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_ancho_6"
                                    ></v-text-field>
                                </td>

                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_espesor_6"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_peso_7"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_largo_7"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_ancho_7"
                                    ></v-text-field>
                                </td>

                                <td>
                                    <v-text-field 
                                        :rules="rules" 
                                        :readonly="item_lev.estatus_cot != null" 
                                        density="compact" variant="outlined"
                                        v-model="item_lev.mnp_pieza_espesor_7"
                                    ></v-text-field>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                    <strong>Nota:</strong> En caso de no ser geometría estándar anexar planos y especificaciones.
                </v-col>
            </v-row>
        </v-sheet>
    </div>

    <div class="card_contenedor">
        <div class="center_div">
            <span class="title_card_2">DESCRIPCIÓN DE LA OPERACIÓN</span>
        </div>
        <v-sheet class="pa-5">
            <v-row no-gutters>
                <v-col cols="12"> 
                    <v-textarea :readonly="item_lev.estatus_cot != null" label="(Ej.: la pieza se toma horizontalmente de un rack de abasto y se traslada a un centro de maquinado, la pieza se entrega en posición vertical)."
                    variant="outlined" v-model="item_lev.mnp_pieza_operacion"></v-textarea> 
                </v-col>

                <v-col cols="6">
                    <v-sheet class="pa-4">
                            <div class="center_element" style="margin: 10px;">
                                <h2>Alimentación Neumática</h2>
                            </div>

                            <div class="center_element">
                                <v-img :src="require('@/assets/ImgFormatos/formato1_maq1.png')" width="200px" height="100px" />
                                <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.alimentacion_neumatica"
                                    label="" value="1"></v-checkbox>
                            </div>
                            <br />
                            <div class="center_element">
                                <strong>Nota:</strong> Presión de trabajo 0.6 Mpa
                            </div>
                        </v-sheet>
                </v-col>
                <v-col cols="6">
                    <v-sheet class="pa-2">
                        <div class="center_element" style="margin: 10px;">
                            <h2>Alimentación Eléctrica</h2>
                        </div>
                        <div class="center_element">
                            <div>
                                <v-img :src="require('@/assets/ImgFormatos/formato1_maq_2.png')" width="200px"
                                    height="100px" />
                            </div>
                            <div>
                                <v-checkbox :readonly="item_lev.estatus_cot != null"
                                    v-model="item_lev.alimentacion_electrica" label="480 v" value="480v"></v-checkbox>
                                <v-checkbox :readonly="item_lev.estatus_cot != null"
                                    v-model="item_lev.alimentacion_electrica" label="220 v" value="220v"></v-checkbox>
                                <v-checkbox :readonly="item_lev.estatus_cot != null"
                                    v-model="item_lev.alimentacion_electrica" label="110 v" value="110v"></v-checkbox>
                            </div>
                        </div>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-sheet>
    </div>

    <div class="card_contenedor">
        <div class="center_div">
            <span class="title_card_2">Tipo de Ambiente</span>
        </div>
        <v-sheet class="pa-5">
            <v-row no-gutters>
                <v-col> 
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_tipo_ambiente"
                        label="Corrosivo" value="corrosivo"
                    ></v-checkbox>
                </v-col>

                <v-col> 
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_tipo_ambiente"
                        label="Exterior" value="exterior"
                    ></v-checkbox>
                </v-col>

                <v-col> 
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_tipo_ambiente"
                        label="Alta temperatura" value="alta_temperatura"
                    ></v-checkbox>
                </v-col>

                <v-col> 
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_tipo_ambiente"
                        label="Húmedo" value="humedo"
                    ></v-checkbox>
                </v-col>

                <v-col> 
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_tipo_ambiente"
                        label="Grado alimenticio" value="grado_alimenticio"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </v-sheet>
    </div>

    <div class="card_contenedor">
        <div class="center_div">
            <span class="title_card_2">DIMENSIONAMIENTO</span>
        </div>
        <v-sheet class="pa-5">
            <v-row no-gutters>
                <v-col cols="6">
                    <strong>A = </strong> Altura Toma (mm)<br>
                    <strong>B = </strong> Altura Entrega (mm)<br>
                    <strong>C = </strong> Paño de Riel a Piso (mm)<br>
                </v-col>
                <v-col cols="6" style="text-align:right">
                    <strong>D = </strong> Altura Min. Equipo (mm)<br>
                    <strong>E = </strong> Altura Max. Equipo (mm)<br>
                </v-col>
                <v-col cols="12" class="mb-3"> 
                    <v-img src="img/dimensionamiento.png"></v-img>
                </v-col>
                <v-col cols="6" class="pa-5">

                    <v-text-field 
                        :rules="rules" 
                        :readonly="item_lev.estatus_cot != null" 
                        density="compact" variant="outlined"
                        label="Altura Toma (mm) A" 
                        v-model="item_lev.mnp_pieza_dim_a"
                    ></v-text-field>

                    <v-text-field 
                        :rules="rules" 
                        :readonly="item_lev.estatus_cot != null" 
                        density="compact" variant="outlined"
                        label="Altura Entrega (mm) B" 
                        v-model="item_lev.mnp_pieza_dim_b"
                    ></v-text-field>

                    <v-text-field 
                        :rules="rules" 
                        :readonly="item_lev.estatus_cot != null" 
                        density="compact" variant="outlined"
                        label="Paño de Riel a Piso (mm) C" 
                        v-model="item_lev.mnp_pieza_dim_c"
                    ></v-text-field>

                </v-col>

                <v-col cols="6" class="pa-5">
                    <v-text-field 
                        :rules="rules" 
                        :readonly="item_lev.estatus_cot != null" 
                        density="compact" variant="outlined"
                        label="Altura Min. Equipo (mm) D" 
                        v-model="item_lev.mnp_pieza_dim_d"
                    ></v-text-field>

                    <v-text-field 
                        :rules="rules" 
                        :readonly="item_lev.estatus_cot != null" 
                        density="compact" variant="outlined"
                        label="Altura Max. Equipo (mm) E" 
                        v-model="item_lev.mnp_pieza_dim_e"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-sheet>
    </div>

    <div class="card_contenedor">
        <div class="center_div">
            <span class="title_card_2">POSICIÓN PIEZAS</span>
        </div>
        <v-sheet class="pa-5">
            <v-row no-gutters>
                <v-col cols="4"> 
                    <v-img src="img/posicionamiento.png" width="300" ></v-img>
                </v-col>
                <v-col cols="8">
                    <v-table density="compact">
                        <thead>
                            <tr>
                                <th class="text-left">Posición pieza - Toma</th>
                                <th class="text-left">Posición pieza - Entrega</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <v-row>
                                        <v-col>
                                            <v-img src="img/vertical.png" width="80"></v-img>
                                        </v-col>
                                        <v-col>
                                            <v-checkbox 
                                                :readonly="item_lev.estatus_cot != null" 
                                                v-model="item_lev.mnp_pieza_posicion_toma"
                                                label="Vertical" value="vertical"
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    
                                    
                                </td>
                                <td>
                                    <v-row>
                                        <v-col>
                                            <v-img src="img/horizontal.png" width="80"></v-img>
                                        </v-col>
                                        <v-col>
                                            <v-checkbox 
                                                :readonly="item_lev.estatus_cot != null" 
                                                v-model="item_lev.mnp_pieza_posicion_entrega"
                                                label="Horizontal" value="horizontal"
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <v-row>
                                        <v-col>
                                            <v-img src="img/horizontal.png" width="80"></v-img>
                                        </v-col>
                                        <v-col>
                                            <v-checkbox 
                                                :readonly="item_lev.estatus_cot != null" 
                                                v-model="item_lev.mnp_pieza_posicion_toma"
                                                label="Horizontal" value="horizontal"
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </td>
                                <td>
                                    <v-row>
                                        <v-col>
                                            <v-img src="img/vertical.png" width="80"></v-img>
                                        </v-col>
                                        <v-col>
                                            <v-checkbox 
                                                :readonly="item_lev.estatus_cot != null" 
                                                v-model="item_lev.mnp_pieza_posicion_entrega"
                                                label="Vertical" value="vertical"
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    
                                    
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <v-row>
                                        <v-col>
                                            <v-img src="img/angulo_toma.png" width="80"></v-img>
                                        </v-col>
                                        <v-col>
                                            <v-text-field 
                                                :rules="rules" 
                                                :readonly="item_lev.estatus_cot != null" 
                                                density="compact" variant="outlined"
                                                label="Ángulo (°)" 
                                                v-model="item_lev.mnp_pieza_angulo_toma"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </td>
                                <td>
                                    <v-row>
                                        <v-col>
                                            <v-img src="img/angulo_entrega.png" width="80"></v-img>
                                        </v-col>
                                        <v-col>
                                            <v-text-field 
                                                :rules="rules" 
                                                :readonly="item_lev.estatus_cot != null" 
                                                density="compact" variant="outlined"
                                                label="Ángulo (°)" 
                                                v-model="item_lev.mnp_pieza_angulo_entrega"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-col>

                <v-col cols="8">
                    <v-text-field 
                        :rules="rules" 
                        :readonly="item_lev.estatus_cot != null" 
                        density="compact" variant="outlined"
                        label="Diseñador" 
                        v-model="item_lev.mnp_pieza_disenador"
                    ></v-text-field>

                    <v-text-field 
                        :rules="rules" 
                        :readonly="item_lev.estatus_cot != null" 
                        density="compact" variant="outlined"
                        label="Asesor" 
                        v-model="item_lev.mnp_pieza_asesor"
                    ></v-text-field>
                </v-col>
                <v-col cols="4" class="pl-5">
                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_dificultad"
                        label="Complejo" value="Complejo"
                    ></v-checkbox>

                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_dificultad"
                        label="Especial" value="Especial"
                    ></v-checkbox>

                    <v-checkbox 
                        :readonly="item_lev.estatus_cot != null" 
                        v-model="item_lev.mnp_pieza_dificultad"
                        label="Básico" value="Basico"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </v-sheet>
    </div>

</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("Session", ['session_key', 'session_data', 'loggedin']),
    },
    props: {
        is_new: Boolean,
        restore_lev: Object,
        visita: Object,
        id_tipo_unidad: String,
        cargarLevantamientos: Function,
        data_unidad_nombre: Object,
    },
    data() {
        return {
            
            rules: [
                (value) => {
                    if (value) return true;

                    return "Este campo es obligatorio";
                },
            ],
            
        }
    },
    watch: {

    },
    computed: {
        item_lev(){
          return !this.is_new ? this.restore_lev : {};  
        }
    },
    created() {
    },



    methods: {

    }
}
</script>

<style scoped>
.card_contenedor {

    margin-top: 20px;
    border: solid 2px #000;
    width: 100%;
    border-radius: 5px;
}

.title_card {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    letter-spacing: 2px;
    background-color: #FFD600;
}


.center_div {

    width: 100%;
    top: -25px;
    display: flex;
    justify-content: center;
}

.center_element {
    display: flex;
    justify-content: center
}


.color_letter {
    color: #000000;
    padding: 4px;
    background-color: #FFD600;
}

.color_spacer {
    color: #000000;
    padding: 4px;

}

.space_container {
    margin-top: -20px;
}

.mg_tn {
    margin-top: -30px;
}

.nota_contenedor {
    padding: 10px;
    border: solid 2px #000000;
    font-size: 18px;
    color: #5F5F5F;
    font-weight: bold;
}

.title_card_2 {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    letter-spacing: 2px;
    background-color: #0D3961;
    color: #fff
}

.imagen-opaca {
  opacity: 0.2; 
}
</style>