<template>
  <div>
    <v-row class="pa-1 mb-5">
      <v-col cols="12" sm="2" lg="" class="py-0 my-0">
        <v-text-field v-model="search" append-icon="search" label="Buscar visita" single-line hide-details filled
          dense></v-text-field>
      </v-col>

      <v-col cols="12" sm="2" lg="" class="py-0 my-0">
        <v-select v-model="estatus" :items="Estatus" item-title="nombre" item-value="id" dense hide-details
          placeholder="Estatus " return-object outlined append-icon="mdi-circle-slice-5"></v-select>
      </v-col>
      <v-col cols="12" sm="2" lg="" class="py-0 my-0">
        <DetePikerInput @ObtenerFechaDP="fecha1_change" :label_dp="'Fecha desde'" :fecha_default="fecha1" />
      </v-col>
      <v-col cols="12" sm="2" lg="" class="py-0 my-0">
        <DetePikerInput @ObtenerFechaDP="fecha2_change" :label_dp="'Fecha hasta'" :fecha_default="fecha2" />
      </v-col>

      <!-- FALTA AGREGAR EL RANGO DE FECHAS. Y EXPORTACIONES ?  -->

      <v-col cols="12" sm="4" lg="" class="py-0 my-0  text-right">

        <!-- <v-btn  class="ma-1" dark color="green" @click="ImprimirExcel()"> <v-icon >mdi-microsoft-excel </v-icon> </v-btn> -->
        <!-- <v-btn  dark color="green" @click="generarExcel('solicitudes')"> <v-icon >mdi-microsoft-excel </v-icon> </v-btn> -->
        <v-btn class=" ma-1" color="success" dark v-if="hasPermission('agregar_visita')" @click="modal = true">Agendar
          visita </v-btn>
        <v-btn class=" ma-1" color="grey" text dark @click="consulta()"><v-icon>mdi-refresh</v-icon> </v-btn>
      </v-col>
    </v-row>
    <br />

    <div class="text-center section">
      <v-calendar class="custom-calendar max-w-full" :masks="masks" disable-page-swipe is-expanded>
        <template v-slot:day-content="{ day }">
          <!--   -->
          <div class="flex flex-col h-full z-10 overflow-hidden">
            <span class="day-label text-sm text-grey" @click="obtenerDataDia(dayAttributes(day), day)">{{ day.day }}</span>
            <div class="flex-grow overflow-y-auto overflow-x-auto">
              <!-- :class="attr.customData.class" -->
              <v-chip v-for="attr in attributes.filter(at => getDate(at.date) === getDate(day.date))" :key="attr.key" :color="attr.customData.tipo_visita == 'Levantamiento' ? 'primary' : 'warning'" class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1" @click="infoVisita(attr,day)">
                {{ attr.customData.title }}
              </v-chip>
              <!-- <p v-for="attr in dayAttributes(day)" :key="attr.key" class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                :class="attr.customData.class">
                {{ attr.customData.title }}
              </p> -->
            </div>
          </div>
        </template>
      </v-calendar>

      <!-- <v-calendar class="custom-calendar max-w-full" :masks="masks" :attributes="attributes" disable-page-swipe
        is-expanded>
        <template v-slot:day-content="{ day, attributes }">
          <div  class="flex flex-col h-full z-10 overflow-hidden" @click="obtenerDataDia(attributes, day)">
            <span class="day-label text-sm text-grey">{{ day.day }}</span>
            <div class="flex-grow overflow-y-auto overflow-x-auto">
              <p v-for="attr in attributes" :key="attr.key" class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                :class="attr.customData.class">
                {{ attr.customData.title }}
              </p>

            </div>
          </div>
        </template>
      </v-calendar> -->
    </div>

    <v-dialog v-model="modal_activitys" max-width="700">
      <v-card class="pa-2">
        <br />
        <h2>Eventos: {{ selecion_dia.ariaLabel }}</h2>
        <br />
        <center><p v-if="selection_att.length==0" style="color:#cccccc">Sin eventos programados</p></center>

        <v-expansion-panels v-if="selection_att.length>0">
          <v-expansion-panel v-for="i in selection_att" :key="i" :class="i.class">

            <v-expansion-panel-title expand-icon="mdi-menu-down">
              <h5 style="color:#000000;">(12:00:00 pm)</h5> &nbsp; &nbsp;
              {{ i.title }}
            </v-expansion-panel-title>

            <!-- <p class="text_m">Fecha de detalle :</p> -->

           
            <v-expansion-panel-text>
              <p class="text_m">Hora :</p>
              <p class="text_m">Evento :</p>
              {{ i.title }}
              <p class="text_m">Comentarios :</p>
              
            
              </v-expansion-panel-text>

          </v-expansion-panel>
          <br />

        </v-expansion-panels>

        <!--    {{ selection_att  }} -->
        <br />
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="modal" width="700px" >	
      <v-card class="pa-5">
        <Modal :showAlert="showAlert" :hasPermission="hasPermission" :consulta="consulta" @modal="modal = $event"/>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import DetePikerInput from "@/components/DatePikerInput.vue";
import moment from 'moment';
import Modal from './VisitasModal.vue';
moment.locale('es-ES');
export default {
  props: {
    showAlert: Function,
    hasPermission: Function
  },

  components: {
    DetePikerInput,
    Modal
  },
  data() {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    return {
      modal_activitys: false,
      selection_att: [],
      selecion_dia: {},
      modal:false,
      masks: {
        weekdays: 'WWW',
      },
      attributes: [],
    };
  },
  created(){
    this.consulta(); 
  },
  mounted(){
    
   },
  methods: {
    consulta() {
      this.attributes=[];

        this.$axios.get("consulta.agenda.visitas")
        .then(r=>{
          r.data.forEach(e=>{
            let color = this.ObtenerColorEvento();
            this.attributes.push({

              key: e.id_visita,
              customData: {
                ...e,
                title: e.tipo_visita,
                class: "bg-"+color+" text-white",
              },
              date: e.fecha_visita_programada
            });
           

           
          })

        })
        .catch(e=>{console.log(e)});

    },

    getDate(date){
      return moment(date).format('YYYY-MM-DD');
    },

    getDataDay(day){
      console.log(day);
    },
    ObtenerColorEvento(){
      let colores = ["deep-purple", "blue", "cyan", "teal", "amber", "pink"];
      let indiceAleatorio = Math.floor(Math.random() * colores.length);
      return colores[indiceAleatorio];
    },

    infoVisita(attr,day){
      this.$router.push({
        path:`/visitas/${attr.customData.id_visita}`
      });
    },

    dayAttributes(day) {
      // Filtra los atributos para que solo muestre los eventos correspondientes al día actual
      return this.attributes.filter(attr => {
        return moment(day.date).isSame(attr.dates, 'day');
      });
    },


    obtenerDataDia(items, dia) {
      this.selection_att = [];
      this.selecion_dia = {
        ariaLabel: dia.ariaLabel,
        date: dia.date,
        id: dia.id
      }
      items.forEach(e => {
        this.selection_att.push(e.customData)
      });
      this.modal_activitys = true;
    }

  }
};
</script>

<style >
.text_m{
  font-weight: bold;
  color:#ffffff;

}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

.custom-calendar.vc-container {
  --day-border: 2px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 110px;
  --weekday-bg: #f8fafc;
  --weekday-border: 2px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  padding:10px;
 
}

.vc-container .vc-header {
  background-color: #f1f5f8;
  padding: 40px 50px;
  margin-bottom: 10px;
}

.vc-header{
  opacity: 1;
 
}
button.vc-title span {
    color: rgb(0, 0, 0);
    font-weight: bold;
    z-index: 20;
    font-size: 18px;
    text-transform: uppercase;
  }

.custom-calendar.vc-container .vc-weeks {
  padding: 0;
}

.custom-calendar.vc-container .vc-weekday {
  background-color: var(--weekday-bg);
  border-bottom: var(--weekday-border);
  border-top: var(--weekday-border);
  padding: 5px 0;
}

.custom-calendar.vc-container .vc-day {
  padding: 5px 5px 3px 5px;
  text-align: left;
  height: var(--day-height);
  min-width: var(--day-width);
  background-color: white;
}

.custom-calendar.vc-container .vc-day.weekday-1,
.custom-calendar.vc-container .vc-day.weekday-7 {
  background-color: #eff8ff;
}

.custom-calendar.vc-container .vc-day:not(.on-bottom) {
  border-bottom: var(--day-border);
}



.custom-calendar.vc-container .vc-day:not(.on-right) {
  border-right: var(--day-border);
}

.custom-calendar.vc-container .vc-day-dots {
  margin-bottom: 10px;
}
</style>