<template>
  <div> 
    <v-expansion-panels v-model="panels" multiple>
      <v-expansion-panel>
        <v-expansion-panel-title style="background-color:rgb(255, 160, 0) !important; color:white; font-weight:bold; font-size:18px" expand-icon="mdi-plus" collapse-icon="mdi-minus">Nuevo Levantamiento</v-expansion-panel-title>
        <v-expansion-panel-text>
                 
          <v-select 
              v-model="unidad_select" 
              label="Tipo unidad" 
              :item-props="itemProps"
              :items="items_tipo_unidades" 
              return-object
          >
          </v-select>
          <div v-if="unidad_select != null"> <!-- v-if="loadedComponent" -->
              
              <FormatoGlobal 
                :is_new="true" 
                :restore_lev="{}" 
                :visita="visita" 
                :id_tipo_unidad="unidad_select.id_tipo_unidad" 
                :component_name="unidad_select.component_name" 
                :data_unidad_nombre="unidad_select.tipo_unidad_nombre" 
                :cargarLevantamientos="cargarLevantamientos"
                :creator="creator"
                :showAlert="showAlert"
                :hasPermission="hasPermission"
              />
              <!-- <component :is="loadedComponent" :is_new="true" :restore_lev="{}" :visita="visita" :id_tipo_unidad="unidad_select.id_tipo_unidad" :data_unidad_nombre="unidad_select.tipo_unidad_nombre" :cargarLevantamientos="cargarLevantamientos"></component> -->
          </div>
          <!-- carga.... -->
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title style="background-color:rgb(255, 160, 0) !important; color:white; font-weight:bold; font-size:18px">Levantamientos registrados</v-expansion-panel-title>
        <v-expansion-panel-text>

          <center v-if="loading">
            <h1>
              <v-icon>mdi-spin mdi-loading</v-icon>
            </h1>
          </center>
          <v-card v-else-if="levantamientos.length > 0" elevation="0" class="col-lg-12 mt-5">
            <v-card-actions>
                <v-text-field
                    v-model="search_producto_borrador"
                    append-icon="mdi-magnify"
                    label="Buscar levantamiento"
                    single-line
                    hide-details
                    outlined
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn
                style="float: right"
                color="blue darken-4"
                small
                fab
                class="white--text"
                @click="cargarLevantamientos()"
                ><v-icon> mdi-reload</v-icon>
                </v-btn>
            </v-card-actions>
                      

            <v-card-body>

                <v-expansion-panels multiple style="border: solid 2px  rgb(255, 160, 0); margin-top: 10px">
                    <v-expansion-panel v-for="(element, index) in levantamientos">

                      

                        <v-expansion-panel-title :disable-icon-rotate="element.estatus_cot !== null">
                            No. Levantamiento {{element.id_levantamiento }} | {{ element.tipo_unidad_nombre }}
                        <!-- {{ element.tipo_unidad_descripcion }} -->

                        <!-- estatus_cot -->

                        <template v-slot:actions v-if="element.estatus_cot !== null">
                            <!-- color:teal|icon: mdi-check : cotizacion autorizada? 
                            mdi-alert-circle : cotizacion rechazada?-->
                            <v-icon color="error" icon="mdi-check">
                            </v-icon>
                        </template>

                        <template v-else v-slot:actions="{ expanded }">
                            <v-icon :color="!expanded ? 'warning' : ''" :icon="expanded ? 'mdi-pencil' : 'mdi-alert-circle'"></v-icon>
                        </template> 

                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <FormatoGlobal 
                              :is_new="false" 
                              :restore_lev="element" 
                              :visita="visita" 
                              :id_tipo_unidad="element.id_tipo_unidad" 
                              :component_name="element.component_name" 
                              :data_unidad_nombre="element.tipo_unidad_nombre" 
                              :cargarLevantamientos="cargarLevantamientos"
                              :creator="creator"
                              :showAlert="showAlert"
                              :hasPermission="hasPermission"
                            />
                            <!-- <Levantamiento :levantamiento="element" :visita="visita" :cargarLevantamientos="cargarLevantamientos"/> -->
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                </v-expansion-panels>
            </v-card-body>
                  <v-card-actions v-if="levantamientos.filter(l => l.estatus_cot == null).length > 0">
                      <v-spacer></v-spacer>
                      <!-- <v-btn
                          variant="text"
                          color="secondary"
                      >
                          Cancel
                      </v-btn> -->
                      <v-btn
                          variant="text"
                          color="green"
                          :disabled="requesting"
                          :loading="requesting"
                          @click="solicitarCotizacion()"
                      >
                          Solicitar cotización
                      </v-btn>
                  </v-card-actions>  
              </v-card>

              <v-card v-else align="center">
                  Aún no hay levantamientos disponibles
              </v-card>

              
          </v-expansion-panel-text>
      </v-expansion-panel>

    </v-expansion-panels>
  </div>
</template>
<script>
import { mapState } from "vuex";
import FormatoGlobal from '@/views/Levantamientos/Formatos/FormatoGlobal.vue';
export default {
  components:{
      FormatoGlobal
  },
  props: {
      visita: Object,
      creator: Boolean,
      showAlert: Function,
      hasPermission: Function
  },
  data() {
      return {
          panels:[1],
          unidad_select: null,
          items_tipo_unidades: [],
          loadedComponent: null,
          levantamientos:[],
          loading:true,
          requesting:false
      }
  },

  mounted() {
  },
  watch: {
      unidad_select(){
          if(this.unidad_select != null && typeof this.unidad_select.id_tipo_unidad !== 'undefined'){
            //  this.loadComponent();
          }
      }
  },
  computed: {
      ...mapState("Session", ['session_key', 'user', 'permisos', 'loggedin']),
  },

  async created() {
      await this.ObtenerUnidades();
      await this.cargarLevantamientos();
  },
  methods: {
      async solicitarCotizacion(){
          this.requesting = true;
          await this.$axios.post('cotizaciones.crear', {
            id_cliente:this.visita.id_cliente,
            id_visita:this.visita.id_visita, 
            id_creador:this.user.id_usr
            })
          .then(() => {
              this.cargarLevantamientos();
          })
          .catch(e => { console.log(e) })
          .finally(() => {
              this.requesting = false;
          });
      },
      itemProps (item) {
          return {
          title: item.tipo_unidad_nombre,
          subtitle: item.tipo_unidad_descripcion,
          value:item
          }
      },

      async cargarLevantamientos(){
          this.loadedComponent=null;
          this.panels=[1];
          this.unidad_select=null;
          this.loading = true;
          await this.$axios.post('levantamientos.consulta', {id_visita:this.visita.id_visita})
          .then(r => { 
              console.log(r.data)
              this.levantamientos = r.data;
          })
          .catch(e => { console.log(e) })
          .finally(() => {
            this.loading = false;
          });

          //

      },
      async ObtenerUnidades() {
        //filtrar solo las que trae la visita. ?
        await this.$axios.get('obteneer.tipos.unidades.activas').then(r => { console.log(r); this.items_tipo_unidades = r.data }).catch(e => { console.log(e) })
      },

      // async loadComponent() {
      //     // Carga el componente de manera asíncrona
      //     const module = await import('./Formatos/'+this.unidad_select.component_name+'.vue');
      //     this.loadedComponent = module.default || module;
      // },
      // async loadedEditComponent(component_name){
      //     const module = await import('./Formatos/'+component_name+'.vue');
      //     return module.default || module;
      // }
  }

}
</script>