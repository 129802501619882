<template>
  <v-content class="pa-0">
    <v-row justify="center" no-gutters>
      <v-col cols="12">

        <v-card-actions class="pa-0">
          <h3> <strong> Agendar visita </strong></h3>
          <v-spacer></v-spacer>
          <v-btn color="error" fab small @click="$emit('modal', false)" ><v-icon>mdi-close</v-icon></v-btn>
        </v-card-actions>

        <v-row class="mt-3">

          <v-col cols="12">
          
            <v-row>
              <v-col>
                <v-select v-model="data.id_cliente" :items="clientes" item-title="nombre" item-value="id_usr"
                :loading="Loading" loading-text="Cargando clientes" :disabled="Loading" label="Cliente" hide-no-data
                persistent-hint  no-data-text="Aún no hay clientes registrados"></v-select>
              </v-col>
              <v-col cols="3">
                <v-btn prepend-icon="mdi-plus" color="primary" size="large" style="color:#ffffff;" @click="modal_clientes=true">
                  Cliente
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-select v-model="data.tipo_visita" 
              :items="['Levantamiento', 'Orden de Servicio']"
              label="Motivo de la visita"
              hide-no-data
              persistent-hint>
            </v-select>
          </v-col>

          <v-col cols="12" v-if="hasPermission('agenda_editar_responsable')">

            <v-autocomplete
              label="Responsable"
              v-model="data.id_usuario_responsable"
              :items="responsables"
              item-title="nombre"
              item-value="id_usr"
              :loading="Loading" loading-text="Cargando usuarios" :disabled="Loading"
              no-data-text="Aún no hay usuarios registrados"
              hide-no-data
              persistent-hint 
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="4" lg="4">
            <v-checkbox label="Requiere auxiliar" v-model="r_auxiliar" @click="data.ids_usuarios_aux = []"></v-checkbox>
          </v-col>
          <v-col cols="12" sm="8" lg="8" v-if="r_auxiliar">
            
            <v-autocomplete
              label="Auxiliares"
              v-model="data.ids_usuarios_aux"
              :items="auxiliares"
              item-title="nombre"
              item-value="id_usr"
              :loading="Loading" loading-text="Cargando usuarios" :disabled="Loading"
              no-data-text="Aún no hay usuarios registrados"
              hide-no-data
              persistent-hint 
              multiple
              chips
            ></v-autocomplete>
          </v-col>

          <v-col cols="12">
            <DetePikerInput @ObtenerFechaDP="get_date_date_piker" :label_dp="'Fecha de visita'" :fecha_default="data.fecha_visita_programada"/>
          </v-col>
          
          <v-col cols="12">
            <v-text-field type="time" v-model="hora_inicio" label="Hora inicio" ></v-text-field>
          </v-col>


          <v-col cols="12">
            <v-text-field type="time" v-model="hora_fin" label="Hora fin"></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea v-model="data.comentarios" label="Comentarios" hide-details dense filled></v-textarea>
          </v-col>
        </v-row>

        <v-card-actions class="pa-0 mt-3">
          <v-spacer></v-spacer>
          <v-btn :disabled="saving" persistent :loading="saving" dark center class="white--text" color="blue"
            @click="agendar()">
            Agendar visita
          </v-btn>
        </v-card-actions>

      </v-col>
    </v-row>


    <v-dialog
      v-model="modal_clientes"
      width="auto"
    >
      <v-card class="pa-3" width="700px">
        <ClientesModal :cliente="cliente" :showAlert="showAlert" @modal="modal_clientes = $event" :es_cita="true" :catalogos="catalogos"/>
      </v-card>
    </v-dialog>



  </v-content>
</template>

<script>

import { mapState } from "vuex";
import moment from 'moment';

import DetePikerInput from "@/components/DatePikerInput.vue";
import ClientesModal from "@/views/Clientes/ClientesModal.vue";

export default {

  components: {
    DetePikerInput,
    ClientesModal
  },

  props: {
    showAlert: Function,
    hasPermission:Function,
    consulta: Function
  },

  data() {
    return {

      
      data:{},
      
      Loading:false,
      clientes:[],
      usuarios:[],
      

      r_auxiliar:false,
      responsables:[],

      auxiliares:[],


      modal_clientes:false,
      cliente:{},

      hora_inicio:null,
      hora_fin:null,

      saving:false,

    };
  },

  async created() {
    this.data.fecha_visita_programada = moment().format("YYYY-MM-DD");
   await this.catalogos().then(() => {
    this.responsables = this.usuarios;
    this.data.id_usuario_responsable = this.user.id_usr;
   });
  },
  computed: {
    ...mapState("Session", ['session_key', 'user','permisos', 'loggedin']),
  },
  mounted() {
  },
  watch: {

    'data.id_usuario_responsable'(nuevoVal, antVal){
      this.auxiliares = this.usuarios.filter(us => us.id_usr !== this.data.id_usuario_responsable);
    },

    'data.ids_usuarios_aux'(nuevoVal, antVal){
      this.responsables = this.usuarios.filter(us => !this.data.ids_usuarios_aux.includes(us.id_usr))
    }

  },

  methods: {

    get_date_date_piker(fecha) {
      this.data.fecha_visita_programada = moment(fecha).format('YYYY-MM-DD');
    },

    async catalogos() {
      this.Loading = true;
      await this.$axios
      .get("consulta.catalogos")
      .then((r) => {
        this.clientes = r.data.clientes;
        this.usuarios = r.data.usuarios;

        
      }).finally( () => {
        this.Loading = false;
      } );
    },

    async agendar(){

      //agregar validaciones....
      if(this.data.id_cliente == null || typeof this.data.id_cliente == 'undefined'){
        this.showAlert({
          activo: true,
          texto: "Por favor selecciona un cliente",
          color: "error",
          vertical: true
        });
        return;
      }

      this.saving = true;

      this.data.horario_visita = this.hora_inicio + " - " + this.hora_fin;

      let payload = {
        ...this.data,
        ids_usuarios_aux: this.data.ids_usuarios_aux != null && this.data.ids_usuarios_aux.length > 0 ? this.data.ids_usuarios_aux.join(',') : "",
        id_usuario_creador: this.user.id_usr
      };

      // if(this.data.ids_usuarios_aux != null && this.data.ids_usuarios_aux.length > 0){
        
      //   payload = {...payload, ids_usuarios_aux:this.data.ids_usuarios_aux.join(',') }
      // }

      this.$axios.post('agendar.visita', payload).then( (r) => {
        this.showAlert({
          activo: true,
          texto: r.data,
          color: "success",
          vertical: true
        });
        this.$emit('modal', false);
        this.consulta();
      } ).catch( (error) => {
        console.log(error)
        this.showAlert({
          activo: true,
          texto: error.request.responseText,
          color: "error",
          vertical: true
        });
      } ).finally( () => {
        this.saving = false;
      } )
    }
  },
};
</script>

<style>
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
</style>