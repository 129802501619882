<template>
    <div>
        <v-btn @click="modal = true" icon color="blue">
            <v-icon>
                mdi-image
            </v-icon>
        </v-btn>

        <v-dialog v-model="modal" width="700px">
            <v-card style="padding:10px;">
                <center>
                    <h2>Agregar imagenes</h2>
                </center>



                <v-file-input label="Subir Imagenes" accept="image/*" v-model="selectedImages" variant="outlined"
                    ref="fileInput" type="file" @change="handleFileUpload" multiple></v-file-input>

                <v-row no-gutters>
                    <v-col cols="12" md="12">
                        <v-row>
                            <v-col v-for="(image, index) in img_views" :key="index" cols="12" md="4">
                                <v-img :src="image" aspect-ratio="1"></v-img>
                            </v-col>
                        </v-row>
                    </v-col>

                    <hr>

                    <v-col cols="12" md="12" v-if="typeof comp.images_views !== 'undefined' && comp.images_views.length > 0">
                        <v-row>
                                    <v-col v-for="(image, index) in comp.images_views" :key="index" class="d-flex child-flex" cols="4">
                               
                                    <v-img :src="$baseURL + image" :lazy-src="image" aspect-ratio="1" cover class="bg-grey-lighten-2">
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                indeterminate
                                                color="grey-lighten-5"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                        <v-btn icon size="x-small" color="red" class="ma-2" style="float:right" @click="btn[index] = true; ImgDelete(image, index)" :disabled="btn[index]" :loading="btn[index]">
                                            <v-icon color="white darken-1">mdi-close</v-icon>
                                        </v-btn>
                                    </v-img>
                                
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" align="right" v-if="img_views.length > 0">
                        <v-btn color="success" :disabled="comp.uploading" :loading="comp.uploading" @click="uploadImages()">Guardar imagénes</v-btn>
                    </v-col>
                </v-row>

            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import Swal from 'sweetalert2';
export default {
    props: {
        comp: Object,
        detalles_os: Object,
        viewdata: Function
    },
    data() {
        return {
            modal: false,
            selectedImages: [],
            selectedFiles: [],
            img_views: [],
            btn:[]
        }
    },
    watch: {
        selectedImages() {
            this.obtenerImagen();
        },
    },

    created() {
        this.comp.uploading = false;
    },
     methods: {

        async ImgDelete(image,index){

            const resultado = await Swal.fire({
                title: '¿Estás seguro?',
                text: 'Se realizará una acción irreversible',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, estoy seguro'
            });

            if (resultado.isConfirmed) {

            //confirm..
                this.comp.images_views.splice(index, 1);
                await this.viewdata().then(() => {
                    window.showAlert({
                        activo: true,
                        texto: "Imagen eliminada con éxito!",
                        color: "success",
                        vertical: true
                    });
                }).catch((e) => {
                    window.showAlert({
                        activo: true,
                        texto: "Lo sentimos! Algo salio mal...",
                        color: "error",
                        vertical: true
                    });
                    this.comp.images_views.push(image)
                }).finally( () => {
                    this.btn[index] = false;
                } );
            }
        },

        async uploadImages(){

            if(this.selectedFiles.length > 0){

                this.comp.uploading=true;

                const fd = new FormData;
                fd.append('path', 'checklist/'+this.detalles_os.id_ot+'/');
                
                for (let i = 0; i < this.selectedFiles.length; i++) {
                    const file = this.selectedFiles[i];
                    fd.append(`files[${i}]`, file);
                }

                await this.$axios.post(this.$uploadsURL, fd, {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                }).then(async(r) => {
                    
                    
                    const dataArray = r.data.split(';');
                    dataArray.forEach((item, index) => {

                        if(typeof this.comp.images_views == 'undefined'){
                            this.comp.images_views = [];
                        }

                        this.comp.images_views.push(item);
                        
                    });

                    await this.viewdata().then( () => {
                        this.selectedImages = []
                        this.selectedFiles = []
                        this.img_views = []
                    } );
                    

                }).finally( () => {
                    this.comp.uploading=false;
                } );
            }
            
        },

        obtenerImagen() {
            this.img_views = [];
            this.selectedImages.forEach(img => {
                this.img_views.push(URL.createObjectURL(img))
            });
        },

        handleFileUpload(event) {
            this.selectedFiles = event.target.files;
        },

    }
}
</script>

<style>
.swal2-container {
    z-index: 9999999 !important;
}
</style>