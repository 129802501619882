<template>
  <v-content class="pa-0">
		<v-row justify="center" no-gutters>
			<v-col cols="12">

				<v-card-actions class="pa-0" >
					<h3> <strong> {{ nuevoRegistro ? 'NUEVO USUARIO':'ACTUALIZAR DATOS DEL USUARIO' }}  </strong></h3> 
					<v-spacer></v-spacer>
					<v-btn color="error" fab small @click="$emit('modal',false)" ><v-icon>mdi-close</v-icon></v-btn>
				</v-card-actions>

				<v-row class="mt-3">
					<!-- NOMBRE DEL CLIENTE -->
					<v-col cols="12" >
						<v-text-field
							v-model="item.nombre" label="Nombre" placeholder="Nombre" append-icon="person"
							hide-details dense filled
						></v-text-field>
					</v-col>
					<!-- RAZON SOCIAL -->
					<v-col cols="12">
						<v-text-field
							v-model="item.rfc" label="RFC" placeholder="RFC" append-icon="domain"
							hide-details dense filled 
						></v-text-field>
					</v-col>

          <v-col cols="12" >
						<v-text-field
							v-model="item.usuario" label="Usuario" placeholder="Ingresa un nombre de usuario" append-icon="person_pin_circle"
							hide-details dense filled
						></v-text-field>
					</v-col>
          <v-col cols="12" >
						<v-text-field type="password"
							v-model="item.contrasena" label="Contraseña" placeholder="Ingresa una clave de acceso" append-icon="person_pin_circle"
							hide-details dense filled
						></v-text-field>
					</v-col>

          <v-col cols="12" >
						<!-- <v-text-field type="password"
							v-model="item.contrasena" label="Contraseña" placeholder="Ingresa una clave de acceso" append-icon="person_pin_circle"
							hide-details dense filled

              hint="Cargando roles..."

						></v-text-field> :hint="`${select.state}, ${select.abbr}`" -->
            <v-select
              
              v-model="role"
              :items="roles"
              item-title="desc_role"
              item-value="id_role"
              :loading="Loading"
              :disabled="Loading"
              
              
              label="Rol del usuario"
              
              hide-no-data
              persistent-hint
              return-object
              single-line

            ></v-select>
					</v-col>

          <!-- regimen fiscal -->

					<!-- DIRECCION DEL CLIENTE -->
					<v-col cols="12" >
						<v-text-field
							v-model="item.direccion" label="Dirección" placeholder="Calle #000, Colonia" append-icon="person_pin_circle"
							hide-details dense filled
						></v-text-field>
					</v-col>

					<!-- CODIGO POSTAL -->
					<v-col cols="12" >
						<v-text-field
							v-model="item.codigo_postal" label="Código Postal" placeholder="C.P." append-icon="where_to_vote"
							hide-details dense filled type="number"
						></v-text-field>
					</v-col>

          	<!--  CIUDADES -->
					<!-- <v-col cols="12" >
						<v-autocomplete
							:items="ciudades" v-model="ciudad" item-text="nombre" item-value="id" label="Ciudades" placeholder="Ciudad"
							dense filled hide-details return-object color="celeste" append-icon="location_city"
						></v-autocomplete>
					</v-col> -->

          <!-- estado... -->

					<!-- <v-col cols="12" >
						<v-select
							:items="zonas"
							item-text="nombre"
							item-value="id"
							return-object
							label="Zona"
							placeholder="Zona del cliente"
							append-icon="pin_drop"
							dense
							filled
							hide-details
							v-model="zona"
						></v-select>
					</v-col> -->

					<!-- TIPO DE CLIENTE -->
					<!-- <v-col cols="12" >
						<v-select
							v-model="tipo_cliente" :items="['Nacional','Internacional']" label="Tipo de Cliente" placeholder="Tipo de cliente"
							append-icon="gps_fixed" dense filled hide-details
						></v-select>
					</v-col> -->
					<!-- NIVEL DEL CLIENTE -->
					<!-- <v-col cols="12" >
						<v-select
							:items="niveles"
							item-value="id"
							item-text="nombre"
							label="Nivel del cliente"
							placeholder="Tipo de cliente"
							append-icon="how_to_reg"
							dense
							filled
							hide-details
							v-model="nivel"
							return-object
						></v-select>
					</v-col> -->
					<!-- RFC DEL CLIENTE -->
					<!-- <v-col cols="12" >
						<v-text-field
							v-model="rfc" label="RFC" placeholder="RFC perteneciente al cliente" append-icon="recent_actors"
							hide-details 	dense filled
						></v-text-field>
					</v-col> -->
					<!--  DIAS DE FACTURACION -->

					<!-- <v-col cols="12" >
						<v-text-field
							v-model="diasFact" label="Dias limite de factura" placeholder="Días limite de factura"	append-icon="insert_drive_file"
							hide-details dense filled	type="number"
						></v-text-field>
					</v-col> -->
					
					<!-- <v-col cols="12" >
						<v-select
							:items="carteras"
							item-text ="nombre"
							item-value ="id"
							return-object
							label="Carteras"
							placeholder="Cartera al que pertenece el cliente"
							append-icon="folder_shared"
							dense
							filled
							hide-details
							v-model="cartera"
						></v-select>
					</v-col> -->

					<!-- TELEFONO 1 DEL CLIENTE -->
					<v-col cols="12" sm="6">
						<v-text-field
							v-model="item.telefono" label="Teléfono" placeholder="Número de contacto" append-icon="phone"
							hide-details dense 	outlined type="number"
						></v-text-field>
					</v-col>
					<!-- EXTENCION 1 -->
					<v-col cols="12" sm="6">
						<v-text-field
							v-model="item.telefono_ext" label="Extención" placeholder="Extención" append-icon="settings_phone"
							hide-details dense outlined type="number"
						></v-text-field>
					</v-col>

					<v-col cols="12">
						<v-text-field
							v-model="item.celular" label="Celular" placeholder="Número de contacto (cel.)" append-icon="phone"
							hide-details dense 	outlined type="number"
						></v-text-field>
					</v-col>
					
					
				</v-row>

				<v-card-actions class="pa-0 mt-3">
					<v-spacer></v-spacer>
					 <v-btn  
					 	:disabled="saving" 
						 persistent 
						 :loading="saving"
						 dark center 
						 class="white--text" 
						 color="blue" 
						 @click="updcreated()">
             {{  nuevoRegistro ? 'AGREGAR CUENTA DE USUARIO' : 'GUARDAR CAMBIOS' }}
          </v-btn> 
				</v-card-actions>

			</v-col>
		</v-row>
	</v-content >
</template>

<script>
export default {
  
  setup(){  },
  computed: {  },
  components: { },

  props: {
    item: Object,
    showAlert: Function,
    consulta: Function
  },

  data() {
    return {
      nuevoRegistro: Object.keys(this.item).length === 0 ? true : false,
      saving:false,
      Loading:true,
      roles:[],
      role:{
        desc_role:'Administrador',
        id_role:1
      },
    };
  },

  created() {

    this.consultaRoles();

    // if(!this.nuevoRegistro){
        // this.cliente = {...this.cliente, key_role:'cliente'};
        // const cliente = new Proxy(this.cliente, {
        //   get(target, property) {
        //     // Implementa el comportamiento deseado para acceder a la propiedad
        //     if (property in target) {
        //       return target[property]; // Devuelve el valor de la propiedad si existe
        //     } else {
        //       return undefined; // Devuelve undefined si la propiedad no existe
        //     }
        //   },
        //   set(target, property, value) {
        //     target[property] = value;
        //     return true;
        //   }
        // });

        // console.log(typeof item.key_role)
      // }
  },

  mounted() {

  },

  watch: {
    // role(){
    //   this.item.id_role = this.role.id_role;
	  // 	this.item.desc_role = this.role.desc_role;
    // }
  },
  
  methods: {
    async consultaRoles(){
      await this.$axios
        .get("consulta.roles")
        .then((r) => {

          const obj = new Proxy(r.data, {
            get(target, property) {
              // Implementa el comportamiento deseado para acceder a la propiedad
              if (property in target) {
                return target[property]; // Devuelve el valor de la propiedad si existe
              } else {
                return undefined; // Devuelve undefined si la propiedad no existe
              }
            },
            set(target, property, value) {
              target[property] = value;
              return true;
            }
          });

          this.roles = obj;
          if(!this.nuevoRegistro){
						this.role = this.roles.filter(r => r.id_role == this.item.rol_principal);
						this.role = this.role[0];
					}
          //  /*  this.role = this.roles.filter(r => r.key_role == this.item.key_role); */
					// 	this.role = this.roles.filter(r => r.id_role == this.item.rol_principal);
					// 	this.role = this.role[0];
					// 	console.log(this.role);
					// }

						this.Loading=false;

          // key_role

        });
    },

    async updcreated() {

      var me = this;
      this.saving = true;
      let payload = {
        table:'usuarios',
        foreignkey:'id_usr',
        protectedkeys:['id_usr'],
        data: this.item
      }
      await this.$axios
        .post("datatable.updcreated", payload)
        .then((r) => {
          //alert...and reload table of 

          this.showAlert({
            activo: true, 
            texto: this.nuevoRegistro ? "Servicio agregado con éxito!" : "Datos actualizados con éxito!", 
            color: "success", 
            vertical: true
          });

          if(this.nuevoRegistro){
            this.consulta();
          }

          me.$emit('modal',false);

        })
        .catch((e) => {
          console.log(e);
          this.showAlert({
            activo: true, 
            texto:"Lo sentimos! Algo salio mal...", 
            color: "error", 
            vertical: true
          });
        }).finally( () => {
          this.saving = false;
          //close modal?
        } );
    },
  },
};
</script>