
<template>
  <div>
    
    <v-row>
      <v-col>
        <v-table>
          <tbody>
            <tr>
              <td class="font-weight-black" style="width:10%">Fecha solicitud</td>
              <td>{{_cotizacion.date_created}}</td>
            </tr>
            
            <tr>
              <td class="font-weight-black" style="width:10%">CLIENTE</td>
              <td>{{_cotizacion.cliente}}</td>
            </tr>

            <tr>
              <td class="font-weight-black" style="width:10%">RESPONSABLE</td>
              <td>{{_cotizacion.responsable}}</td>
            </tr>

            <tr>
              <td class="font-weight-black" style="width:10%">NO. VISITA</td>
              <td>{{_cotizacion.id_visita}}</td>
            </tr>

            <tr v-if="_cotizacion.id_levantamiento !== null">
              <td class="font-weight-black" style="width:10%">LEVANTAMIENTO</td>
              <td>
                L{{levantamiento.id_levantamiento}} - {{ levantamiento.tipo_unidad_nombre }}</td>
            </tr>

            <tr>
              <td class="font-weight-black" style="width:10%">ESTATUS</td>
              <td>
                <span v-if="_cotizacion.estatus == 0">Pendiente</span>
                <span v-else-if="_cotizacion.estatus == 1">Enviada al cliente</span>
                <span v-else-if="_cotizacion.estatus == 2">Autorizada</span>
                <span v-else-if="_cotizacion.estatus == 3">Rechazada</span>
                <span v-else-if="_cotizacion.estatus == 4">Cancelada</span>
              </td>
            </tr>

            <tr v-if="_cotizacion.estatus > 1">
              <td class="font-weight-black" style="width:10%">
                <span v-if="_cotizacion.estatus == 2">AUTORIZACIÓN</span>
                <span v-else-if="_cotizacion.estatus == 3">RECHAZO</span>
                <span v-else-if="_cotizacion.estatus == 4">CANCELACIÓN</span>
              </td>
              <td>
                {{ _cotizacion.fecha_cambio_estatus }}
              </td>
            </tr>

            <tr v-if="_cotizacion.estatus == 2 || _cotizacion.estatus == 3">
              <td class="font-weight-black" style="width:10%">COMENTARIOS DEL CLIENTE</td>
              <td>
                {{ _cotizacion.comentarios_cliente }}
              </td>
            </tr>


            <tr v-if="_cotizacion.estatus == 2">
              <td class="font-weight-black" style="width:10%">NO. ORDEN DE COMPRA</td>
              <td>
                {{ _cotizacion.oc }}
              </td>
            </tr>

            <tr v-if="_cotizacion.estatus == 2">
              <td class="font-weight-black" style="width:10%">NO. ORDEN DE COMPRA</td>
              <td>
                <a :href="getURL(_cotizacion.file_oc)" target="blank">Descargar</a>
              </td>
            </tr>

            

          </tbody>
        </v-table>
      </v-col>
      <v-col>
        <v-table>
          <tbody>

            <tr v-if="_cotizacion.estatus > 0 && _cotizacion.fecha_cotizacion_enviada != null">
              <td class="font-weight-black" style="width:10%">FECHA COTIZACIÓN</td>
              <td>
                {{ _cotizacion.fecha_cotizacion_enviada }}
              </td>
            </tr>

            <tr v-if="_cotizacion.estatus > 0">
              <td class="font-weight-black" style="width:10%">COTIZADO POR</td>
              <td>
                {{_cotizacion.cotizador}}
              </td>
            </tr>

            <tr>
              <td class="font-weight-black" style="width:10%">VENCIMIENTO</td>
              <td>
                <!-- v-if="_cotizacion.estatus == 0"  -->
                <v-text-field v-model="_cotizacion.fecha_cotizacion_vencimiento" type="date" label="Fecha de vencimiento" density="compact" variant="outlined" style="margin-top:15px"></v-text-field>
                <!-- <span v-else>{{_cotizacion.fecha_cotizacion_vencimiento}}</span> -->
              </td>
            </tr>

            

            <tr>
              <td class="font-weight-black" style="width:10%">TÉCNICO TITULAR</td>
              <td>
                <v-select v-if="cotizacion.estatus == 0" v-model="tecnicos_asignados" :items="tec_titulares" item-title="nombre" return-object item-value="id_usr" label="Seleccionar técnicos titulares" density="compact" variant="outlined" style="margin-top:15px"></v-select>
                <!-- v-if="cotizacion.estatus == 0" v-select -->
                <template v-else>
                  {{tec_titulares.find(t => t.id_usr == _cotizacion.id_tecnicos_asignados).nombre}}
                  <!-- <span v-for="(tec, index) in tecnicos_asignados">
                    {{  tec.nombre }} {{ (index+1)==tecnicos_asignados.length ? '' : ',' }}
                  </span> -->
                </template>
              </td>
            </tr>

            <tr>
              <td class="font-weight-black" style="width:10%">AUXILIARES</td>
              <td>

                <!-- {{ visita.ids_usuarios_aux }} -->
                <!-- {{ auxiliares_asignados }} -->
                  <!-- v-if="_cotizacion.estatus == 0" -->
                <v-select  v-model="auxiliares_asignados" :items="tec_auxiliares" item-title="nombre" item-value="id_usr" return-object label="Seleccionar técnicos titulares" density="compact" variant="outlined" style="margin-top:15px" multiple></v-select>
                <!-- <template v-else-if="auxiliares_asignados.length > 0">
                  <span v-for="(aux, j) in auxiliares_asignados">
                    {{  aux.nombre }} {{ (j+1)==auxiliares_asignados.length ? '' : ',' }}
                  </span>
                </template> -->
                <!-- <span v-else><i>Sin auxiliares asignados</i></span> -->
              </td>
            </tr>

            <!-- <tr>
              <td class="font-weight-black" style="width:10%">FECHA TENTATIVA SERVICIO</td>
              <td>
                <v-text-field v-if="_cotizacion.estatus == 0" v-model="_cotizacion.fecha_tentativa_servicio" type="date" label="Fecha del servicio" density="compact" variant="outlined" style="margin-top:15px"></v-text-field>
                <span v-else>{{_cotizacion.fecha_tentativa_servicio}}</span>
              </td>
            </tr> -->
            <tr>
              <td class="font-weight-black" style="width:10%">Observaciones</td>
              <td>
                <!-- v-if="_cotizacion.estatus == 0" -->
                <v-textarea v-model="_cotizacion.comentarios_cotizador" label="Notas de la cotización" variant="outlined" density="compact" style="margin-top:15px"></v-textarea>
                <!-- <span v-else>{{ _cotizacion.comentarios_cotizador }}</span> -->
              </td>
            </tr>                          
          </tbody>
        </v-table>
      </v-col>
    </v-row>
    <br/>
    <hr>
    <br/>
    <v-row>
      <v-col>
        <v-card elevation="0" class="col-lg-12 mt-5">
          <v-card-actions>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar concepto"
                  single-line
                  hide-details
                  outlined
              ></v-text-field>
              <v-spacer></v-spacer>
              <!-- v-if="_cotizacion.estatus == 0" -->
              <v-btn
                
                style="float: right"
                color="green darken-4"
                small
                fab
                class="white--text"
                @click="modalConcepto = true"
              ><v-icon>mdi-plus</v-icon> Concepto
              </v-btn>

              <v-btn fab icon color="green"> <v-icon>mdi-refresh</v-icon></v-btn>

          </v-card-actions>

          <v-card-body>
            <v-data-table 
              v-model:items-per-page="itemsPerPage"
              :headers="cotizacion_headers"
              :items="_cotizacion_items"
              :search="search"
              fixed-header
              :loading ="cotizacion_loading"
              loading-text="Cargando... Por favor espere."
              no-data-text="Aún no hay conceptos agregados a la cotización"
              dense
            >
              <template v-slot:item="{ item, index }">
                <tr>
                  <td>
                    <!-- _cotizacion.estatus == 0 && -->
                    <v-text-field v-if="item.selectable.id_prodserv == null" @input="
                    updateItem(item.selectable, 'concepto')" :append-inner-icon="item.selectable.concepto_spin ? 'mdi-spin mdi-loading' : ''" style="margin-top:15px"  v-model="item.selectable.concepto" label="Concepto" density="compact" variant="outlined"></v-text-field>
                    <span v-else>{{ item.selectable.concepto }}</span> 
                  </td>

                  <td>
                    <!-- v-if="_cotizacion.estatus == 0" -->
                    <v-textarea  @input="
                    updateItem(item.selectable, 'descripcion')" :append-inner-icon="item.selectable.descripcion_spin ? 'mdi-spin mdi-loading' : ''" style="margin-top:15px"  v-model="item.selectable.descripcion" label="Descripción" density="compact" variant="outlined"></v-textarea>
                    <!-- <span v-else>{{ item.selectable.descripcion }}</span>  -->
                  </td>

                  <td>
                    <!-- _cotizacion.estatus == 0 &&  -->
                    <v-text-field v-if="item.selectable.prodserv == 'producto'" type="number" @input="
                    updateItem(item.selectable, 'cantidad')" :append-inner-icon="item.selectable.cantidad_spin ? 'mdi-spin mdi-loading' : ''" style="margin-top:15px"  v-model="item.selectable.cantidad" label="Cantidad" density="compact" variant="outlined"></v-text-field>
                    <!-- <span v-else>{{ item.selectable.cantidad }}</span> -->
                  </td>

                  <td>
                    {{ item.selectable.unidad }}
                    <!-- <v-select v-if="cotizacion.estatus == 0 && item.selectable.prodserv == 'producto'" @input="
                    updateItem(item.selectable, 'unidad')" :append-inner-icon="item.selectable.unidad_spin ? 'mdi-spin mdi-loading' : ''"  style="margin-top:15px" v-model="item.selectable.unidad" label="Unidad" density="compact" variant="outlined" :items="['Pieza', 'Servicio']"></v-select>
                    <span v-else>{{ item.selectable.unidad }}</span> -->
                  </td>

                  <td>
                    <!-- v-if="_cotizacion.estatus == 0" -->
                    <v-text-field  type="number" prefix="$" @input="
                    updateItem(item.selectable, 'precio_unitario')" :append-inner-icon="item.selectable.precio_unitario_spin ? 'mdi-spin mdi-loading' : ''"  style="margin-top:15px" v-model="item.selectable.precio_unitario" label="Precio unit." density="compact" variant="outlined"></v-text-field>
                    <!-- <span v-else>{{ item.selectable.precio_unitario }}</span> -->
                  </td>
                  <td>
                    {{ parseFloat(item.selectable.precio_unitario*item.selectable.cantidad).toFixed(2) }}
                  </td>
                  <td>
                    <!-- v-if="_cotizacion.estatus == 0" -->
                    <v-btn color="red" class="ma-1"  small dark  @click="item.selectable.deleting=true; deleteItem(item.selectable)" :disabled="item.selectable.deleting" :loading="item.selectable.deleting">
                      <v-icon> mdi-delete </v-icon>
                    </v-btn> 
                  </td>
                </tr>

                <template v-if="(index+1 ) == _cotizacion_items.length">
                  <tr>
                    <td colspan="5" align="right"><strong>Subtotal</strong></td>
                    <td colspan="2">{{ _cotizacion.subtotal }}</td>
                  </tr>
                  <tr>
                    <td colspan="5" align="right"><strong>IVA (%)</strong></td>
                    <td colspan="2">
                      <!-- v-if="_cotizacion.estatus == 0" -->
                      <v-text-field @input="_cotizacion.iva_porcentaje_spin=true; updateCotizacion('iva') " :append-inner-icon="_cotizacion.iva_porcentaje_spin ? 'mdi-spin mdi-loading' : ''" type="number" step="0.01" style="margin-top:15px"  v-model="_cotizacion.iva_porcentaje" label="Impuestos" density="compact" variant="outlined"></v-text-field>
                      <!-- <span v-else>
                        {{ _cotizacion.iva_porcentaje }} %
                      </span> -->
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5" align="right"><strong>Monto IVA</strong></td>
                    <td colspan="2">{{ _cotizacion.iva_monto }}</td>
                  </tr>
                  <tr>
                    <td colspan="5" align="right"><strong>Total</strong></td>
                    <td colspan="2">{{ _cotizacion.total }}</td>
                  </tr>

                  <tr>
                    <td colspan="5" align="right"><strong>Moneda</strong></td>
                    <td colspan="2">
                      <!--  v-if="_cotizacion.estatus == 0" -->
                      <v-select v-model="_cotizacion.moneda" :items="['MXN', 'USD']" label="Seleccionar una moneda" style="margin-top:15px" density="compact" variant="outlined" @update:modelValue="_cotizacion.moneda_spin = true; updateCotizacion('moneda')" :append-inner-icon="_cotizacion.moneda_spin ? 'mdi-spin mdi-loading' : ''"></v-select>
                      <!-- <span v-else>{{ _cotizacion.moneda }}</span> -->
                    </td>
                  </tr>

                </template>
              </template>
            
          

            </v-data-table>
            

          </v-card-body>

        </v-card>
      </v-col>
    </v-row>
    <br/>
    <hr>
    <br/>
    <v-row v-if="user.role_name != 'Cliente'">
      <v-col cols="12">
        <v-btn block :color="_cotizacion.estatus == 0 ? 'green' : 'blue'" :disabled="enviar" :loading="enviar" @click="enviarCotizacion()"><v-icon>mdi-check</v-icon> {{ _cotizacion.estatus == 0 ? 'ENVIAR' : 'REENVIAR' }} COTIZACIÓN</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-text-field v-model="oc" label="No. de Orden de compra" variant="outlined" density="compact"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-file-input v-model="file_oc" @input="handleFileUpload" accept="image/*, application/pdf" label="PDF Orden de compra" variant="outlined" density="compact"></v-file-input>
      </v-col>
      <v-col cols="12" >
        <v-textarea  v-model="_cotizacion.comentarios_cliente" label="Comentarios del cliente" variant="outlined" density="compact" style="margin-top:15px"></v-textarea>
      </v-col>
      <v-col cols="6" >
        <v-btn block color="error" :disabled="enviar" @click="estatusCotizacion(3)"><v-icon>mdi-times</v-icon> RECHAZAR </v-btn>
      </v-col>
      <v-col cols="6" >
        <v-btn block color="green" :disabled="enviar" @click="estatusCotizacion(2)"><v-icon>mdi-check</v-icon> AUTORIZAR </v-btn>
      </v-col>
    </v-row>

    <v-dialog persistent v-model="modalConcepto" width="700px" >	
      <v-card class="pa-5">
        <Modal :id_cotizacion="_cotizacion.id_cotizacion" v-on:cargarCotizacionItems="cargarCotizacionItems" @modal="modalConcepto = $event"/>
      </v-card>
    </v-dialog>


  </div>
</template>



<script>

import CotizacionPDF from "@/mixins/CotizacionPDF.js";
import { mapState } from "vuex";
import ScreenSplash from '@/components/ScreenSplash.vue';
import Modal from './ConceptoModal.vue';

import moment from 'moment';

export default {
  mixins: [CotizacionPDF],
  components: {
    ScreenSplash,
    Modal
  },

  computed: {
    ...mapState("Session", ['session_key', 'user','permisos', 'loggedin']),
    ...mapState(['router']),
    screenWidth() {
      return window.innerWidth;
    }
  },
  props: {
    visita:Object,
    cotizacion: Object,
    cotizacion_items:Array,
    tecnicos:Array,
    levantamiento:Object,
    showAlert:Function,
    hasPermission:Function
  },

  setup(){
    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file && file.type !== 'application/pdf') {
        window.showAlert({
          activo: true,
          texto: 'Por favor, seleccione un archivo PDF válido.',
          color: "error",
          vertical: true
        });
        event.target.value = null;
      }
    };

    return {
      handleFileUpload
    };
  },

  data() {
    return {
        file_oc:[],
        oc:'',
        tec_titulares:[],
        tec_auxiliares:[],
        tecnicos_asignados:[],
        auxiliares_asignados:[],
      
        enviar:false,
        modalConcepto:false,
        concepto:null,
        
        _cotizacion: this.cotizacion,
        _cotizacion_items:this.cotizacion_items,
        _tecnicos:this.tecnicos, 

        itemsPerPage: 100,
        cotizacion_headers:[
                { title: 'Producto o servicio'	 , align: 'left'  , key: 'concepto' 	},
                { title: 'Descripción'	 , align: 'left'  , key: 'descripcion' 	},
                { title: 'Cantidad'  					 , align: 'left'  , key: 'cantidad' },
                { title: 'Unidad'  					 , align: 'left'  , key: 'unidad' },
                { title: 'Precio unit.'	 , align: 'left'  , key: 'precio_unitario' },
                { title: 'Precio Total'	 , align: 'left'  , key: 'total' },
                { title: ''	 , align: 'left'  , key: 'action' }
              ],

        search:'',
        cotizacion_loading:false,
    };
  },
  watch: {
    tecnicos_asignados: {
      handler(newValue, oldValue) {
        console.log(newValue);
        this.tec_auxiliares = this._tecnicos.filter(us => newValue.id_usr !== us.id_usr);
        // this.tec_auxiliares = this._tecnicos.filter(us =>  !newValue.includes(us));
      },
      deep: true
    },

    auxiliares_asignados: {
      handler(newValue, oldValue) {
        this.tec_titulares = this._tecnicos.filter(us =>  !newValue.includes(us));
      },
      deep: true
    }

  },
  created() {
    

    this.tec_titulares = this._tecnicos;
    this.tec_auxiliares = this._tecnicos;
    if(this._cotizacion.estatus == 0){
      this.auxiliares_asignados = this.visita.ids_usuarios_aux != null ? (this.visita.ids_usuarios_aux.includes(',') ? this.visita.ids_usuarios_aux.split(',') : [this.visita.ids_usuarios_aux]) : [];
      this.auxiliares_asignados = this.tec_auxiliares.length > 0 ? this.tec_auxiliares.filter(t => this.auxiliares_asignados.includes( String(t.id_usr) ) ) : [];
    } else {
      this.auxiliares_asignados = this._cotizacion.id_auxiliares_asignados != null ? (this._cotizacion.id_auxiliares_asignados.includes(',') ? this._cotizacion.id_auxiliares_asignados.split(',') : [this._cotizacion.id_auxiliares_asignados]) : [];
      this.auxiliares_asignados = this.tec_auxiliares.length > 0 ? this.tec_auxiliares.filter(t => this.auxiliares_asignados.includes( String(t.id_usr) ) ) : [];
    }
    
    //     this.auxiliares_asignados = this.cotizacion.id_auxiliares_asignados !== null ? (this.cotizacion.id_auxiliares_asignados.includes(',') ? this.cotizacion.id_auxiliares_asignados.split(',') : this.cotizacion.id_auxiliares_asignados) : [];
    //     this.auxiliares_asignados = this.tec_auxiliares.length > 0 ? this.tec_auxiliares.filter(t => this.auxiliares_asignados.includes(t.id_usr)) : [];
    this.calc();
  },

  methods: {
    getURL(){
      return new URL(this._cotizacion.file_oc, this.$uploadsURL).toString()
    },
    calc(){
    

      console.log("con esta data hace el calculo:", JSON.parse(JSON.stringify(this._cotizacion_items)));
      let subtotal = 0;
      if(this._cotizacion_items.length > 0 ){
        this._cotizacion_items.forEach(item => {
          subtotal += item.precio_unitario * item.cantidad;
        });
      }
      
      this._cotizacion.subtotal = parseFloat(subtotal).toFixed(2);

      this._cotizacion.iva_monto = 0;
      if(this._cotizacion.subtotal > 0 && this._cotizacion.iva_porcentaje !== null){
        this._cotizacion.iva_monto = parseFloat(this._cotizacion.subtotal * parseFloat(this._cotizacion.iva_porcentaje / 100).toFixed(6) ).toFixed(2);
      }

      this._cotizacion.total = parseFloat(parseFloat(this._cotizacion.subtotal) + parseFloat(this._cotizacion.iva_monto)).toFixed(2);
    },

    async estatusCotizacion(estatus){
      let text = estatus == 2 ? 'autorizar' : 'rechazar';

      if(estatus == 2 && this.oc == ''){
        window.showAlert({
          activo: true,
          texto: "Por favor ingresa el no. de OC",
          color: "error",
          vertical: true
        });
        return;
      }

      if(estatus == 2 && (this.file_oc == null || this.file_oc.length < 1)){
        window.showAlert({
          activo: true,
          texto: "Por favor ingresa el documento pdf de OC",
          color: "error",
          vertical: true
        });
        return;
      }


      if(window.confirm("¿Éstas seguro que desea "+text+" esta cotización?")){
        
        this.enviar = true;

        let formData = new FormData();
        formData.append('id_usuario_contestacion', this.user.id_usr);
        formData.append('id_cotizacion', this._cotizacion.id_cotizacion);
        formData.append('estatus', estatus);
        formData.append('comentarios_cliente', this._cotizacion.comentarios_cliente);

        if(estatus == 2){

          formData.append('oc', this.oc);

          let uploadata = new FormData();
          uploadata.append('path', 'cotizaciones/'+this._cotizacion.id_cotizacion+'/ordenes_compra/');
          for (let i = 0; i < this.file_oc.length; i++) {
            uploadata.append('files['+i+']', this.file_oc[i]);
          }

          const uploadAPI = new URL('uploads.php', this.$uploadsURL).toString();

          await this.$axios.post(uploadAPI, uploadata).then((r)=>{
            formData.append('fileoc', r.data);
          }).catch( (error) => {
            console.log(error);
            window.showAlert({
              activo: true,
              texto: error.response.data,
              color: "error",
              vertical: true
            });
            this.enviar = false;
          });
        }

        if(this.enviar){
          await this.$axios.post('cotizaciones.estatus', formData).then(()=>{
            window.showAlert({
              activo: true,
              texto: "Cotización "+ (estatus == 2 ? 'autorizada' : 'rechazada') + " con éxito!",
              color: "success",
              vertical: true
            });
            setTimeout(() => {
              window.location.reload();
            }, 1500);

          }).catch(e=>{
            console.log(e)
          }).finally(() => {
            this.enviar = false;
          });
        }
        
      }
      

    },
    async updateCotizacion(type){
      //this??
      await this.$axios.post('cotizaciones.update', {cotizacion:this._cotizacion}).then(()=>{
        
      }).catch(e=>{
        console.log(e)
      }).finally(() => {
        if(type == 'moneda'){
          this._cotizacion.moneda_spin = false;
        } else if(type == 'iva'){
          this._cotizacion.iva_porcentaje_spin = false;
          this.calc();
        }
      });

    },

    async cargarCotizacionItems(){
      this.cotizacion_loading=true;
      await this.$axios.post('cotizaciones.items', {id_cotizacion:this._cotizacion.id_cotizacion}).then(r=>{
        console.log("esta data es la que deberia usar para el calculo: ", r.data)
        this._cotizacion_items = r.data;
      }).catch(e=>{console.log(e)}).finally( () => {
        this.cotizacion_loading=false;
        this.calc();
      });
    },

    async deleteItem(item){
      if(window.confirm("¿Éstas seguro que desea eliminar este concepto '"+item.concepto+"'?")){
        await this.$axios.post('cotizaciones.deleteItem', item).then(()=>{
          this.cargarCotizacionItems();
        }).catch(e=>{
          window.showAlert({
            activo: true,
            texto: "Lo sentimos! Algo salio mal...",
            color: "error",
            vertical: true
          });
        }).finally( () => {
          item.deleting = false;
        });
      } else {
        item.deleting = false;
      }
    },
    async updateItem(item, column){

      if(item.timer != null){
        clearTimeout(item.timer);
      }
      
      item.timer = setTimeout(async() => {
        if(column == 'precio_unitario'){
          item.precio_unitario_spin=true;
        } else if(column == 'cantidad'){
          item.cantidad_spin=true;
        } else if(column == 'concepto'){
          item.concepto_spin=true;
        } else if(column == 'unidad'){
          item.unidad_spin=true;
        } else if(column == 'descripcion'){
          item.descripcion_spin=true;
        }
        
        await this.$axios.post('cotizaciones.updateItem', item).then(()=>{
        
        }).catch(e=>{
          window.showAlert({
            activo: true,
            texto: "Lo sentimos! Algo salio mal...",
            color: "error",
            vertical: true
          });
        }).finally( () => {

          if(column == 'precio_unitario'){
            item.precio_unitario_spin=false;
          } else if(column == 'cantidad'){
            item.cantidad_spin=false;
          } else if(column == 'concepto'){
            item.concepto_spin=false;
          } else if(column == 'unidad'){
            item.unidad_spin=false;
          } else if(column == 'descripcion'){
            item.descripcion_spin=false;
          }

          this.calc();

        });
      }, 1000);

      
    },
   
    

    async enviarCotizacion(){
      if(this._cotizacion.fecha_cotizacion_vencimiento == null){
        window.showAlert({
          activo: true,
          texto: "Por favor, ingresa una fecha de vencimiento de la cotización",
          color: "error",
          vertical: true
        });
        return;
      }

      if(this._cotizacion.moneda == null){
        window.showAlert({
          activo: true,
          texto: "Por favor, ingresa una moneda en la cotización",
          color: "error",
          vertical: true
        });
        return;
      }

      if(this._cotizacion.estatus == 0 && this.tecnicos_asignados.length < 1){
        window.showAlert({
          activo: true,
          texto: "Por favor, selecciona al menos a un técnico títular",
          color: "error",
          vertical: true
        });
        return;
      }

      // if(this._cotizacion.fecha_tentativa_servicio == null){
      //   window.showAlert({
      //     activo: true,
      //     texto: "Por favor, selecciona una fecha tentativa de servicio",
      //     color: "error",
      //     vertical: true
      //   });
      //   return;
      // }

      if(this._cotizacion.estatus == 0 && this._cotizacion_items.length < 1){
        window.showAlert({
          activo: true,
          texto: "Por favor, ingresa al menos un concepto en esta cotización",
          color: "error",
          vertical: true
        });
        return;
      }


      let textsend= this._cotizacion.estatus == 0 ? 'enviar' : 'reenviar';
      if(window.confirm("¿Éstas seguro que desea "+textsend+" esta cotización?")){
        
        
        
        // una vez que las cotizaciones sean autorizadar el usuario debrea programar las visitas?
        //por cada id item es una visita que se programara??

        // let tecnicos = this.tecnicos_asignados.map(t => t.id_usr);
        // this._cotizacion.id_tecnicos_asignados = tecnicos.join(',');
        if(this._cotizacion.estatus == 0){

          this._cotizacion.id_tecnicos_asignados = this.tecnicos_asignados.id_usr;

          if(this.auxiliares_asignados.length > 0){
            let auxiliares = this.auxiliares_asignados.map(t => t.id_usr);
            this._cotizacion.id_auxiliares_asignados = auxiliares.join(',');
          } else {
            this._cotizacion.id_auxiliares_asignados = "";
          }
        }
       
       
        this.enviar=true;
        await this.$axios.post('cotizaciones.'+textsend, {cotizacion:this._cotizacion, id_usuario_cotizador:this.user.id_usr}).then(()=>{
          window.showAlert({
            activo: true,
            texto: "Cotización "+ (this._cotizacion.estatus == 0 ? 'enviada' : 'reenviada') + " con éxito!",
            color: "success",
            vertical: true
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }).catch(e=>{
          window.showAlert({
            activo: true,
            texto: "Lo sentimos! Algo salio mal...",
            color: "error",
            vertical: true
          });
        }).finally( () => {
          //que va a cargar?
          this.enviar=false;
        });
      }
    },


    // subtotal(){
    //   let subtotal = 0;
    //   this.cotizacion_items.forEach(item => {
    //     subtotal += item.precio_unitario * item.cantidad;
    //   });
    //   return this.cotizacion.subtotal = parseFloat(subtotal).toFixed(2);
    // },
    // iva(){
    //   // cotizacion.iva_porcentaje
    //   return 0;
    // },
    // calc(){
    //     let subtotal = this.subtotal();
    //     if(subtotal !== null && this.cotizacion.iva_porcentaje !== null){
    //         this.cotizacion.iva_monto = parseFloat(subtotal * parseFloat(this.cotizacion.iva_porcentaje / 100).toFixed(6) ).toFixed(2);
    //         this.cotizacion.total = parseFloat(parseFloat(subtotal) + parseFloat(this.cotizacion.iva_monto)).toFixed(2);
    //         this.updateCotizacion('iva');
    //     }
    // },

    // subtotal(){
    //   let subtotal = 0;
    //   this.cotizacion_items.forEach(item => {
    //     subtotal += item.precio_unitario * item.cantidad;
    //   });

    //   return this.cotizacion.subtotal = parseFloat(subtotal).toFixed(2);
    // },

    // iva(){
    //   return this.cotizacion.iva_monto
    // },
    // total(){
    //   return this.cotizacion.total
    // },



    // calc(){
    //   this.updateCotizacion('iva');
    // }
    
  }
}
</script>

<style>

.total-table {
  width: 100%; /* Asegura que la tabla ocupe todo el ancho disponible */
  border-collapse: collapse; /* Combina los bordes de las celdas */
  margin-top: 10px; /* Agrega un espacio entre la tabla y el v-data-table */
}

.total-table td {
  border: 1px solid #ccc; /* Agrega bordes a las celdas */
  padding: 8px; /* Espaciado interno en las celdas */
  text-align: left; /* Alinea el texto a la izquierda */
}

.total-table td[colspan="2"] {
  text-align: right; /* Alinea el texto a la derecha en la columna de Total */
  font-weight: bold; /* Puede resaltar el texto del Total */
}
</style>