<template>
    <div>
        <!-- v-if="!Loading" -->
        <v-row class="pa-0 ma-3" justify="center">
              <v-col cols="12">
                    <v-card-actions class="font-weight-black headline">
                        <v-row>
                            <v-col cols="12" sm="12" lg="12">
                                UNIDADES
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-autocomplete
                                    v-model="cliente"
                                    :items="clientes"
                                    item-title="nombre"
                                    item-value="id_usr"
                                    return-object
                                    single-line 
                                    hide-details
                                    label="Cliente"
                                    v-if="hasPermission('ver_unidadesxcli')"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model="search" append-icon="search" label="Buscar unidad" single-line hide-details style="margin-left:30px"></v-text-field>
                            </v-col>
                        </v-row>
                         
                    </v-card-actions>
                    <v-card class="mt-3" outlined>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn v-if="hasPermission('agregar_unidad')" v-show="(typeof cliente.id_usr != 'undefined')" small color="green" dark @click="data = {}; modal = true">Agregar </v-btn>
                            <v-btn v-show="(typeof cliente.id_usr != 'undefined')" small class="gray" icon dark @click="consulta()"><v-icon>mdi-refresh</v-icon> 
                            </v-btn>
                        </v-card-actions>
                    
                        <v-data-table 
                            v-model:items-per-page="itemsPerPage"
                            :headers="headers"
                            :items="items"
                            :search="search"
                            fixed-header
                            hide-default-footer
                            :loading ="Loading"
                            :no-data-text="cliente.length < 1 ? 'Por favor, selecciona un cliente para consultar sus unidades' : 'Aún no hay unidades registradas para este cliente'"
                            loading-text="Cargando... Por favor espere."
                            dense
                        >

                        

                            <template v-slot:item.nombre_unidad="{ item }" > 
                                <v-row style="padding-top:20px;padding-bottom:20px;">
                                    <v-col cols="12">
                                        <img :src="item.selectable.foto" width="150" height="100">
                                    </v-col>
                                    <!-- <v-col cols="8">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td style="font-weight: bold;">
                                                        Unidad:
                                                    </td>
                                                    <td>
                                                        {{ item.selectable.desc_unidad }}                                                              
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="font-weight: bold;">
                                                        Clave:
                                                    </td>
                                                    <td>
                                                        {{ item.selectable.clave_unidad }}                                                              
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="font-weight: bold;">
                                                        No. Serie:
                                                    </td>
                                                    <td>
                                                        {{ item.selectable.numero_serie }}                                                              
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </v-col> -->
                                </v-row>
                                

                                <!-- <table class="table">
                                    <thead>
                                        <tr>
                                        <th class="text-center" colspan="4" style="border:none">
                                                <img :src="item.selectable.foto" alt="" class="img-responsive" width="85%">
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-right"><label>Nombre</label></td>
                                            <td>`+data.nombre+` / `+data.apellido_paterno+`,`+data.apellido_materno+`                                                                 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-right"><label>Fecha de Compra</label></td>
                                            <td>`+data.fecha_nacimiento+`</td>
                                        </tr>
                                        <tr>
                                            <td class="text-right"><label>Departamento</label></td>
                                            <td>`+data.apellido_materno+`</td>
                                        </tr>
                                        <tr>
                                            <td class="text-right"><label>Placa</label></td>
                                            <td>`+data.email+`</td>
                                        </tr>
                                    </tbody>
                                </table> -->
                            </template>

                            <template v-slot:item.action="{ item }" > 
                                <!-- "data = item.selectable; modal = true" -->
                                <v-btn v-if="hasPermission('ver_unidad')" small text color="blue" dark @click="verUnidad(item.selectable)"><v-icon> mdi-eye </v-icon> </v-btn> 
                            </template>
                            
                            
                            <template v-slot:item.qr="{ item }" > 
                                
                                <div class="qr-container">
                                    <!-- Header -->
                                    <div class="qr-header">
                                        <h3>{{ item.selectable.tipo_unidad_nombre }}</h3>
                                    </div>
                                    <!-- image="img/logo_print.png"
                                        :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }" -->
                                        <QRCodeVue3 :ref="'qrCode_' + item.selectable.clave_unidad" 
                                            :value="item.selectable.clave_unidad"
                                            :width="200"
                                            :height="200"
                                            :image="'img/logo_print.png'"
                                            :dotsColor="'#000000'"
                                            :backgroundColor="'#ffffff'"
                                        />
                                        <!-- <QRCodeVue3
                                        :value="item.selectable.clave_unidad"
                                        style="cursor: pointer;"
                                        image="img/logo_print.png"
                                        :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
                                        :width="200"
                                        :height="200"
                                        fileExt="png"
                                        :errorCorrectionLevel="'H'"
                                        :dotsOptions="{
                                            type: 'dots',
                                            color: '#0d3961',
                                            gradient: {
                                            type: 'linear',
                                            rotation: 0,
                                            colorStops: [
                                                { offset: 0, color: '#0d3961' },
                                                { offset: 1, color: '#0d3961' },
                                            ],
                                            },
                                        }"
                                        :download="false"
                                        :ref="'qrCode_' + item.selectable.clave_unidad"
                                    /> -->

                                    <!-- Footer -->
                                    <div class="qr-footer" @click="printQR(item)">
                                        <p>{{ item.selectable.clave_unidad }}</p>
                                    </div>

                                </div>
                            </template>

                            <template v-slot:item.estatus="{ item }" > 
                                
                            </template>
                        </v-data-table>
    
                    </v-card>
                    
                     <v-dialog persistent v-model="modal" width="700px" >	
                    <v-card class="pa-5">
                            <Modal :showAlert="showAlert" :cliente="cliente" :consulta="consulta" @modal="modal = $event"/>
                    </v-card>
                </v-dialog>
              </v-col>
          </v-row>
      </div>
      <!-- <ScreenSplash v-else/> -->
    </template>
    
    <script>
    import html2canvas from 'html2canvas';
    import { mapState, mapMutations } from "vuex";
    import ScreenSplash from '@/components/ScreenSplash.vue';

    import QRCodeVue3 from '@/components/QRCodeVue3.vue';

    import Modal from './UnidadesModal.vue';
    import axios from 'axios'
    export default {
        props: {
            hasPermission:Function,
            showAlert: Function
        },
        components: {
            Modal,
            QRCodeVue3,
            ScreenSplash
        },
        data () {
            return {
              

                tableName:'unidades',
                search: '',
                itemsPerPage: 100,
                items:[],
                clientes:[],
                cliente:[],
                data:{},
                Loading:true,
                modal: false,
                headers:[
                    { title: 'QR'			 , align: 'left', sortable:false  , key: 'qr' 	},
                    { title: 'Fotografía'			 , align: 'left', sortable:false  , key: 'nombre_unidad' 	},    
                    { title: 'Clave'  					 , align: 'left'  , key: 'clave_unidad' },
                    { title: 'Unidad'  					 , align: 'left'  , key: 'desc_unidad' },
                    { title: 'Tipo Unidad'	 , align: 'left'  , key: 'tipo_unidad_nombre' },
                    { title: 'No. Serie'			 , align: 'left'  , key: 'numero_serie' 	},
                    { title: 'estatus'	 , align: 'left'  , key: 'estatus' 	},
                    { title: ''  			 , align: 'right' , key: 'action', sortable: false }
                ],
            }
        },

        async created(){
            if(!this.hasPermission('ver_unidadesxcli')){
                this.cliente = this.user;
                await this.consulta();
            } else {
                 // this.Loading=true;
                axios.post('datatable.search', {table:'usuarios', where: {rol_principal:'2'}}).then((res)=>{
                    this.clientes = res.data;
                }).catch((err)=>{
                    console.log(err);
                }).finally( ()=> {
                    // this.Loading=false;
                });
            }
        },

        mounted(){
            this.Loading = false;
        },

        computed: {
            ...mapState("Session", ['session_key', 'user', 'permisos', 'loggedin']),
        },
        watch:{
            cliente(){
                if(typeof this.cliente.id_usr !== 'undefined' && this.cliente.id_usr !== null){
                    // alert(this.cliente.id_usr);
                    this.consulta();
                } 

            }
        },
        methods:{
            printQR(item) {
                // Acceder al ref dinámico
                console.log(item.selectable.clave_unidad);
                const qrCodeRef = this.$refs['qrCode_' + item.selectable.clave_unidad];

                // Asegúrate de que qrCodeRef no sea null
                if (!qrCodeRef) {
                    console.error('No se encontró el QR code ref:', item.selectable.clave_unidad);
                    return;
                }
                
                console.log(qrCodeRef.$el);

                // Usa html2canvas para capturar el elemento del QR y generar una imagen
                html2canvas(qrCodeRef.$el, { scale: 3, useCORS: true }).then((canvas) => {
                    // Obtiene la imagen en base64
                    const qrImageBase64 = canvas.toDataURL('image/png');
                    
                    // Verifica que qrImageBase64 tenga contenido
                    if (!qrImageBase64) {
                    console.error('No se generó la imagen del QR.');
                    return;
                    }

                    // Crear una nueva ventana para imprimir
                    const printWindow = window.open('', '', 'width=200,height=300');
                    
                    // Estilos para ajustar el tamaño de impresión a 3x2 pulgadas
                    const styles = `
                    <style>
                        @page { margin: 0; } /*size: 76mm 51mm;*/
                        body { display: flex; justify-content: center; align-items: center; margin: 0; } /*width: 76mm; height: 51mm;*/
                        .qr-container { text-align: center; } /*width: 100%;  height: 100%;*/
                        h3, p { margin: 0; font-size: 10pt; }
                        img { width: 125px; height: 125px; }
                    </style>
                    `;

                    
                    printWindow.document.open();
                    // Inyecta el HTML del QR con la imagen en base64
                    printWindow.document.write(`
                    <html>
                        <head><title>Imprimir QR</title>${styles}</head>
                        <body>
                        <div class="qr-container">
                            <h3>${item.selectable.tipo_unidad_nombre}</h3>
                            <img src="${qrImageBase64}" alt="Código QR"/><br>
                            <p>${item.selectable.clave_unidad}</p>
                        </div>
                        </body>
                    </html>
                    `);
                    
                    // Cierra el documento y da tiempo para que se renderice antes de imprimir
                    printWindow.document.close();
                    printWindow.focus();
                    
                    // Asegúrate de dar tiempo suficiente para renderizar
                    setTimeout(() => {
                        printWindow.print();
                        printWindow.close();
                    }, 100); // Ajusta el tiempo si es necesario
                }).catch((error) => {
                    console.error("Error al generar la imagen del QR:", error);
                });
            },


            
            async consulta(){
                this.Loading=true;
                axios.post('unidades.consulta', {id_cliente:this.cliente.id_usr}).then((res)=>{
                    this.items = res.data;
                }).catch((err)=>{
                    console.log(err);
                }).finally( ()=> {
                    this.Loading=false;
                });
            },

            verUnidad(unidad){
                this.$router.push({name:'DetUnidadComponent', params:{id_unidad:unidad.clave_unidad}});
            }

            


        }
    }
    </script>
    <style scoped>
.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-header h3 {
  font-size: 14px;
}

.qr-footer p {
  font-size: 12px;
  margin-top: 10px;
  cursor: pointer;
}



</style>