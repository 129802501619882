<template>
    <div id="pdf-content-caratula">

         <!-- {{ 
            detalles_os }}

        {{ unidad }} 

        {{ levantamiento }} -->
        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="3" style="background-color:#002060; color: #ffffff;">
            <center>
                <img :src="appconf.logo" :width="appconf.logo_width" :height="appconf.logo_height"/>
            </center>
            </v-col>
            <v-col v-html="appconf.ot_header" cols="12" md="6" style="background-color:#c00000; color: #ffffff;">
                
            </v-col>
            <v-col v-html="appconf.ot_iso" cols="12" md="3" style="background-color:#002060; color: #ffffff;">
                
                
            </v-col>
        </v-row>

        <br />

        <!-- si no hay una visita agendada para ese dia para ese cliente con estos tecnicos, se debe crear. -->

        <div class="hide-from-canvas" style="padding-top: 10px; padding-left: 10px; padding-right: 10px;  border-radius: 15px;
            border:solid 8px #000; background-color: #f5f5f5;">
            <v-form v-if="detalles_os.estatus == 0 && hasPermission('programar_ot')"  v-model="valid" :lazy-validation="lazy">
                <v-row no-gutters>
                    <v-col cols="2" class="pa-2">
                        <v-text-field v-model="fecha_visita_programada" label="Fecha de visita" type="date" variant="solo"
                        density="compact" :rules="inputRules"></v-text-field>
                    </v-col>
                    <v-col cols="4" class="pa-2">
                        <v-select v-model="tecnicos_asignados" :items="tec_titulares" item-title="nombre" return-object item-value="id_usr" label="Titulares" density="compact" variant="solo" :rules="inputRules"></v-select>
                    </v-col>
                    <v-col cols="4" class="pa-2">
                        <v-select v-model="auxiliares_asignados" :items="tec_auxiliares" item-title="nombre" return-object item-value="id_usr" label="Auxiliares" density="compact" variant="solo" multiple></v-select>
                    </v-col>
                    <v-col cols="2" class="pa-2">
                        <v-btn block color="warning" :disabled="!valid" @click="modal = true">AGENDAR VISITA</v-btn>
                    </v-col>
                </v-row>
            </v-form>

            <v-row v-if="detalles_os.estatus == 1 && hasPermission('editar_orden')" no-gutters>
                <v-col cols="6">
                    <v-text-field label="Fecha y hora de inicio" type="datetime-local" variant="solo"
                        density="compact"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Fecha y hora finalizado" type="datetime-local" variant="solo"
                        density="compact"></v-text-field>
                </v-col>
            </v-row>

        </div>

        <br />

        <div
            style="width: 100%; padding:10px; background-color: #002060; color: #fff;  border-radius: 15px; margin-bottom:10px;">
            <center>DATOS DEL CLIENTE</center>
        </div>
        <div style=" padding: 10px;  border-radius: 15px; border:solid 8px #000; background-color: #f5f5f5;">

            <v-row no-gutters>
                <v-col cols="6">
                    <v-text-field readonly label="Nombre de la empresa" variant="solo" density="compact" v-model="detalles_os.nombre_cliente"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Área de trabajo" variant="solo" density="compact" v-model="detalles_os.area_trabajo"></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">
                    <v-text-field label="Dirección" variant="solo" density="compact" v-model="detalles_os.direccion_cliente"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Línea" variant="solo" density="compact" v-model="detalles_os.linea"></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">
                    <v-text-field label="Teléfono" variant="solo" density="compact" v-model="detalles_os.telefono_cliente"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Máquina" variant="solo" density="compact" v-model="detalles_os.nombre_unidad" readonly></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">
                    <v-text-field label="Nombre de contacto" variant="solo" density="compact" v-model="detalles_os.contacto_cliente" readonly></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Nombre de proyecto" variant="solo" density="compact" v-model="detalles_os.proyecto"></v-text-field>
                </v-col>
            </v-row>
        </div>
        <br />




        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="4" style="background-color:#002060; color: #ffffff;">
                <center> CUADRILLA</center>
                <v-radio-group v-model="detalles_os.cuadrilla">

                    <v-radio label="Mantenimiento" value="1"></v-radio>
                    <v-radio label="Operaciones" value="2"></v-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="12" md="4" style="background-color:#c00000; color: #ffffff;">
                <center>
                    <br />
                    SERVICIO
                    <v-radio-group v-model="ts" readonly>
                        <v-radio label="Interno" value="1"></v-radio>
                        <v-radio label="Externo" value="2"></v-radio>
                    </v-radio-group>
                </center>
            </v-col>
            <v-col cols="12" md="4" style="background-color:#ffc000; color: #ffffff;">
                <center>
                    MOTIVO DE LA VISITA
                    <v-radio-group  v-model="detalles_os.id_servicio" readonly>
                        <div style="display: flex; justify-content: center;">
                            <v-radio label="Preventivo" value="2"></v-radio>
                            <v-radio label="Correctivo" value="1"></v-radio>
                        </div>
                        <div style="display: flex; justify-content: center;">
                            <v-radio label="Montaje" value="5"></v-radio>
                            <v-radio label="Garantía" value="3"></v-radio>
                        </div>
                        <div style="display: flex; justify-content: center;">
                            <v-radio label="Pruebas de carga" value="4"></v-radio>
                            <v-radio label="Levantamiento" value="6"></v-radio>
                        </div>

                        <div style="display: flex; justify-content: center;">
                            <v-radio label="Cursos" value="8"></v-radio>
                            <v-radio label="Otros" value="7"></v-radio>
                        </div>

                    </v-radio-group>
                </center>

            </v-col>
        </v-row>

        <br />

        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col v-if="levantamiento.component_name == 'Gruas'" cols="12" md="4" style="padding:10px; background-color:#ffc000; color: #ffffff;">

                DATOS GENERALES DE LA GRÚA
                <v-text-field v-model="levantamiento.grua_marca" readonly label="Marca de la grúa" variant="solo" density="compact"></v-text-field>
                <v-text-field v-model="levantamiento.tipo_unidad_nombre" readonly label="Tipo de grúa" variant="solo" density="compact"></v-text-field>
                <v-text-field v-model="levantamiento.capacidad_grua" readonly label="Capacidad" variant="solo" density="compact"></v-text-field>
                <v-text-field label="Montada / Colgante" variant="solo" density="compact" v-model="unidad.base"></v-text-field>
                <v-text-field label="Trolley Manual/Eléctrica" variant="solo" density="compact" v-model="unidad.trolley"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" style="padding:10px; background-color:#002060; color: #ffffff;">
                <center>
                    DATOS GENERALES ESTRUCTURA
                    <v-text-field label="Claro" variant="solo" density="compact" v-if="formato_uno==true"></v-text-field>
                    <v-text-field label="Giro" variant="solo" density="compact" v-if="formato_dos==true"></v-text-field>
                    <v-text-field label="Articulada" variant="solo" density="compact" v-if="detalles_os.nombre_tipo_unidad=='GRUA ARTICULADA'"></v-text-field>
                    <v-text-field label="Recorrido" variant="solo" density="compact" v-if="formato_uno==true" v-model="levantamiento.recorrido_total_col_col"></v-text-field>
                    <v-text-field label="Largo de brazo" variant="solo" density="compact" v-if="formato_dos==true"></v-text-field>
                   
                </center>
            </v-col>
            <v-col cols="12" md="4" style="padding:10px; background-color:#c00000; color: #ffffff;">
                <center>
                    <v-text-field label="Numero de columnas" variant="solo" density="compact" v-if="formato_uno==true" v-model="unidad.numero_columnas"></v-text-field>
                    <v-text-field label="Giro mecanico o electrico" variant="solo" density="compact" v-model="unidad.giro"></v-text-field>
                    <v-text-field label="Claro entre columnas" variant="solo" density="compact" v-if="formato_uno==true" v-model="levantamiento.claro_col_col" readonly></v-text-field>
                    <v-text-field label="Tipo de alimentación" variant="solo" density="compact" v-model="levantamiento.tipo_alimentacion" readonly></v-text-field>
                    <v-text-field label="Tipo de anclaje" variant="solo" density="compact"></v-text-field>
                    <v-text-field label="Radio de curvatura" variant="solo" density="compact" v-if="detalles_os.nombre_tipo_unidad=='GRUA ARTICULADA'"></v-text-field>

                </center>

            </v-col>
        </v-row>


        <br/>

        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="4" style="padding:10px; background-color:#002060; color: #ffffff;">

                DATOS GENERALES POLIPASTO
                <v-text-field label="Marca de polipasto" variant="solo" density="compact" v-model="unidad.polipasto_marca"></v-text-field>
                <v-text-field label="Modelo" variant="solo" density="compact" v-model="unidad.polipasto_modelo"></v-text-field>
                <v-text-field label="Voltaje" variant="solo" density="compact" v-model="unidad.polipasto_voltaje"></v-text-field>
                <v-text-field label="Número de serie" variant="solo" density="compact" v-model="unidad.polipasto_numero_serie"></v-text-field>
                <v-text-field label="Trolley Manual/Eléctrica" variant="solo" density="compact" v-model="unidad.polipasto_trolley"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" style="padding:10px; background-color:#c00000; color: #ffffff;">
                <center>
                    TIPO POLIPASTO
                    <v-radio-group v-model="levantamiento.tipo_polipastos">

                        <v-radio label="Eléctrico de Cadena"
                        value="Eléctrico de Cadena"></v-radio>
                        <v-radio label="Eléctrico de Cable"
                        value="Eléctrico de Cable"></v-radio>
                        <v-radio label="Neumático" value="Neumático"></v-radio>
                        <v-radio label="Garrucha / Manual" value="Garrucha / Manual"></v-radio>


                    </v-radio-group>
                </center>
            </v-col>
            <v-col cols="12" md="4" style="padding:10px; background-color:#ffc000; color: #ffffff;">
                <center>
                    SISTEMA ELÉCTRICO

                    <v-text-field label="Tierra" variant="solo" density="compact" v-model="unidad.sistema_electrico_tierra"></v-text-field>
                    <v-text-field label="N° Líneas" variant="solo" density="compact" v-model="unidad.sistema_electrico_numero_lineas"></v-text-field>
                    <v-text-field label="Tipo/Marca" variant="solo" density="compact" v-model="unidad.sistema_electrico_marca"></v-text-field>
                    <v-text-field label="Amperaje" variant="solo" density="compact" v-model="unidad.sistema_electrico_amperaje"></v-text-field>

                </center>

            </v-col>
        </v-row>

        <br />
        <div style="background-color: #c00000; padding: 10px; width: 100%; border-radius: 15px;
    border:solid 8px #000;">
            <v-textarea v-model="levantamiento.notas_comentarios" label="FALLAS REPORTADAS" variant="solo" readonly></v-textarea>
           
            <v-btn color="green" class="hide-from-canvas" @click="save">Guardar</v-btn>
        </div>

    </div>

    <v-dialog v-model="modal" persistent max-width="500">
        <v-card>
        <v-card-title class="subtitle-1 font-weight-black">
            ASIGNAR ORDEN DE SERVICIO
        </v-card-title>
        <v-card-subtitle class="subtitle-2 font-weight-black">¿ ESTA SEGURO DE QUERER CONTINUAR
            ?</v-card-subtitle>
        <v-divider class="my-0 py-3"></v-divider>
        <v-card-text align="center" class="red--text font-weight-bold">
            {{ !persistent ? 'SE AGENDARÁ UNA VISITA Y SE NOTIFICARÁ A LOS USUARIOS ASGINADOS' : 'SE AGENDARÁ UNA VISITA AUNQUE YA HAY 3 PARA ESTA FECHA Y SE NOTIFICARÁ A LOS USUARIOS ASGINADOS'  }}
            
        </v-card-text>
        <v-divider class="my-0 py-2"></v-divider>
        <v-card-actions>
            <v-spacer />
            <v-btn dark outlined color="gris" @click="modal = false">Regresar</v-btn>
            <v-btn dark :color="persistent ? 'error' : 'primary'" :loading="agendar" :disabled="agendar" @click="agendar_visita()" >
                {{ !persistent ? 'Continuar' : 'ASIGNAR DE TODOS MODOS' }}
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import html2canvas from "html2canvas";

import { mapState } from "vuex";
export default {
    props:{
        detalles: Object,
        tecnicos: Array,
        unidad: Object,
        levantamiento: Object,
        showAlert: Function,
        hasPermission:Function,
        appconf:Object
    },
    computed: {
        ...mapState("Session", ['session_key', 'user','permisos', 'loggedin']),
        ...mapState(['router']),
        screenWidth() {
        return window.innerWidth;
        },
        formato_uno(){
            //MONORRIEL, BIRRIEL, PORTICO, SEMI PORTICO
        if( this.detalles_os.nombre_tipo_unidad=='GRUA MONORRIEL'||this.detalles_os.nombre_tipo_unidad=='GRUA BIRRIEL'||this.detalles_os.nombre_tipo_unidad=='GRUA PORTICO'||this.detalles_os.nombre_tipo_unidad=='GRUA SEMI PORTICO'||this.detalles_os.nombre_tipo_unidad=='GRUA ARTICULADA'){
            return true;
        }
        else{
            return false;
        }


        },
        formato_dos(){
            if(this.detalles_os.nombre_tipo_unidad=='GRUA BANDERA'|| this.detalles_os.nombre_tipo_unidad=='GRUA RADIAL'||this.detalles_os.nombre_tipo_unidad=='GRUA ARTICULADA'){
                return true
            }
            else{
                return false
            }
        }
    },
    data() {
        return {
            text: "Caratula",

            _tecnicos:this.tecnicos,
            persistent:false,
            tecnicos_asignados:{},
            tec_titulares:[],
            detalles_os:{},
            auxiliares_asignados:[],
            tec_auxiliares:[],
            ts:'2',
            fecha_visita_programada:'',
            agendar:false,
            modal:false,
            comentarios:'',

            inputRules: [v => !!v || 'Es requerido'],
            valid:true,
            lazy:false
        }
    },

    watch: {
        tecnicos_asignados: {
            handler(newValue, oldValue) {
                
                if(newValue !== 'undefined' && typeof newValue == 'object'){
                    this.tec_auxiliares = this._tecnicos.filter(us =>  parseInt(newValue.id_usr) !== parseInt(us.id_usr) ); 
                }
            //    if(this.auxiliares_asignados.includes(newValue)){

            //    }
            },
            deep: true
        },

        auxiliares_asignados: {
            handler(newValue, oldValue) {
                this.tec_titulares = this._tecnicos.filter(us =>  !newValue.includes(us));
            },
            deep: true
        }
    },

    created(){

        this.levantamiento.tipo_alimentacion = this.levantamiento.alimentacion_tipo_maq == "1" ? "Neumática" : "Eléctrica";
        //detalles_os .... consulta unidad and levantamiento.
        //ot? 


        this.detalles_os = this.detalles;

        this.detalles_os.id_servicio = String(this.detalles_os.id_servicio);


        this.tec_titulares = this._tecnicos;
        this.tec_auxiliares = this._tecnicos;
        
        // this.detalles_os.id_tecnicos_asignados
        this.tecnicos_asignados = this.tec_titulares.find(t => t.id_usr == this.detalles_os.id_tecnicos_asignados);

        // this.tecnicos_asignados = this.detalles_os.id_tecnicos_asignados != null ? (this.detalles_os.id_tecnicos_asignados.includes(',') ? this.detalles_os.id_tecnicos_asignados.split(',') : [this.detalles_os.id_tecnicos_asignados]) : [];
        // this.tecnicos_asignados = this.tec_titulares.length > 0 ? this.tec_titulares.filter(t => this.tecnicos_asignados.includes( String(t.id_usr) ) ) : [];
        
        this.auxiliares_asignados = this.detalles_os.id_auxiliares_asignados != null ? (this.detalles_os.id_auxiliares_asignados.includes(',') ? this.detalles_os.id_auxiliares_asignados.split(',') : [this.detalles_os.id_auxiliares_asignados]) : [];
        this.auxiliares_asignados = this.tec_titulares.length > 0 ? this.tec_titulares.filter(t => this.auxiliares_asignados.includes( String(t.id_usr) ) ) : [];
        
       

    },

    mounted(){

    },

    methods:{

        // async generatePDF() {
        //     const content = document.getElementById("pdf-content-caratula");
        //     const canvas = await html2canvas(content, { useCORS: true });

        //     const imgData = canvas.toDataURL("image/png");
        //     // ret
        // },
        async save(){
            try {

                // Ocultar temporalmente los elementos que no quieres incluir
                const hiddenElements = document.querySelectorAll('.hide-from-canvas');
                hiddenElements.forEach(el => el.style.display = 'none');


                const content = document.getElementById("pdf-content-caratula");
                const canvas = await html2canvas(content, { useCORS: true });

                const file = this.dataURLtoBlob (canvas.toDataURL("image/png"));

                hiddenElements.forEach(el => el.style.display = '');

                const fd = new FormData;
                fd.append('path', 'reportes/caratula/'+this.detalles_os.id_ot+'/');
                fd.append('files', file);

                await this.$axios.post(this.$uploadsURL, fd, {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                }).then(async(r) => {
                    
                    // this.aviso_alerta.firma_cliente = new URL(r.data, this.$baseURL).toString();
                    const reporte_caratula_img = new URL(r.data, this.$baseURL).toString();

                    let datapost = {
                        id_ot:this.detalles_os.id_ot,
                        reporte_caratula_img:reporte_caratula_img,
                        area_trabajo:this.detalles_os.area_trabajo,
                        linea:this.detalles_os.linea,
                        proyecto:this.detalles_os.proyecto,
                        cuadrilla:this.detalles_os.cuadrilla,

                        /*id_cliente:this.detalles_os.id_cliente,
                        direccion_cliente:this.detalles_os.direccion_cliente,
                        telefono_cliente:this.detalles_os.telefono_cliente,*/
                        
                        id_unidad:this.detalles_os.id_unidad,
                        base:this.unidad.base,
                        trolley:this.unidad.trolley,
                        // this.unidad.claro
                        //... GIRO 
                        numero_columnas:this.unidad.numero_columnas,
                        giro:this.unidad.giro,
                        tipo_anclaje:this.unidad.tipo_anclaje,
                        polipasto_marca:this.unidad.polipasto_marca,
                        polipasto_modelo:this.unidad.polipasto_modelo,
                        polipasto_voltaje:this.unidad.polipasto_voltaje,
                        polipasto_numero_serie:this.unidad.polipasto_numero_serie,
                        polipasto_trolley:this.unidad.polipasto_trolley,
                        sistema_electrico_tierra:this.unidad.sistema_electrico_tierra,
                        sistema_electrico_numero_lineas:this.unidad.sistema_electrico_numero_lineas,
                        sistema_electrico_marca:this.unidad.sistema_electrico_marca,
                        sistema_electrico_amperaje:this.unidad.sistema_electrico_amperaje,
                    }

                    this.$emit('update', reporte_caratula_img);

                    await this.$axios.post('orden.servicio.caratula.guardar', datapost)

                    .then(r => { 
                        //change estatus? 
                        //window.reload() 

                        this.showAlert({
                            activo: true, 
                            texto:"Orden de servicio actualizada con éxito", 
                            color: "success", 
                            vertical: true
                        });

                        this.detalles_os.estatus = 1;

                        this.modal = false;

                    }).catch((e) => {

                        this.showAlert({
                            activo: true, 
                            texto:e.response.data, 
                            color: "error", 
                            vertical: true
                        });

                        if(e.response.data == "Ya hay 3 visitas programadas para este día"){
                            this.persistent = true;
                        }

                    }).finally( () => {

                        this.agendar = false;

                    } )

                }).finally( () => {
                    // this.loading = false;
                } );

            } catch (error) {
                console.error('Error al enviar la imagen al servidor:', error);
                // this.loading=false
            }
        },

        dataURLtoBlob(dataURL) {
            let array, binary, i, len;
            binary = atob(dataURL.split(',')[1]);
            array = [];
            i = 0;
            len = binary.length;
            while (i < len) {
                array.push(binary.charCodeAt(i));
                i++;
            }
            return new Blob([new Uint8Array(array)], {
                type: 'image/png'
            });
        },
        async agendar_visita(){
            this.agendar = true;

            // let tecnicos = this.tecnicos_asignados.map(t => t.id_usr);
            // tecnicos.join(',')
            let id_auxiliares_asignados = "";
            if(this.auxiliares_asignados.length > 0){
                let auxiliares = this.auxiliares_asignados.map(t => t.id_usr);
                id_auxiliares_asignados = auxiliares.join(',');
            }

            try {

               
                let datapost = {
                    id_cliente:this.detalles_os.id_cliente,
                    id_ot:this.detalles_os.id_ot,
                    id_usr:this.user.id_usr,
                    id_tecnicos_asignados: this.tecnicos_asignados.id_usr,
                    id_auxiliares_asignados:id_auxiliares_asignados,
                    fecha_visita_programada:this.fecha_visita_programada,
                    persistent:this.persistent ? 'si' : 'no',
                    comentarios:this.comentarios
                }


                await this.$axios.post('orden.servicio.agendar.visita', datapost)

                .then(r => {
                    //change estatus? 
                    //window.reload() 

                    this.showAlert({
                        activo: true, 
                        texto:"Orden de servicio asignada con éxito", 
                        color: "success", 
                        vertical: true
                    });

                    this.detalles_os.estatus = 1;

                    this.modal = false;

                }).catch((e) => {

                    this.showAlert({
                        activo: true, 
                        texto:e.response.data, 
                        color: "error", 
                        vertical: true
                    });

                    if(e.response.data == "Ya hay 3 visitas programadas para este día"){
                        this.persistent = true;
                    }

                }).finally( () => {

                    this.agendar = false;

                } )

            } catch (error) {
                console.error('Error al enviar la imagen al servidor:', error);
            // this.loading=false
            }
        }
    }

    
}
</script>
<style>
/* https://bennettfeely.com/clippy/ */

.div_centro {
    background-color: #c00000;
    clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%);
    padding: 2px;
}

.div_izq {
    background-color: #002060;
    clip-path: polygon(0 0, 63% 0, 100% 100%, 0 100%);

}

.div_der {
    background-color: #002060;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 33% 100%);
}

.contenedor_uno {
    background-color: #c00000;
    width: 100%;
    display: flex;
    justify-content: center;
    color: #fff;
    border-radius: 15px;
    border: solid 8px #000;

}

.t_1 {
    width: 20%;
}

.t_2 {
    width: 60%;
}

.t_3 {
    width: 20%;

}


/* TRES */



.div_centro_tres {
    background-color: #c00000;
    clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%);
    padding: 2px;
}

.div_izq_tres {
    background-color: #002060;
    clip-path: polygon(0 0, 90% 0, 100% 100%, 0 100%);


}

.div_der_tres {
    background-color: #ffc000;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 9% 100%);

}

.contenedor_tres {
    background-color: #c00000;
    width: 100%;
    display: flex;
    justify-content: center;
    color: #fff;
    border-radius: 15px;
    border: solid 8px #000;

}

.t_1_tres {
    width: 33.5%;
}

.t_2_tres {
    width: 33%;
}

.t_3_tres {
    width: 33.7%;

}
</style>