<template>
    <div id="pdf-content-reporte">
        <v-row class="pdf-page">
            <v-col cols="12"  class="mt-0 pa-0">
                <v-img :src="require('@/assets/reporte/report_header.png')" contain class="width-100 height-100"></v-img>
            </v-col>
            <v-col cols="6" class="mt-0 pa-0" style="background-color: rgb(241,241,241);">
                <v-img :src="require('@/assets/reporte/image2.png')" width="600" class="ml-5"></v-img>
            </v-col>
            <v-col cols="6" class="mt-0" style="background-color: rgb(241,241,241);">
                <v-card variant="solo">
                    <v-card-title class="text-center" style="color:rgb(0,24,75)">REPORTE DE SERVICIO</v-card-title>
                    <v-card-subtitle class="text-center" style="color:rgb(255,0,0)">{{ detalles_os.nombre_tipo_unidad  }} {{ detalles_os.nombre_unidad }} | OT {{ detalles_os.id_ot }} | {{ getDate(fecha_visita_programada) }}</v-card-subtitle>
                    <v-card-subtitle class="text-center" style="color:rgb(0,24,75)">
                        {{ detalles_os.nombre_servicio }}
                    </v-card-subtitle>
                    <v-card-text>
                        Cliente: <strong>{{ detalles_os.nombre_cliente }}</strong><br>
                        <strong>ORDEN COMPRA: {{ detalles_os.oc }}</strong><br><br>
                        Contacto: Ing. Eduardo Cornejo<br>
                        Fecha de Servicio: 30 de noviembre del 2023<br>
                        Ubicación: Camino a la Tijera No. 1 , Gdl. Jal<br>
                    </v-card-text>
                    <v-card-text class="text-right">
                        <strong>CARVAJAL CRANES & HOIST SAPI DE CV</strong><br>
                        Contacto: Brenda Adriana Castillo León<br>
                        Tel: 81 2110 1905<br>
                        ventas4@carvajalcranes.com<br>
                        www.carvajalcranes.com<br>
                        Fuerza y Servicio al Nivel de tu Carga.
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- <v-row>
            <v-col cols="6">
                <v-card variant="solo">
                    <v-card-title>
                        Servicio de mantenimiento Preventivo a Grúas y Polipastos
                    </v-card-title>
                    <v-card-text>
                        ...tablas
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6">
                imagenes...
            </v-col>
        </v-row>
        <v-divider></v-divider> -->
        
        <v-row class="pdf-page">
            <v-col cols="6" style="background-color: rgb(241,241,241);">
                <v-img :src="require('@/assets/reporte/ot.png')" contain class="width-100 height-100"></v-img>
                <v-card-actions class="text-h5">
                    <v-spacer></v-spacer>
                    Orden de trabajo {{ detalles_os.id_ot }}
                </v-card-actions>
            </v-col>
            <v-col cols="6" style="background-color: rgb(241,241,241);">
                <v-img v-if="detalles_os.reporte_caratula_img !== null && detalles_os.reporte_caratula_img !== ''" :src="detalles_os.reporte_caratula_img" contain class="width-100 height-100" crossorigin="anonymous"></v-img>
            </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-row class="pdf-page">
            <v-col cols="12" style="background-color: rgb(241,241,241);">
                <img :src="require('@/assets/reporte/aviso_alerta.png')" width="1400" height="180"/>
            </v-col>
            <v-col cols="6" style="background-color: rgb(241,241,241);">
                <v-card variant="solo">
                    <v-card-title class="text-center text-h4 text-uppercase">
                        {{ aviso_props.advertencia_importante }}
                    </v-card-title>
                    <v-card-text class="mt-2 text-center text-h5 text-uppercase">
                        {{ aviso_props.observaciones }}
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" style="background-color: rgb(241,241,241);">
                <v-img v-if="detalles_os.reporte_aviso_img !== null && detalles_os.reporte_aviso_img !== ''" :src="detalles_os.reporte_aviso_img" contain class="width-100 height-100" crossorigin="anonymous"></v-img>
            </v-col>
        </v-row>

        <v-divider></v-divider>
        
        <v-row class="pdf-page">

            <v-col cols="12" style="background-color: rgb(241,241,241);">
                <img :src="require('@/assets/reporte/diagnostico.png')" width="1400" height="180"/>
            </v-col>

            <v-col cols="6" style="background-color: rgb(241,241,241);">
                <v-card variant="solo">
                    
                    <v-card-text>
                        <!-- {{detalles_os.estatus_reporte_unidad}} -->
                        <v-alert v-if="detalles_os.nombre_tipo_unidad !=='POLIPASTO NEUMATICO' && detalles_os.nombre_tipo_unidad !=='POLIPASTO MANUAL' "
                            :text="alert.message"
                            :title="alert.title"
                            :type="alert.color"
                            :icon="alert.icon"
                        ></v-alert>
                        <!-- <v-alert
                            text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, ratione debitis quis est labore voluptatibus! Eaque cupiditate minima, at placeat totam, magni doloremque veniam neque porro libero rerum unde voluptatem!"
                            title="Alert title"
                            type="success"
                        ></v-alert> -->
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" style="background-color: rgb(241,241,241);">
                <v-img v-if="detalles_os.reporte_diagnostico_img !== null && detalles_os.reporte_diagnostico_img !== ''" :src="detalles_os.reporte_diagnostico_img" contain class="width-100 height-100" crossorigin="anonymous"></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="pdf-page">
            <v-col cols="12" style="background-color: rgb(241,241,241);">
                <img :src="require('@/assets/reporte/evidencia_header.png')" width="1400">
            </v-col>
            <v-col cols="12" style="background-color: rgb(241,241,241);">
                <!-- imagenes--- -->
                 <v-row>
                    <v-col
                    v-for="img in aviso_props.img_observaciones"
                    :key="img"
                    class="d-flex child-flex"
                    cols="4"
                    >
                    <v-img
                        :src="getURL(img)"
                        aspect-ratio="1"
                        class="bg-grey-lighten-2"
                        cover
                    >
                        <template v-slot:placeholder>
                        <v-row
                            align="center"
                            class="fill-height ma-0"
                            justify="center"
                        >
                            <v-progress-circular
                            color="grey-lighten-5"
                            indeterminate
                            ></v-progress-circular>
                        </v-row>
                        </template>
                    </v-img>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        
        
    </div>

    <v-row>
        <v-col cols="12">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="generatePDF">DESCARGAR PDF</v-btn>
            </v-card-actions>
        </v-col>
    </v-row>
</template>


<script>
import Swal from 'sweetalert2';
import moment from 'moment';

import html2pdf from 'html2pdf.js';
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";


export default {
    components: {
    },
    props: {
        appconf:Object,
        detalles_os:Object,
        aviso_props:Object,
        checklist_data:Object,
        showAlert:Function,
        hasPermission:Function
    },
    data() {
        return {
           
            alert: {
                message:'',
                color:'',
                icon:''
            }
        }
    },
    computed: {
        
        screenWidth() {
            var ancho = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if (ancho) {
                let resultado =0;
            
                let med=0;
                if(ancho>=1000){
                    med = ancho/12;
                    let estado= med*2;
                    resultado =ancho-estado-100;
                }else if(ancho < 1000){
                    resultado =ancho-100;
                }else{
                    resultado =200
                }
                return resultado;
            } else {
                return 200;
            }
        }


    },
    watch: {
        
    },
    created() {
        
        if(this.detalles_os.estatus_reporte_unidad == null){
            this.alert = {
                title:'100%',
                message:'NINGUN COMPONENTE ESTA FALLANDO',
                color:'success',
                icon:'$success'
            }
        } else if(this.detalles_os.estatus_reporte_unidad == "30"){
            this.alert = {
                title:'30%',
                message:'COMPONENTES GENERAN UN PARO TOTAL',
                color:'error',
                icon:'$error'
            }
        } else if(this.detalles_os.estatus_reporte_unidad == "50"){
            this.alert = {
                title:'50%',
                message:'COMPONENTES GENERAN UNA OPERACIÓN BAJO SUPERVISIÓN',
                color:'warning',
                icon:'$warning'
            }
        }

        if(typeof this.aviso_props.img_observaciones !== 'undefined' && this.aviso_props.img_observaciones !== null && this.aviso_props.img_observaciones !== '' && this.aviso_props.img_observaciones !== 'undefined'){
            if(typeof this.aviso_props.img_observaciones == 'string'){
                this.aviso_props.img_observaciones = this.aviso_props.img_observaciones.includes(';') ? this.aviso_props.img_observaciones.split(';') : [this.aviso_props.img_observaciones]
            } 
            
        }
    },
    mounted() {
    },
    methods: {
        getDate(date){
            return moment(date).format('LL');
        },
        getURL(img){
            return new URL(img, this.$baseURL).toString()
        },
        generatePDF() {
            var element = document.getElementById('pdf-content-reporte');
            var opt = {
                margin: 0,
                filename: 'reporte.pdf',
                image: { type: 'png', quality: 1 },
                html2canvas: { useCORS: true, scale: 3, dpi: 192, letterRendering: true },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                pagebreak: { mode: ['avoid-all', 'css', 'legacy'], before: '.pdf-page' } // Verifica que '.pdf-page' esté bien aplicado
            };

            html2pdf().set(opt).from(element).outputPdf('bloburl').then(function(pdfBlobUrl) {
                // Puedes abrir el PDF generado en una nueva ventana o hacer otras operaciones
                window.open(pdfBlobUrl);
            });


            // var element = document.getElementById('pdf-content-reporte');
            // var opt = {
            //     margin: 0,
            //     filename: 'reporte.pdf',
            //     image: { type: 'png', quality: 1 },
            //     html2canvas: { useCORS: true, scale: 3, dpi: 192, letterRendering: true },
            //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            //     pagebreak: { mode: ['avoid-all', 'css', 'legacy'], before: '.pdf-page' } // Verifica que '.pdf-page' esté bien aplicado
            // };

            // html2pdf().set(opt).from(element).outputPdf('bloburl').then(function(pdfBlobUrl) {

            //     // $('#pdfIframe').attr('src', pdfBlobUrl);

            //     // $('#pdfIframe').on('load', function() {
                
            //     //  });

            //     window.open(pdfBlobUrl, '_blank');

            //     // Abrir el PDF en una nueva ventana
            //     // var pdfWindow = window.open(pdfBlobUrl, '_blank');

            //     // Verificar periÃ³dicamente si la ventana hija se ha cerrado
            //     // var checkWindowClosed = setInterval(function() {
            //     //     if (pdfWindow.closed) {
            //     //         clearInterval(checkWindowClosed);
            //     //         // Recargar la pÃ¡gina de origen
            //     //         location.reload();
            //     //     }
            //     // }, 1000); // Verificar cada segundo
            // });

            // const element = document.getElementById('pdf-content-reporte');
            // // const options = {
            // //     margin: [0, 0],
            // //     filename: 'reporte.pdf',
            // //     image: { type: 'jpeg', quality: 0.98 },
            // //     html2canvas: { scale: 2 },
            // //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            // //     pagebreak: { mode: ['css', 'legacy'], before: '.pdf-page' } // Agregamos esta línea para crear un salto de página antes de cada `v-row`
            // // };
            // const options = {
            //     margin: [0.5, 0.5], // Márgenes superior e inferior
            //     filename: 'reporte.pdf',
            //     image: { type: 'jpeg', quality: 0.98 },
            //     html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true },
            //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            //     pagebreak: { mode: ['avoid-all', 'css', 'legacy'], before: '.pdf-page' }
            // };


            // html2pdf()
            //     .from(element)
            //     .set(options)
            //     .save();
        }
            /*async generatePDF() {
            const rows = document.querySelectorAll(".pdf-page");
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'pt', // Usar puntos como unidad de medida
                format: 'a4' // Tamaño de página predeterminado
            });

            const pxToPt = 72 / 96; // Factor de conversión de píxeles a puntos

            for (let i = 0; i < rows.length; i++) {
                const content = rows[i];
                const canvas = await html2canvas(content, { useCORS: true, scale: 2 });
                const imgData = canvas.toDataURL("image/png");

                const imgWidth = canvas.width;
                const imgHeight = canvas.height;

                // Convertir dimensiones de píxeles a puntos
                const imgWidthInPt = imgWidth * pxToPt;
                const imgHeightInPt = imgHeight * pxToPt;

                if (i > 0) {
                    // Crear una nueva página con el tamaño exacto de la imagen
                    pdf.addPage([imgHeightInPt,  imgWidthInPt ]);
                }

                // Agregar la imagen a la página, ocupando todo el espacio
                pdf.addImage(imgData, "PNG", 0, 0, imgWidthInPt, imgHeightInPt);
            }

            pdf.save("reportes.pdf");
        }*/



    }
}
</script>
<style>
@media print {
    .pdf-page {
        page-break-before: always;
        page-break-inside: avoid; /* Evita que el contenido se corte entre páginas */
    }
}
</style>